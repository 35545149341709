import React from 'react';
import { useTranslation } from 'react-i18next';

import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import {
  Modal,
  ModalContainer,
  CloseButton,
  modalContainerTransition,
  modalTransition,
} from '../../layout/Modal';
import Feedbacks from './feedbacks';

const Button = styled.button`
  outline: none;
  border: none;
  background: Transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;

  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
`;

interface IThumbIcon {
  mouseHover: boolean;
}
const ThumbIcon: React.FC<IThumbIcon> = (props: IThumbIcon) => (
  <svg
    width="26"
    height="35"
    viewBox="0 0 26 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M8.73499 4.24545V10.0333H15.9695L15.1427 4.24545C14.9016 3.41868 13.9232 1.76514 11.9388 1.76514C9.95451 1.76514 8.97613 3.41868 8.73499 4.24545Z"
        fill="white"
      />
      <path
        d="M9.2928 10.1631H20.0251C21.5584 10.1631 22.3248 11.0149 22.3248 13.485C22.3248 15.4611 20.7917 16.0687 20.0251 16.1255C22.1546 16.1255 24.1135 17.4883 24.1135 19.6177C24.1135 21.7471 19.5992 22.4285 20.7064 22.4285C21.8137 22.4285 22.3248 24.0469 22.3248 25.7504C22.3248 27.1132 20.7917 28.3057 20.0251 28.7316C20.2522 28.9587 20.7064 29.8218 20.7064 31.4572C20.7064 33.0926 18.3215 33.2743 17.129 33.1607H10.1446C4.89773 33.1607 2.39358 29.1291 1.79736 27.1132V17.4883C1.79736 12.5821 6.79432 10.5606 9.2928 10.1631Z"
        fill="white"
      />
      <path
        d="M25.0575 19.183C25.0575 17.6358 24.1489 16.292 22.829 15.6374C23.4729 14.8853 23.8067 13.9398 23.7713 12.9448C23.6954 10.834 21.8445 9.11489 19.648 9.11489H16.472V5.01226C16.472 2.65516 14.5166 0.736084 12.111 0.736084C9.70712 0.736084 7.74997 2.65516 7.74997 5.01226V9.38266C3.70081 10.3678 0.690063 13.9613 0.690063 18.2309V24.9634C0.690063 29.99 4.8606 34.081 9.98864 34.081H18.3213C20.1975 34.081 21.7198 32.6661 21.7855 30.8611C21.8108 30.1388 21.6051 29.4462 21.1989 28.8627C22.5609 28.2213 23.505 26.856 23.505 25.2774C23.505 24.3501 23.1779 23.4972 22.6334 22.8211C24.0579 22.2046 25.0575 20.8062 25.0575 19.183ZM9.63801 5.01226C9.63801 3.67503 10.7472 2.58739 12.111 2.58739C13.4748 2.58739 14.584 3.67503 14.584 5.01226V9.11489H9.98864C9.87064 9.11489 9.75601 9.11984 9.63969 9.12315V5.01226H9.63801ZM21.005 21.3037H12.4262C11.9053 21.3037 11.4822 21.7186 11.4822 22.2294C11.4822 22.7401 11.9053 23.155 12.4262 23.155H19.4507C20.6442 23.155 21.6152 24.1071 21.6152 25.2774C21.6152 26.4477 20.6442 27.3998 19.4507 27.3998H12.4262C11.9053 27.3998 11.4822 27.8131 11.4822 28.3255C11.4822 28.8362 11.9053 29.2511 12.4262 29.2511H18.3786C18.7933 29.2511 19.1827 29.4131 19.471 29.7057C19.7609 29.9982 19.9109 30.385 19.8958 30.795C19.8671 31.5867 19.1591 32.2297 18.3196 32.2297H9.98864C5.90239 32.2297 2.5781 28.9701 2.5781 24.9634V18.2326C2.5781 14.2258 5.90239 10.9662 9.98864 10.9662H19.6463C20.8381 10.9662 21.8428 11.8836 21.8833 13.0092C21.9035 13.5911 21.6894 14.1432 21.2781 14.5614C20.8668 14.9796 20.3138 15.211 19.7205 15.211H12.4262C11.9053 15.211 11.4822 15.6242 11.4822 16.1366C11.4822 16.6474 11.9053 17.0623 12.4262 17.0623H21.0033C22.1968 17.0623 23.1678 18.0144 23.1678 19.1847C23.1678 20.3533 22.1985 21.3037 21.005 21.3037Z"
        fill={props.mouseHover ? '#7ECBBD' : '#898989'}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24.3675"
          height="33.345"
          fill="white"
          transform="translate(0.690063 0.736084)"
        />
      </clipPath>
    </defs>
  </svg>
);

const Feedback = (): React.ReactElement => {
  const { t } = useTranslation();
  const copy = t('feedback.button', { defaultValue: 'Feedback' });
  const nodeRef = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  function onOpenButtonClick() {
    setOpen(true);
  }

  function onCloseButtonClick() {
    setOpen(false);
  }

  React.useEffect(() => {
    function onCloseEsc(e: KeyboardEvent) {
      if (e.keyCode === 27) {
        onCloseButtonClick();
      }
    }
    document.addEventListener('keydown', onCloseEsc);
    return () => document.removeEventListener('keydown', onCloseEsc);
  }, [onCloseButtonClick]);

  const [mouseEnter, onMouseEnter] = React.useState(false);
  const mouseEventHandle = () => {
    onMouseEnter(!mouseEnter);
  };
  return (
    <>
      <Transition
        nodeRef={nodeRef}
        in={open}
        timeout={{ appear: 0, exit: 500 }}
        unmountOnExit
      >
        {(status) => (
          <ModalContainer
            ref={nodeRef}
            transition={{ css: modalContainerTransition, status: status }}
          >
            <Modal
              background="#cee5ca"
              transition={{ css: modalTransition, status }}
              foldedColor={`rgba(0, 0, 0, 0.4)`}
            >
              <Feedbacks onSubmit={onCloseButtonClick} />
              <CloseButton onClick={onCloseButtonClick} />
            </Modal>
          </ModalContainer>
        )}
      </Transition>
      <Button
        onClick={onOpenButtonClick}
        onMouseEnter={mouseEventHandle}
        onMouseLeave={mouseEventHandle}
      >
        <ThumbIcon mouseHover={mouseEnter} />
        <p
          style={{
            margin: `.4rem`,
            fontWeight: `bold`,
            fontSize: `1rem`,
            fontFamily: `Quicksand`,
          }}
        >
          {copy}
        </p>
      </Button>
    </>
  );
};

export default Feedback;
