import React from 'react';
import styled from 'styled-components';
import { ModalContext } from '../../layout/Modal';

const Container = styled.div<{ width: number; height: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 5px solid black;
  border-radius: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  overflow: hidden;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SignIcon = styled.img<{ size: number; offset: number }>`
  position: absolute;
  top: ${(props) => props.offset}px;
  left: ${(props) => props.offset}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

const MagnifyIcon = styled.img<{ size: number; offset: number }>`
  position: absolute;
  bottom: ${(props) => props.offset}px;
  right: ${(props) => props.offset}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  cursor: pointer;
`;

export default function VideoStyle({
  src,
  autoPlay,
  loop,
  playsInline,
  muted,
  iconSrc,
  onMagnify,
}: {
  src: string;
  autoPlay: boolean;
  loop: boolean;
  playsInline: boolean;
  muted: boolean;
  iconSrc: string;
  onMagnify?: (src: string) => void;
}): React.ReactElement {
  const { width } = React.useContext(ModalContext);
  const videoSize = width * 0.325;
  const iconSize = width * 0.05;
  const iconOffset = iconSize * 0.4;
  return (
    <Container width={videoSize} height={videoSize}>
      <SignIcon src={iconSrc} size={iconSize} offset={iconOffset} />
      <Video
        src={src}
        autoPlay={autoPlay}
        loop={loop}
        playsInline={playsInline}
        muted={muted}
      />
      {onMagnify && (
        <MagnifyIcon
          src={'/assets/magnifier.svg'}
          size={iconSize * 0.75}
          offset={iconOffset}
          onClick={() => onMagnify && onMagnify(src)}
        />
      )}
    </Container>
  );
}
