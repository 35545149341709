import React, { FC } from 'react';
import styled from 'styled-components';

interface PageIndicatorProps {
  isActive: boolean;
  color?: string;
}

interface CircleStyleProps {
  isActive: boolean;
  color?: string;
}
const CircleIndicator = styled.div<CircleStyleProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0.2rem;
  background-color: ${(props) => (props.isActive ? props.color : '#C4C4C4')};
  transition: all 0.5s;
`;

const PageIndicator: FC<PageIndicatorProps> = (props: PageIndicatorProps) => {
  return (
    <CircleIndicator
      color={props.color || '#7ecbbd'}
      isActive={props.isActive}
    />
  );
};

export default PageIndicator;
