import * as React from 'react';

import ParallaxMap, { Scene, Layer, Asset } from '../containers/ParallaxMap';
import WelcomeSign from '../containers/WelcomeSign';

import { IMap } from './index';

export default function TownMap({
  mapName,
  dimension,
}: IMap): React.ReactElement {
  return (
    <ParallaxMap
      name={mapName}
      dimension={dimension}
      offset={5}
      Background={<Layer></Layer>}
      Character={<Layer></Layer>}
    >
      <Scene name={'home'}>
        <Layer>
          <Asset
            name="welcome_sign"
            size={0.75}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            segments={[
              [0, 74],
              [75, 274],
            ]}
            speed={0}
          >
            <WelcomeSign width={dimension.width} />
          </Asset>
          <Asset
            name="export_Home"
            size={0.75}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            segments={[
              [0, 74],
              [75, 274],
            ]}
            speed={0}
          />
        </Layer>
        <Layer></Layer>
      </Scene>
      <Scene name="onboarding_instruction">
        <Layer>
          <Asset
            name="export_02_restaurant_D02_character" // character
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.3, y: 0.7 }}
            speed={-0.5}
          />
          <Asset
            name="export_02_restaurant_F01_character" // character 2
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.3, y: 0.7 }}
            speed={-0.5}
          />
        </Layer>
        <Layer></Layer>
      </Scene>

      <Scene name="signselection">
        <Layer>
          <Asset
            name="export_Onboarding_JP"
            size={0.45}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.25, y: 0.7 }}
            speed={0}
          />

          <Asset
            name="export_Onboarding_HK"
            size={0.45}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.75, y: 0.7 }}
            speed={0}
          />
        </Layer>
        <Layer></Layer>
      </Scene>
    </ParallaxMap>
  );
}
