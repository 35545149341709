import React from 'react';
import { Helmet } from 'react-helmet';

const TITLE = {
  en: 'Sign Town',
  jp: '手話タウン',
  hk: '手語村',
};

const siteLang = {
  en: 'en',
  jp: 'ja',
  hk: 'zh-hk',
};

const DESCRIPTION = {
  en:
    'Learn Sign Language using AI - A Sign Language experience powered by Google',
  jp:
    '手話タウンはＡＩのちからで手話をつかいながら学べるゲームです ー Google と作る手話学習ゲーム',
  hk:
    '「手語村」是一個利用人工智能協助學習手語的網上互動遊戲 - A Sign Language experience powered by Google',
};

export enum iLang {
  en = 'en',
  jp = 'jp',
  hk = 'hk',
}

const SEO = ({ lang }: { lang: iLang }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: siteLang[lang],
      }}
    >
      <title lang={siteLang[lang]}>{TITLE[lang]}</title>
      <meta
        lang={siteLang[lang]}
        name="description"
        content={DESCRIPTION[lang]}
      />
      <meta lang={siteLang[lang]} property="og:title" content={TITLE[lang]} />
      <meta
        lang={siteLang[lang]}
        property="og:description"
        content={DESCRIPTION[lang]}
      />
      <meta
        lang={siteLang[lang]}
        property="og:site_name"
        content={TITLE[lang]}
      />
      <meta lang={siteLang[lang]} name="twitter:title" content={TITLE[lang]} />
      <meta
        lang={siteLang[lang]}
        name="twitter:description"
        content={DESCRIPTION[lang]}
      />
    </Helmet>
  );
};

export default SEO;
