import * as React from 'react';

import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import DeafCulture from '../../containers/DeafCulture';
import Feedback from '../../containers/Feedback';
import SignLanguageButton from '../../containers/SignLanguageButton';
import { MapNames } from '../../recoils/gameStateAtom';
import PlayTown from './Town';

const PlayContainer = styled.div`
  overflow: hidden;
`;

export default function Play(): React.ReactElement {
  const { path: basePath } = useRouteMatch();

  console.log('path: ', basePath);

  return (
    <PlayContainer>
      <SignLanguageButton />
      <DeafCulture />
      <Feedback />
      {/* <Switch>
        <Route path={`${basePath}`}>
        </Route>
      </Switch> */}
      <PlayTown mapName={MapNames.TOWN} />
    </PlayContainer>
  );
}
