import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import styled from 'styled-components';
import { HKSLIcon, JSLIcon } from '../../components/Icons';

import { signLanguageAtom, SignLanguageList } from '../../recoils/signLanguage';

const Icons = {
  [SignLanguageList.HKSL]: HKSLIcon,
  [SignLanguageList.JSL]: JSLIcon,
};

const Container = styled.div`
  position: fixed;
  right: 50px;
  top: 50px;

  z-index: 3;
`;

const Choices = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
`;

const SignLanguage = styled.button<{ active: boolean }>`
  outline: none;
  background: transparent;
  border: none;

  color: black;
  cursor: pointer;

  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

const Label = styled.p`
  font-weight: bold;
`;

const CharacterIcon = styled.div`
  width: 30px;
  margin-right: 10px;
`;

export default function SignLanguageButton(): React.ReactElement {
  const [signLanguage, setSignLanguage] = useRecoilState(signLanguageAtom);
  const { t } = useTranslation();

  return (
    <Container>
      <Choices>
        {Object.keys(SignLanguageList).map((signLanguageItem) => {
          const Icon = Icons[signLanguageItem as SignLanguageList];
          const active = signLanguage === signLanguageItem;
          return (
            <SignLanguage
              key={signLanguageItem}
              active={active}
              onClick={() =>
                setSignLanguage(signLanguageItem as SignLanguageList)
              }
            >
              <CharacterIcon>
                <Icon active={active} />
              </CharacterIcon>
              <Label>{t(`global.${signLanguageItem.toLowerCase()}`)}</Label>
            </SignLanguage>
          );
        })}
      </Choices>
    </Container>
  );
}
