import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ActionButton } from '../../components/Button';
import LineIndicator from '../../components/LineIndicator';
import Icons, {
  FeedbackIntro,
  FeedbackOutro,
  Left,
  LeftMid,
  RightMid,
  Right,
} from './Icons';

const Wrapper = styled.div`
  padding: calc(60px * var(--scale));
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const QuestionWrapper = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;

const Question = styled.h2`
  text-align: center;
  font-size: calc(18px * var(--scale));
  font-weight: bold;
  margin: calc(30px * var(--scale));
  white-space: break-spaces;
`;

const Container = styled.div<{ offset: number }>`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  transform: translate(${(props) => props.offset * 250}%, 0);
  transition: 1s all;
`;

const ChoiceWrapper = styled.div`
  flex: 1;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
`;

const IndicatorWrapper = styled.div`
  width: 80%;
`;

const Choice = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  height: 100px;

  cursor: pointer;

  font-size: calc(16px * var(--scale));
  font-weight: 600;
`;
const ChoiceUsability = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  height: 120px;

  cursor: pointer;

  white-space: nowrap;
  overflow: visible;
  width: calc(var(--scale) * 5rem);
`;
const ChoiceSigner = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  height: calc(var(--scale) * 120px);

  cursor: pointer;

  white-space: nowrap;
  overflow: visible;
  width: calc(var(--scale) * 5rem);
`;
const ChoiceText = styled.p`
  margin: calc(var(--scale) * 0.8rem);
  font-size: calc(var(--scale) * 16px);
  font-weight: 600;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  padding: calc(var(--scale) * 20px);
  font-size: calc(var(--scale) * 16px);
`;

enum Score {
  nah = 'Nah',
  ok = 'OK',
  good = 'Good',
  love_it = 'Love It',
}

const ScoreIcon: Record<Score, typeof Icons[0]> = {
  [Score.nah]: Icons[0],
  [Score.ok]: Icons[1],
  [Score.good]: Icons[2],
  [Score.love_it]: Icons[3],
};

enum BinaryScore {
  help_understand_not_really = 'Not really',
  help_understand_yes = 'For sure',
}

const BinaryScoreIcon: Record<BinaryScore, typeof Icons[0]> = {
  [BinaryScore.help_understand_not_really]: Icons[1],
  [BinaryScore.help_understand_yes]: Icons[2],
};

type Enjoy = 'Not really' | 'It was OK' | 'Good' | 'Loved it!';
type UsabilityAnswer = 'Deaf' | 'Hearing' | 'Hard of hearing';
type SignerAnswer = 'Signer' | 'Native Signer' | 'Non Signer' | 'Learning';

type TAnswer = {
  score: Score | undefined;
  help_understand_deaf_community: BinaryScore | undefined;
  enjoy: Enjoy | undefined;
  feedback_enjoy: string;
  feedback_not_like: string;
  usability: UsabilityAnswer | undefined;
  signer: SignerAnswer | undefined;
};

type TChoice<T> = {
  Icon: typeof Left;
  key: string;
  answer: T;
};

const EnjoyChoice: TChoice<Enjoy>[] = [
  {
    Icon: Icons[0],
    key: 'not_really',
    answer: 'Not really',
  },
  {
    Icon: Icons[1],
    key: 'it_was_ok',
    answer: 'It was OK',
  },
  {
    Icon: Icons[2],
    key: 'enjoygood',
    answer: 'Good',
  },
  {
    Icon: Icons[3],
    key: 'enjoylove_it',
    answer: 'Loved it!',
  },
];

const UsabilityChoice: TChoice<UsabilityAnswer>[] = [
  {
    Icon: Left,
    key: 'deaf',
    answer: 'Deaf',
  },
  {
    Icon: LeftMid,
    key: 'hearing',
    answer: 'Hearing',
  },
  {
    Icon: RightMid,
    key: 'hard_of_hearing',
    answer: 'Hard of hearing',
  },
];

const SignerChoice: TChoice<SignerAnswer>[] = [
  {
    Icon: Left,
    key: 'signer',
    answer: 'Signer',
  },
  {
    Icon: LeftMid,
    key: 'nativesigner',
    answer: 'Native Signer',
  },
  {
    Icon: RightMid,
    key: 'nonsigner',
    answer: 'Non Signer',
  },
  {
    Icon: Right,
    key: 'learning',
    answer: 'Learning',
  },
];

const NUM_QUESTIONS = 8;

export default function Feedbacks({
  onSubmit,
}: {
  onSubmit: () => void;
}): React.ReactElement {
  const [page, setPage] = React.useState(0);
  React.useEffect(() => {
    setPage(0);
  }, []);

  const { t } = useTranslation();
  const feedbackT = (key: string) => t(`feedback.${key}`);

  const [answer, setAnswer] = React.useState<TAnswer>({
    score: undefined,
    help_understand_deaf_community: undefined,
    enjoy: undefined,
    feedback_enjoy: '',
    feedback_not_like: '',
    usability: undefined,
    signer: undefined,
  });
  const [submitting, setSubmitting] = React.useState(false);

  async function submitFeedback() {
    setSubmitting(true);
    try {
      await fetch(
        ' https://us-central1-bit-sign-language.cloudfunctions.net/sendFeedback',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(answer),
        },
      );
    } finally {
      onSubmit();
    }
  }

  return (
    <Wrapper>
      <QuestionWrapper>
        <QuestionPage
          question={feedbackT('intro')}
          page={0}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          buttonDisabled={false}
          buttontext={feedbackT('start')}
        >
          <FeedbackIntro />
        </QuestionPage>

        <QuestionPage
          question={feedbackT('score')}
          page={1}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          buttonDisabled={!answer.score}
          buttontext={feedbackT('next')}
        >
          {Object.entries(Score).map(([key, val]) => {
            const Icon = ScoreIcon[val];
            return (
              <Choice
                key={key}
                onClick={() => {
                  setAnswer((answer) => ({ ...answer, score: val }));
                }}
              >
                <Icon active={answer.score === val} />
                <p>{feedbackT(key)}</p>
              </Choice>
            );
          })}
        </QuestionPage>

        <QuestionPage
          question={feedbackT('enjoy')}
          page={2}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          buttonDisabled={!answer.enjoy}
          buttontext={feedbackT('next')}
        >
          {EnjoyChoice.map(({ key, Icon, answer: val }) => {
            return (
              <Choice
                key={key}
                onClick={() => {
                  setAnswer((answer) => ({
                    ...answer,
                    enjoy: val,
                  }));
                }}
              >
                <Icon active={answer.enjoy === val} />
                <p>{feedbackT(key)}</p>
              </Choice>
            );
          })}
        </QuestionPage>

        <QuestionPage
          question={feedbackT('help_understand_deaf_community')}
          page={3}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          buttonDisabled={!answer.help_understand_deaf_community}
          buttontext={feedbackT('next')}
        >
          {Object.entries(BinaryScore).map(([key, val]) => {
            const Icon = BinaryScoreIcon[val];
            return (
              <Choice
                key={key}
                onClick={() => {
                  setAnswer((answer) => ({
                    ...answer,
                    help_understand_deaf_community: val,
                  }));
                }}
              >
                <Icon active={answer.help_understand_deaf_community === val} />
                <ChoiceText>{feedbackT(key)}</ChoiceText>
              </Choice>
            );
          })}
        </QuestionPage>

        <QuestionPage
          question={feedbackT('feedback_enjoy')}
          page={4}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          skippableText={feedbackT('skip')}
          buttonDisabled={!answer.feedback_enjoy}
          buttontext={feedbackT('next')}
        >
          <TextArea
            value={answer.feedback_enjoy}
            onChange={(e) =>
              setAnswer((answer) => ({
                ...answer,
                feedback_enjoy: e.target.value,
              }))
            }
          />
        </QuestionPage>

        <QuestionPage
          question={feedbackT('feedback_not_like')}
          page={5}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          skippableText={feedbackT('skip')}
          buttonDisabled={!answer.feedback_not_like}
          buttontext={feedbackT('next')}
        >
          <TextArea
            value={answer.feedback_not_like}
            onChange={(e) =>
              setAnswer((answer) => ({
                ...answer,
                feedback_not_like: e.target.value,
              }))
            }
          />
        </QuestionPage>

        <QuestionPage
          question={feedbackT('usability')}
          page={6}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          buttonDisabled={!answer.usability}
          buttontext={feedbackT('next')}
        >
          {UsabilityChoice.map(({ Icon, key, answer: val }) => {
            return (
              <ChoiceUsability
                key={key}
                onClick={() => {
                  setAnswer((answer) => ({
                    ...answer,
                    usability: val,
                  }));
                }}
              >
                <Icon active={answer.usability === val} />
                <ChoiceText>{feedbackT(key)}</ChoiceText>
              </ChoiceUsability>
            );
          })}
        </QuestionPage>

        <QuestionPage
          question={feedbackT('signerQuestion')}
          page={7}
          currentPage={page}
          onButtonClick={() => setPage((page) => page + 1)}
          buttonDisabled={!answer.signer}
          buttontext={feedbackT('next')}
        >
          {SignerChoice.map(({ Icon, key, answer: val }) => {
            return (
              <ChoiceSigner
                key={key}
                onClick={() => {
                  setAnswer((answer) => ({
                    ...answer,
                    signer: val,
                  }));
                }}
              >
                <Icon active={answer.signer === val} />
                <ChoiceText>{feedbackT(key)}</ChoiceText>
              </ChoiceSigner>
            );
          })}
        </QuestionPage>

        <QuestionPage
          question={feedbackT('outro')}
          page={8}
          currentPage={page}
          onButtonClick={submitFeedback}
          buttonDisabled={submitting}
          buttontext={feedbackT('submit')}
        >
          <FeedbackOutro />
        </QuestionPage>
      </QuestionWrapper>

      <IndicatorWrapper>
        {page !== 0 ? <LineIndicator progress={page / NUM_QUESTIONS} /> : null}
      </IndicatorWrapper>
    </Wrapper>
  );
}

function QuestionPage({
  question,
  children,
  page,
  currentPage,
  onButtonClick,
  buttonDisabled,
  buttontext,
  skippableText,
}: {
  question: string;
  page: number;
  currentPage: number;
  onButtonClick: () => void;
  buttontext: string | React.ReactNode;
  buttonDisabled: boolean;
  skippableText?: string;
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <Container offset={page - currentPage}>
      <Question>{question}</Question>
      <ChoiceWrapper>{children}</ChoiceWrapper>
      <div className="">
        {skippableText && (
          <ActionButton
            deactivated={false}
            style={{
              margin: `calc(15px * var(--scale))`,
              padding: `calc(0.75rem * var(--scale)) calc(25px * var(--scale))`,
              fontSize: `calc(18px * var(--scale))`,
              background: 'transparent',
              border: '2px solid white',
            }}
            onClick={onButtonClick}
          >
            {skippableText}
          </ActionButton>
        )}
        <ActionButton
          deactivated={buttonDisabled}
          style={{
            margin: `calc(15px * var(--scale))`,
            padding: `calc(0.75rem * var(--scale)) calc(25px * var(--scale))`,
            fontSize: `calc(18px * var(--scale))`,
          }}
          onClick={onButtonClick}
        >
          {buttontext}
        </ActionButton>
      </div>
    </Container>
  );
}
