import React from 'react';
import TagManager from 'react-gtm-module';

import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import {
  Modal,
  ModalContainer,
  CloseButton,
  modalContainerTransition,
  modalTransition,
  ModalContext,
} from '../../layout/Modal';
import { deafCultureName } from '../../recoils/deafCultureStateAtom';
import PageIndicator from '../PageIndicator';
import SVGResponsivePlayer from '../SVGResponsivePlayer';

// props
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 80px 0 80px;

  justify-content: center;
  align-items: center;
  flex-flow: column;

  transition: 500ms all;
`;

const DeafCultureTip = styled.div`
  min-height: 30%;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  max-width: 500px;

  margin: 0 auto;

  font-weight: bolder;
`;

const Lottie = styled.div`
  flex: 1;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Indicator = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 55px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

const Pagination = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  padding: 20px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  pointer-events: none;
  & * {
    pointer-events: all;
  }
`;

const Button = styled.button<{ disabled: boolean; left?: boolean }>`
  outline: none;
  border: none;
  background: transparent;

  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  & path {
    fill: ${(props) => (props.disabled ? 'grey' : 'black')};
  }

  font-size: 30px;
  border-radius: 15px;
  padding: 2px 10px;
`;

export interface DeafCultureContent {
  name: deafCultureName;
  background: string;
  content: string;
  src: string;
  viewBox?: string;
}
export interface DeafCultureProps {
  open: boolean;
  onCloseButtonClick: () => void;
  contents: DeafCultureContent | DeafCultureContent[];
}

const DeafCultureContent = ({
  contentIndex,
  setContentIndex,
  content,
  onCloseButtonClick,
}: {
  contentIndex: number;
  setContentIndex: (idx: number) => void;
  content: DeafCultureContent[];
  onCloseButtonClick: () => void;
}): React.ReactElement => {
  const { t } = useTranslation();
  const { width } = React.useContext(ModalContext);

  const style: { [key: string]: string | number } = {
    width: 800,
    height: 500,
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: -1,
    transform: `translate(-50%, -50%)`,
  };
  return (
    <>
      {content.map((content, idx) => (
        <Content
          key={content.src}
          style={{
            transform: `translate(${(idx - contentIndex) * width}px, 0)`,
            background: content.background,
          }}
        >
          <DeafCultureTip>{t(content.content)}</DeafCultureTip>
          <Lottie>
            <div style={style}>
              <svg
                style={{
                  width: '100%',
                  height: '100%',
                  transform: `scale(${width / 800})`,
                }}
              >
                <g
                  transform={`translate(${(style.width as number) * 0.5}, ${
                    (style.height as number) * 0.5
                  })`}
                >
                  <SVGResponsivePlayer
                    src={content.src}
                    fitBy={'width'}
                    side={style.width as number}
                    lottieProps={{
                      rendererSettings: { viewBoxSize: content.viewBox },
                    }}
                  />
                </g>
              </svg>
            </div>
          </Lottie>
        </Content>
      ))}
      {content.length > 1 && (
        <>
          <Pagination>
            <Button
              onClick={() => setContentIndex(0)}
              disabled={contentIndex === 0}
              left
            >
              <svg
                width="17"
                height="27"
                viewBox="0 0 17 27"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1225 1.35656C13.9341 0.607353 15.1994 0.657965 15.9486 1.46961C16.6978 2.28125 16.6472 3.54657 15.8356 4.29577L5.89727 13.4696L15.8356 22.6434C16.6472 23.3926 16.6978 24.658 15.9486 25.4696C15.1994 26.2812 13.9341 26.3319 13.1225 25.5827L0 13.4696L13.1225 1.35656Z"
                />
              </svg>
            </Button>
            <Button
              onClick={() => setContentIndex(1)}
              disabled={contentIndex === 1}
            >
              <svg
                width="18"
                height="27"
                viewBox="0 0 18 27"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.18271 1.35656C3.37107 0.607353 2.10575 0.657965 1.35654 1.46961C0.607334 2.28125 0.657946 3.54657 1.46959 4.29577L11.4079 13.4696L1.46959 22.6434C0.657946 23.3926 0.607334 24.658 1.35654 25.4696C2.10575 26.2812 3.37107 26.3319 4.18271 25.5827L17.3052 13.4696L4.18271 1.35656Z"
                />
              </svg>
            </Button>
          </Pagination>
          <Indicator>
            {content.map((_, idx) => (
              <PageIndicator
                key={idx}
                isActive={contentIndex === idx}
                color={'black'}
              />
            ))}
          </Indicator>
        </>
      )}
      <CloseButton onClick={onCloseButtonClick} />
    </>
  );
};

const DeafCulture = (props: DeafCultureProps): React.ReactElement => {
  const nodeRef = React.useRef(null);

  const [contentIndex, setContentIndex] = React.useState(0);
  React.useEffect(() => {
    setContentIndex(0);
  }, [props]);

  React.useEffect(() => {
    function onCloseEsc(e: KeyboardEvent) {
      if (e.keyCode === 27) {
        props.onCloseButtonClick();
      }
    }
    document.addEventListener('keydown', onCloseEsc);
    return () => document.removeEventListener('keydown', onCloseEsc);
  }, [props.onCloseButtonClick]);

  React.useEffect(() => {
    if (props.open) {
      if (Array.isArray(props.contents)) {
        // console.log({
        //   'deaf culture modal': props.open,
        //   name: `${props.contents[0].name} & ${props.contents[1].name}`,
        // });
        const args = {
          dataLayer: {
            event: 'deaf_culture',
            deaf_culture_name: `${props.contents[0].name} & ${props.contents[1].name}`,
          },
        };
        TagManager.dataLayer(args);
      } else {
        // console.log({
        //   'deaf culture modal': props.open,
        //   name: props.contents.name,
        // });
        const args = {
          dataLayer: {
            event: 'deaf_culture',
            deaf_culture_name: props.contents.name,
          },
        };
        TagManager.dataLayer(args);
      }
    }
  }, [props.open]);

  const content = React.useMemo<DeafCultureContent[]>(() => {
    let content: DeafCultureContent[];
    if (Array.isArray(props.contents)) {
      content = props.contents;
    } else {
      content = [props.contents];
    }

    return content;
  }, [props.contents, contentIndex]);

  return (
    <Transition
      nodeRef={nodeRef}
      in={props.open}
      timeout={{ appear: 0, exit: 500 }}
      unmountOnExit
    >
      {(status) => (
        <ModalContainer
          ref={nodeRef}
          transition={{ css: modalContainerTransition, status: status }}
        >
          <Modal
            background={content[0].background}
            transition={{ css: modalTransition, status }}
            foldedColor={`rgba(0, 0, 0, 0.4)`}
          >
            <DeafCultureContent
              content={content}
              contentIndex={contentIndex}
              setContentIndex={setContentIndex}
              onCloseButtonClick={props.onCloseButtonClick}
            />
          </Modal>
        </ModalContainer>
      )}
    </Transition>
  );
};

export default DeafCulture;
