import { atom, selector } from 'recoil';
import { signLanguageAtom, SignLanguageList } from './signLanguage';

export enum CharacterPose {
  IDLE = 'idle',
  WALKING = 'loop',
}

export enum CharacterNames {
  NOH = 'export_noh',
  PINGPONG = 'export_pingpong',
}

export interface ICharacterState {
  pose: CharacterPose;
  accessories: {
    headwear: string | null;
    facewear: string | null;
    footwear: string | null;
  };
}

export const characterStateAtom = atom<ICharacterState>({
  key: 'characterState',
  default: {
    pose: CharacterPose.IDLE,
    accessories: {
      headwear: null,
      facewear: null,
      footwear: null,
    },
  },
});

export const characterBySignLanguage = selector({
  key: 'characterBySignLanguage',
  get: ({ get }) => {
    const signLanguage = get(signLanguageAtom);

    switch (signLanguage) {
      case SignLanguageList.JSL:
        return CharacterNames.PINGPONG;
      case SignLanguageList.HKSL:
        return CharacterNames.NOH;
    }
  },
});
