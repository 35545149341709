// instruction
// signLanguageSelection

import React from 'react';
import styled from 'styled-components';
import Instruction from '../../containers/Instruction';

interface FullScreenI {
  show: boolean;
}

const FullScreen = styled.div<FullScreenI>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.show ? `translate(-50%, -50%)` : `translate(-50%, -45%)`};
  opacity: ${(props) => (props.show ? `1` : `0`)};
  z-index: ${(props) => (props.show ? `1` : `-1`)};

  transition: all 0.5s;
`;

interface iOnboarding {
  show: boolean;
  handlePlaySound?: () => void;
  allowSoundPlayState: () => void;
}
const OnBoarding: React.FC<iOnboarding> = (props: iOnboarding) => {
  const { show } = props;

  return (
    <FullScreen show={show}>
      <Instruction allowSoundPlayState={props.allowSoundPlayState} />
    </FullScreen>
  );
};

export default OnBoarding;
