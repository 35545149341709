import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { keyframes } from 'styled-components';
import { PrimaryButton } from '../../components/Button';
import { LABELS, MULTI_TAGS } from '../../modules/ML/constant';

// import { sceneSelect as sceneSelectAtom } from '../../recoils/mlStateAtom';
import { MapNames } from '../../recoils/gameStateAtom';
import { signLanguageAtom, SignLanguageList } from '../../recoils/signLanguage';
import { useTranslation } from 'react-i18next';
import mod from '../../utils/mod';
import { AchievementIcon, ArrowIcon } from '../../components/Icons';
import formatSignText from '../../utils/formatSignText';
import { lastPageAtom } from '../../recoils/playStateAtom';
import VideoStyle from '../../components/VideoStyle';
import { ModalContext } from '../../layout/Modal';
import MagnifyVideo from '../../components/VideoStyle/MagnifyVideo';

/*
  structures
    SignSelectionWrapper
      HeaderWrapper
        icon
        title
      videoSelectionWrapper
        video
      controllerWrapper
        button
*/

const SignSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const HeaderWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: calc(60px * var(--scale, 1));
  justify-content: center;
  position: relative;
  z-index: 5;
`;
const CounterWrapper = styled.div`
  position: absolute;
  left: 1.5rem;
  top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;
const AchievementIconContainer = styled.div`
  width: calc(56px * var(--scale, 1));
  height: calc(56px * var(--scale, 1));
`;
const Title = styled.h2`
  text-align: center;
  width: 70%;
  font-size: calc(18px * var(--scale, 1));
  font-weight: bold;
  margin-top: calc(50px * var(--scale, 1));
`;
const VideoSelectionWrapper = styled.div<{ horizontalPadding: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  flex: 1;

  padding: 0 ${(props) => props.horizontalPadding}px;
`;

const centerSlideIn = keyframes`
  from {
    transform: translate(0%, 25%);
    opacity: 0;
  } to {
    transform: translate(0%, 0%);
    opacity: 1;
  }
`;
const VideoWrapper = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
  animation: ${centerSlideIn} 0.5s;
`;

interface IVideoContainerStyle {
  isShow: boolean;
  isCenter: boolean;
  isPositionLeft: boolean;
  isPositionRight: boolean;
}
const VideoContainer = styled.div<IVideoContainerStyle>`
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isShow ? 'flex' : 'none')};
  transform: ${(props) =>
    props.isShow
      ? props.isCenter
        ? `translate(0%,0%)`
        : props.isPositionLeft
        ? `translate(-150%,0) scale(0.8, 0.8)`
        : `translate(250%,0) scale(0.8, 0.8)`
      : 'none'};
  transition: all 0.5s;
`;
const VideoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & p {
    text-transform: capitalize;
    font-size: calc(17px * var(--scale, 1));
    font-weight: bold;
    color: #585858;
  }
`;

const Button = styled.button`
  outline: none;
  border: none;
  background: transparent;

  font-size: 30px;
  border-radius: 15px;
  margin: 2px 35px;
  z-index: 5;
  cursor: pointer;

  width: 20px;

  & path {
    fill: black;
  }
`;
const ControllerWrapper = styled.div`
  margin-bottom: calc(2.6rem * var(--scale, 1));
`;
const PageCountText = styled.p`
  margin: 0 0.5rem;
  font-size: calc(18px * var(--scale, 1));
  font-weight: 500;
`;

/*
  read playerState
  [key: number]: {
    jsl: string[],
    hksl: stirng[],
  }  
*/

const sceneAnalytics = [
  'play_packing_select',
  'play_restaurant_select',
  'play_hotel_select',
];

interface SelecSigningInput {
  signingNumberArray: number[];
  signingTextArray: string[];
}

interface SignSelectionProps {
  nextState: () => void;
  selectSigning: (input: SelecSigningInput) => void;
  mapName: MapNames;
  thisScene: number;
  videoList: string[][];
  handleSelectSignPage: (input: number) => void;
  goToAchievement: () => void;
  signPageSelect: number;
  goNextSign: number;
  resetIsGoNextSign: () => void;
  previousSignPlayState: number;
}

const SignSelection: React.FC<SignSelectionProps> = (
  props: SignSelectionProps,
) => {
  const [magnifyVideoSrc, setMagnifyVideoSrc] = React.useState('');
  const { videoList } = props;
  const signLanguage = useRecoilValue<SignLanguageList>(signLanguageAtom);
  const { width } = React.useContext(ModalContext);

  const { t } = useTranslation();
  const [, updateLastPage] = useRecoilState(lastPageAtom);

  const questionKey = [
    'play',
    props.mapName,
    props.thisScene,
    'signQuestion',
    signLanguage.toUpperCase(),
    props.signPageSelect,
  ].join('.');
  const question = t(questionKey, { defaultValue: 'Shall I bring?' });

  // Flow:
  // check dose play object has scene in key ?
  // check sign progress
  // map signprogress to signpage

  // disalbe page that already play
  // show the selected video on each page

  useEffect(() => {
    console.log('go next sign: ', props.goNextSign);
    if (props.goNextSign !== 0) {
      setTimeout(() => {
        handleClickRight();
        props.resetIsGoNextSign();
      }, 400);
    }
  }, [props.goNextSign]);

  useEffect(() => {
    console.log({
      scene: props.thisScene,
    });
    updateLastPage(sceneAnalytics[props.thisScene]);
  }, []);

  const numVideo = videoList.length;
  const handleClickLeft = () => {
    props.handleSelectSignPage(mod(props.signPageSelect - 1, numVideo));
  };
  const handleClickRight = () => {
    props.handleSelectSignPage(mod(props.signPageSelect + 1, numVideo));
  };

  const handleClickReady = () => {
    // check if in the multi label [hksl_... - jsl_...]
    const leftSignLabel = `${
        signLanguage.charAt(0).toUpperCase() +
        signLanguage.slice(1).toLowerCase()
      }_${videoList[props.signPageSelect][0]}`,
      rightSignLabel = `${
        signLanguage.charAt(0).toUpperCase() +
        signLanguage.slice(1).toLowerCase()
      }_${videoList[props.signPageSelect][1]}`;

    console.log(
      'SignSelection::handleClickReady:',
      leftSignLabel,
      rightSignLabel,
    );

    props.handleSelectSignPage(props.signPageSelect);

    const selectSignArray: number[] = [
      LABELS.indexOf(leftSignLabel),
      LABELS.indexOf(rightSignLabel),
    ];
    if (MULTI_TAGS.hasOwnProperty(leftSignLabel)) {
      selectSignArray[0] = MULTI_TAGS[leftSignLabel];
    }
    if (MULTI_TAGS.hasOwnProperty(rightSignLabel)) {
      selectSignArray[1] = MULTI_TAGS[rightSignLabel];
    }

    const selectSignTextArray = [
      videoList[props.signPageSelect][0],
      videoList[props.signPageSelect][1],
    ];
    // update selectSiging map to label
    props.selectSigning({
      signingNumberArray: selectSignArray,
      signingTextArray: selectSignTextArray,
    });
    props.nextState();
  };

  const MapIcon = AchievementIcon[props.mapName];
  const Icon = MapIcon[props.thisScene];

  return (
    <SignSelectionWrapper>
      <HeaderWrapper>
        <CounterWrapper onClick={props.goToAchievement}>
          <AchievementIconContainer>
            {props.thisScene in MapIcon ? <Icon /> : <>Icon</>}
          </AchievementIconContainer>
          <PageCountText>x{videoList.length || 0}</PageCountText>
        </CounterWrapper>
        <Title>{question}</Title>
      </HeaderWrapper>
      <VideoSelectionWrapper horizontalPadding={width * 0.025}>
        <Button onClick={handleClickLeft}>
          <ArrowIcon left />
        </Button>
        <VideoWrapper>
          {videoList.map((video, index) => {
            const next = mod(props.signPageSelect + 1, numVideo),
              previous = mod(props.signPageSelect - 1, numVideo);
            return (
              <VideoContainer
                key={index}
                isShow={
                  index === previous ||
                  index === props.signPageSelect ||
                  index === next
                }
                isCenter={index === props.signPageSelect}
                isPositionLeft={index === previous}
                isPositionRight={index === next}
              >
                <VideoTextContainer>
                  <VideoStyle
                    src={`./assets/videos/${signLanguage}/${
                      signLanguage.charAt(0).toUpperCase() +
                      signLanguage.slice(1).toLowerCase()
                    }_${video[0]}.m4v`}
                    autoPlay={true}
                    loop
                    playsInline
                    muted
                    iconSrc={'/assets/sign_icon/' + video[0] + '.svg'}
                    onMagnify={setMagnifyVideoSrc}
                  ></VideoStyle>
                  <p>{formatSignText(video[0])}</p>
                </VideoTextContainer>
                <VideoTextContainer>
                  <VideoStyle
                    src={`./assets/videos/${signLanguage}/${
                      signLanguage.charAt(0).toUpperCase() +
                      signLanguage.slice(1).toLowerCase()
                    }_${video[1]}.m4v`}
                    autoPlay={true}
                    loop
                    playsInline
                    muted
                    iconSrc={'/assets/sign_icon/' + video[1] + '.svg'}
                    onMagnify={setMagnifyVideoSrc}
                  ></VideoStyle>
                  <p>{formatSignText(video[1])}</p>
                </VideoTextContainer>
              </VideoContainer>
            );
          })}
        </VideoWrapper>
        <Button onClick={handleClickRight}>
          <ArrowIcon />
        </Button>
      </VideoSelectionWrapper>
      <ControllerWrapper>
        <PrimaryButton onClick={handleClickReady}>
          {t('signplay.signselection.ready')}
        </PrimaryButton>
      </ControllerWrapper>
      {magnifyVideoSrc && (
        <MagnifyVideo
          src={magnifyVideoSrc}
          onMinify={() => setMagnifyVideoSrc('')}
          ready={handleClickReady}
        />
      )}
    </SignSelectionWrapper>
  );
};

export default SignSelection;
