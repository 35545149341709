import { AnimationSegment } from 'lottie-web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SVGResponsivePlayer from '../../components/SVGResponsivePlayer';

const SEGMENT_LANGUAGE: Record<string, AnimationSegment> = {
  en: [0, 274],
  hk: [275, 549],
  jp: [550, 822],
};
export default function WelcomeSign({
  width,
}: {
  width: number;
}): React.ReactElement {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <SVGResponsivePlayer
      fitBy={'width'}
      side={0.75 * width}
      src={'/assets/map/home/home/back/export_Sign.json'}
      segments={[SEGMENT_LANGUAGE[language]]}
      // lottieProps={}
    />
  );
}
