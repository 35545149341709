import { ParentSize } from '@vx/responsive';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InstructionPageProps } from '.';
import CanvasResult from '../Camera/CanvasResult';
import SVGResponsivePlayer from '../SVGResponsivePlayer';
import Camera from './instructionCamera';
import InstructionResult from './InstructionResult';

import { classifyModel as classifyModelAtom } from '../../recoils/mlStateAtom';
import { useRecoilValue } from 'recoil';

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
`;

const CameraWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 80%;
  height: 75%;
  margin: 0;
  margin-bottom: -30px;
`;
const WhiteBackground = styled.div`
  position: absolute;
  width: 78%;
  height: 100%;
  border-radius: 2rem;
  background-color: #000;
  opacity: 0.5;
`;
const RecordText = styled.p`
  position: absolute;
  font-size: calc(24px * var(--scale, 1));
  font-weight: 600;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
`;
type IWhiteWrapper = {
  isCapturing: boolean;
};
const WhiteWrapper = styled.div<IWhiteWrapper>`
  width: calc(64px * var(--scale, 1));
  height: calc(64px * var(--scale, 1));
  border-radius: 50%;
  /* border: ${(props) =>
    props.isCapturing ? `none` : `0.4rem solid #ccefc6`}; */

  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
const CountDownCaptureText = styled.p`
  font-weight: 600;
  position: absolute;
  font-size: calc(14px * var(--scale, 1));
  width: max-content;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
`;

// const CountDownText = styled.p`
//   font-weight: 600;
//   position: absolute;
//   font-size: 2.4rem;
//   width: max-content;
//   z-index: 1;
//   top: 30%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   color: #000;
// `;
const ActionButton = styled.button`
  width: calc(64px * var(--scale, 1));
  height: calc(64px * var(--scale, 1));
  border-radius: 50%;
  border: none;
  color: #000;
  background-color: #fd9226;
  &:hover {
    background-color: #ffa1a1;
    color: #000;
  }
  &:active {
    background-color: #ffa1a1;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
  z-index: 1;

  cursor: pointer;
  transition: all 0.2s;
`;

const RecordStatusCircle = styled.div`
  width: calc(32px * var(--scale, 1));
  height: calc(32px * var(--scale, 1));
  border-radius: 50%;
  border: none;
  background-color: #ff2626;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  transition: all 0.2s;
`;

interface IProcessingModal {
  isShow: boolean;
}
const ProcessingModal = styled.div<IProcessingModal>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: ${(props) => (props.isShow ? `1` : `0`)};
  z-index: ${(props) => (props.isShow ? `3` : `-3`)};
  border-radius: 3rem;
  top: 0;
`;
const LottieContainer = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
const MLProgressText = styled.p`
  font-size: calc(20px * var(--scale, 1));
  position: absolute;
  top: 65%;
`;
const MLProgressNumberText = styled.p`
  font-size: calc(20px * var(--scale, 1));
  position: absolute;
  top: 75%;
  white-space: pre-wrap;
  text-align: center;
`;

// const SigningWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: space-evenly;
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;
const Title = styled.h2`
  text-align: center;
  width: 70%;
  font-size: calc(18px * var(--scale, 1));
  font-weight: bold;
`;

/**
 * position page
 * camera
 * face check position
 *
 */
interface IimageStack {
  imageData: ImageData;
  imageUrl: string;
}

/**
 * signing state
 * idle
 * positioning
 * countdown
 * capturing
 * processing
 */

const IMAGE_STACK: IimageStack[] = [];
const PREDICTION_IMAGE_STACK: ImageData[] = [];
const PREDICTION_IMAGE_URL: string[] = [];
// const TIMER_STACK = ['signplay.signing.getready', 'signplay.signing.signnow'];
const TIMER_STACK = ['onboarding.instruction.getready'];

const TITLE_TEXT = [
  'onboarding.instruction.clickstart',
  'onboarding.instruction.position',
  'onboarding.instruction.smile',
];

let canvasInterval: NodeJS.Timeout;
type CameraType = React.ElementRef<typeof Camera>;

// interface IholisticResult {
//   frame: number,
//   face: number,
//   pose: number,
//   hand: number,
// }

const PositionPage = (props: InstructionPageProps): React.ReactElement => {
  const [countDown, updateCountDown] = useState<number>(0);
  // const [countDownRecording, updateCountDownRecording] = useState<number>(0);

  const [signingState, updateSigningState] = useState('idle');
  const [classifyState, updateClassifyState] = useState('idle');

  const classifyModel = useRecoilValue(classifyModelAtom);
  const cameraRef = useRef<CameraType>(null);

  const [frame, updateFrame] = useState<number>(0);
  // const [holisticResult, updateHolisticResult] = useState<IholisticResult>({});

  const [tipsRandom, updateTipsRandom] = useState<number>(0);
  const [isResultError, set_isResultError] = useState<boolean>(false);

  /**
   * kp detectState
   * all
   * noface
   * nopose
   * nohand
   */
  const [kpDetectState, set_kpDetectState] = useState('all');

  const { t } = useTranslation();

  const handlePositioning = () => {
    updateSigningState('positioning');
  };

  const handleStart = () => {
    console.log('handle start');
    if (signingState === 'positioning') {
      updateSigningState('countdown');
      let count = 0;
      const CountDownInterval = setInterval(() => {
        count += 1;
        // updateCountDown(count);
        if (count === 1) {
          clearInterval(CountDownInterval);
          updateSigningState('capturing');
          handleCapture();
        }
      }, 2000);
    }
  };

  const handleCapture = () => {
    let time_set = 0;
    cameraRef.current?.handleClassify();
    const captureInterval = setInterval(() => {
      time_set += 100;
      const imageCaptured = cameraRef.current?.captureImage();
      if (imageCaptured) IMAGE_STACK.push(imageCaptured);
      // if (time_set % 1000 === 0) {
      //   updateCountDownRecording(time_set / 1000);
      // }
      if (time_set === 3000) {
        clearInterval(captureInterval);
        updateSigningState('processing');
      }
    }, 100);
  };

  const userClickTryAgain = () => {
    // PREDICTION_IMAGE_URL.length = 0;
    clearArray();
    set_kpDetectState('all');
    updateSigningState('idle');
    updateCountDown(0);
    // updateCountDownRecording(0);
  };

  const clearArray = () => {
    PREDICTION_IMAGE_STACK.length = 0;
    IMAGE_STACK.length = 0;
    PREDICTION_IMAGE_URL.length = 0;
  };

  useEffect(() => {
    const canvasWrapper = document.getElementById(
      'canvas-result-wrapper-positionpage',
    );
    switch (signingState) {
      case 'idle':
        updateTipsRandom(Math.floor(Math.random() * 4));
        clearInterval(canvasInterval);
        if (canvasWrapper) {
          canvasWrapper.style.zIndex = '-1';
          canvasWrapper.style.left = '32%';
          canvasWrapper.style.opacity = '0';
        }
        for (let i = 0; i < 16; i++) {
          const canvasEl = document.getElementById(
            'canvas-result-wrapper-positionpage' + i,
          );
          if (canvasEl) canvasEl.remove();
        }
        break;
      case 'processing':
        startClassify();
        break;
      case 'result':
        // show video and canvas
        let currentFrame = 0;
        if (canvasWrapper) {
          canvasWrapper.style.zIndex = '100';
          canvasWrapper.style.left = '32%';
          // canvasWrapper.style.top = '27%';
          canvasWrapper.style.opacity = '1';
        }

        canvasInterval = setInterval(() => {
          // hidden previous canvas
          const prevCanvas = document.getElementById(
            'canvas-result-wrapper-positionpage' + currentFrame.toString(),
          );
          if (prevCanvas) {
            prevCanvas.style.opacity = '0';
            prevCanvas.style.zIndex = '-1';
          }

          currentFrame = (currentFrame + 1) % 16;
          const showCanvas = document.getElementById(
            'canvas-result-wrapper-positionpage' + currentFrame.toString(),
          );
          if (showCanvas) {
            showCanvas.style.opacity = '1';
            showCanvas.style.zIndex = '100';
          }
        }, 187.5);
        break;
      default:
        break;
    }
  }, [signingState]);

  const startClassify = () => {
    // slice image stack to 16 frame uniform spread
    const thres = (IMAGE_STACK.length - 5) / 16;
    const imageTime = [];
    for (let i = 0; i < 16; i++) {
      imageTime.push(Math.round(thres * i));
    }
    console.log('image time stack: ', imageTime);
    for (const time of imageTime) {
      PREDICTION_IMAGE_STACK.push(IMAGE_STACK[time + 3].imageData);
      PREDICTION_IMAGE_URL.push(IMAGE_STACK[time + 3].imageUrl);
    }
    updateClassifyState('classify_keypoints');
  };

  useEffect(() => {
    switch (classifyState) {
      case 'idle':
        updateFrame(0);
        break;
      case 'classify_keypoints':
        console.log('classify predict holistic keypoints');
        classifyModel
          .predictHolistic_image(
            PREDICTION_IMAGE_STACK[frame],
            'canvas-result-wrapper-positionpage',
          )
          .then((result) => {
            console.log(result);
            if (frame === 15) {
              console.log('classify_sign');
              updateClassifyState('classify_sign');
            } else {
              updateFrame(result.frame + 1);

              /**
               * check face, pose, hand
               * whenever face = 0 >> no face
               * face = 1; pose = 0 >> no pose
               * face = 1; pose = 1; hand ไม่เคย 1 เลย >> no hand
               */
              switch (kpDetectState) {
                case 'all':
                  if (result.face === 0) {
                    console.log('no face');
                    set_kpDetectState('noface');
                  } else if (result.pose === 0) {
                    console.log('no pose');
                    set_kpDetectState('nopose');
                  } else if (result.hand === 0) {
                    console.log('no hand');
                    set_kpDetectState('nohand');
                  }
                  break;
                case 'noface':
                  if (result.face === 1) {
                    set_kpDetectState('nopose');
                  }
                  break;
                case 'nopose':
                  if (result.pose === 1) {
                    console.log('no pose');
                    set_kpDetectState('nohand');
                  }
                  break;
                case 'nohand':
                  if (result.hand === 1) {
                    set_kpDetectState('all-hand');
                  }
                  break;
                case 'all-hand':
                  break;
                default:
                  break;
              }
              // if (result.face === 0 ) {
              //   console.log('no face');
              //   set_kpDetectState('noface');
              // }
              // if (result.face === 1 && result.pose === 0) {
              //   console.log('no pose');
              //   set_kpDetectState('nopose');
              // }
              // if (
              //   result.face === 1 &&
              //   result.pose === 1 &&
              //   result.hand === 1
              // ) {
              //   console.log('have hand');
              // } else {
              //   console.log('no hand');
              //   set_kpDetectState('nohand');
              // }
            }
          });
        break;
      case 'classify_sign':
        classifyModel
          .predictSign()
          .then((result) => {
            console.log('result from tflite predict: ', result);
            updateClassifyState('post_process');
          })
          .catch((err) => {
            console.log(err);
            updateClassifyState('idle');
            updateSigningState('result');
            set_isResultError(true);
            // window.location.reload();
          });
        break;
      /**
       * TODO: post process
       *
       * flow
       * receive knn
       * fire {clsKNN, select_1, select_2} to KNN model
       * recieve result from KNN model (select_1, select_2, not_match)
       * case - correct:
       *  return
       * case - no_correct:
       *  show video tutorial and keypoints canvas frames,
       *  return
       *
       * @return
       *
       * update signingSelect // knn result
       * update signingSelectPair // another
       *
       */
      case 'post_process':
        updateClassifyState('idle');
        updateSigningState('result');
        break;
      default:
        break;
    }
  }, [classifyState, frame]);

  return (
    <Container>
      {signingState === 'result' && (
        <InstructionResult
          handleTryAgain={userClickTryAgain}
          onNextPage={props.onNextPage}
          pageState="positionpage"
          isError={isResultError}
          kpDetectState={kpDetectState}
        />
      )}
      <CanvasResult id={'canvas-result-wrapper-positionpage'} />
      {signingState !== 'result' && (
        <>
          <ProcessingModal
            isShow={
              signingState === 'processing' || signingState === 'senddata'
            }
          >
            {/* <ProcessingDoodle src="./assets/sign_play/processing_doodle.png" /> */}
            <ParentSize>
              {({ width, height }) => (
                <LottieContainer>
                  <g transform={`translate(${width / 2}, ${height * 0.6})`}>
                    <SVGResponsivePlayer
                      fitBy={'width'}
                      side={width}
                      src={'assets/loading.json'}
                    />
                  </g>
                </LottieContainer>
              )}
            </ParentSize>
            <MLProgressText>
              {/* {signingState === 'processing'
                ? t('signplay.signing.progress')
                : t('signplay.signing.improve')} */}
              {t('tips.tips')}
            </MLProgressText>
            {signingState === 'processing' && (
              <>
                {/* <MLProgressNumberText>{`${Math.floor(
                  (frame / 16) * 100,
                )} %`}</MLProgressNumberText> */}
                <MLProgressNumberText>
                  {t('tips.' + tipsRandom)}
                </MLProgressNumberText>
              </>
            )}
          </ProcessingModal>
          <Title>
            {t(
              TITLE_TEXT[
                signingState === 'idle'
                  ? 0
                  : signingState === 'capturing'
                  ? 2
                  : 1
              ],
            )}
          </Title>
          <CameraWrapper>
            <Camera
              ref={cameraRef}
              signingState={signingState}
              handleStart={handleStart}
            />
            {signingState === 'countdown' && (
              <>
                <WhiteBackground />
                <RecordText>{t(TIMER_STACK[countDown])}</RecordText>
              </>
            )}
          </CameraWrapper>
          {/* <PrimaryButton onClick={props.onNextPage}>Next</PrimaryButton> */}
          <WhiteWrapper isCapturing={signingState === 'capturing'}>
            {/* {signingState === 'capturing' && (
              <CountDownText>{3 - countDownRecording}</CountDownText>
            )} */}
            {signingState === 'capturing' && <RecordStatusCircle />}
            {signingState === 'idle' && (
              <ActionButton onClick={() => handlePositioning()}>
                <CountDownCaptureText>
                  {t('onboarding.instruction.start')}
                </CountDownCaptureText>
              </ActionButton>
            )}
          </WhiteWrapper>
        </>
      )}
    </Container>
  );
};

export default PositionPage;
