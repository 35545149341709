import * as React from 'react';
import { useRecoilValue } from 'recoil';

import styled from 'styled-components';
import { ParentSizeProvidedProps } from '@vx/responsive/lib/components/ParentSize';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

import {
  characterBySignLanguage,
  characterStateAtom,
} from '../../recoils/characterStateAtom';
import Character from '../../components/Character';
import config from '../../config';
import { getGameStateAtomByMap, MapNames } from '../../recoils/gameStateAtom';
// import { activateItem, ITEMS } from '../../recoils/itemAtom';

const CharacterGroup = styled.g`
  transition: all ${config.transition}s;
`;

const transitionStyles: (
  dimension: ParentSizeProvidedProps,
) => { [key in TransitionStatus]: string } = (dimension) => ({
  entering: `translate(${-dimension.width}, ${0.7 * dimension.height})`,
  entered: `translate(${dimension.width * config.characterOffset}, ${
    0.7 * dimension.height
  })`,
  exiting: `translate(${-dimension.width}, ${0.7 * dimension.height})`,
  exited: `translate(${-dimension.width}, ${0.7 * dimension.height})`,
  unmounted: `translate(${-dimension.width}, ${0.7 * dimension.height})`,
});

interface IMainCharacterProps {
  mapName?: string;
  dimension?: ParentSizeProvidedProps;
}

export default function MainCharacter(
  props: IMainCharacterProps,
): React.ReactElement {
  if (!props.dimension) throw new Error('Character does not have dimension!');

  const { pose } = useRecoilValue(characterStateAtom);
  const character = useRecoilValue(characterBySignLanguage);
  const gameState = useRecoilValue(getGameStateAtomByMap(MapNames.TOWN));

  // const showCharacter = gameState.scene >= 0 && gameState.scene <= 3;
  // const activator = activateItem<SVGGElement>(ITEMS.STEAK);
  const nodeRef = React.useRef(null);

  return (
    <Transition
      nodeRef={nodeRef}
      in={gameState.scene >= 0 && gameState.scene <= 2}
      timeout={config.transition * 1000}
    >
      {(status) => (
        <CharacterGroup
          ref={nodeRef}
          transform={
            transitionStyles(props.dimension as ParentSizeProvidedProps)[status]
          }
          // {...activator}
        >
          <Character
            side={1 * (props.dimension as ParentSizeProvidedProps).height}
            character={character}
            pose={pose}
          />
        </CharacterGroup>
      )}
    </Transition>
  );
}
