import * as React from 'react';
import { Path } from '.';

export default function HKSL({
  active,
}: {
  active: boolean;
}): React.ReactElement {
  return (
    <svg
      width="25"
      height="40"
      viewBox="0 0 25 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2536 2.5164C20.866 3.9911 25 9.03016 25 15.0197V32.5443C25 36.2017 21.988 39.1667 18.2724 39.1667H9.19235C5.47682 39.1667 2.46479 36.2017 2.46479 32.5443V14.9215C0.937207 13.3369 0 11.1954 0 8.8385C0 3.95713 4.01998 0 8.97887 0C11.4207 0 13.6349 0.959508 15.2536 2.5164Z"
        fill={active ? '#7ECBBD' : '#C4C4C4'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0421 16.9215C11.4666 16.9215 11.0001 16.455 11.0001 15.8795L11.0001 15.042C11.0001 14.4665 11.4666 14 12.0421 14C12.6176 14 13.0841 14.4665 13.0841 15.042L13.0841 15.8795C13.0841 16.455 12.6176 16.9215 12.0421 16.9215Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7932 16.9215C15.2177 16.9215 14.7512 16.455 14.7512 15.8795L14.7512 15.042C14.7512 14.4665 15.2177 14 15.7932 14C16.3687 14 16.8352 14.4665 16.8352 15.042L16.8352 15.8795C16.8352 16.455 16.3687 16.9215 15.7932 16.9215Z"
        fill="black"
      />
    </svg>
  );
}
