import React, { useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import Play from './pages/Play';
import Home from './pages/Home';
import OnBoarding from './pages/OnBoarding';

import Div100vh from 'react-div-100vh';

import { useRecoilState, useRecoilValue } from 'recoil';
import { classifyModel as classifyModelAtom } from './recoils/mlStateAtom';
import Loader from './components/Loader';
import styled from 'styled-components';
import Error from './containers/Error';
import { isChrome, isMobile } from 'react-device-detect';
import { errorAtom, errorListAtom, ErrorStatus } from './recoils/errorAtom';
import { ParentSize } from '@vx/responsive';
import { getGameStateAtomByMap, MapNames } from './recoils/gameStateAtom';
import { HomeMap, TownMap } from './maps';
import config from './config';
import {
  SOUNDSTATE,
  soundStateAtom,
  SOUNDSTATUS,
  soundStatusAtom,
} from './recoils/soundStateAtom';
import SoundComponent from './components/Sound';
import { initKnn } from './modules/ML/knn.js';
import SignSelectionContainer from './containers/SignSelectionContainer';
import { useTranslation } from 'react-i18next';
import Analytics from './components/Analytics';
import { lastPageAtom } from './recoils/playStateAtom';
import Fonts from './components/Fonts';
import PartnerLogo from './components/PartnerLogo';
import { useHistory } from 'react-router-dom';
import SEO, { iLang } from './components/SEO';

const ProcessingModal = styled.div`
  width: 50vw;
  height: 50%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const LoaderText = styled.p`
  font-weight: 700;
  font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, -50%);
`;

// const VersionTag = styled.p`
//   position: absolute;
//   bottom: 2%;
//   right: 2%;
//   color: #888;
// `;
const SigntownSign = styled.img`
  position: absolute;
  height: 40%;
  left: 50%;
  top: 90%;
  transform: translate(-50%, 0%);
`;
const WarnText = styled.p`
  position: absolute;
  overflow: auto;
  width: 30%;
  height: 50%;
  white-space: pre-line;
  z-index: 999;
  top: 10%;
  left: 5%;
`;

type SoundComponentType = React.ElementRef<typeof SoundComponent>;

const version = '0.2.27a-0.1.3m';

const App: React.FC = () => {
  const [isInitModel, updateIsInitModel] = React.useState<boolean>(false);

  const errorList = useRecoilValue(errorListAtom);

  const classifyModel = useRecoilValue(classifyModelAtom);

  const soundComponentRef = useRef<SoundComponentType>(null);

  const [isError, setErrorStatus] = useRecoilState(errorAtom);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const InitModel = async () => {
    if (!isInitModel) {
      const Tic = +new Date();
      const loadModel = await classifyModel.loadModel(
        'model/classification/js32_takeshi845/model.json',
      );
      // const loadModel = false;
      if (!loadModel) {
        if (isChrome) {
          console.log('load model fail.');
          setErrorStatus(ErrorStatus.WEBGL_ERROR);
          return;
        } else {
          console.log('load model fail.');
          setErrorStatus(ErrorStatus.BROWSER_INCOMPATIBLE);
          return;
        }
      }
      const initHolistic = await classifyModel.initHolistic();
      if (!initHolistic) {
        if (isChrome) {
          console.log('load model fail.');
          setErrorStatus(ErrorStatus.WEBGL_ERROR);
          return;
        } else {
          console.log('load model fail.');
          setErrorStatus(ErrorStatus.BROWSER_INCOMPATIBLE);
          return;
        }
      }
      // const initFace = await classifyModel.initFace();
      // if (!initFace) {
      //   console.log('load facemodel fail.');
      //   setErrorStatus(ErrorStatus.ML_LOADING_FAILURE);
      //   return;
      // }
      const initKNN = await initKnn();
      if (!initKNN) {
        setErrorStatus(ErrorStatus.ML_LOADING_FAILURE);
        return;
      }
      const Toc = +new Date();
      console.log('loaded model consume: ', Toc - Tic);
      console.log(`setup success`);
      updateIsInitModel(true);
      history.push(window.location.pathname);
    }
  };

  const location = useLocation();
  const isHomePage = location.pathname === '/';
  React.useEffect(() => {
    if (isMobile) {
      setErrorStatus(ErrorStatus.DEVICE_INCOMPATIBLE);
    }
    if (!isHomePage) {
      InitModel();
    }
    console.info(version);

    function myCustomWarn(...args: any[]) {
      const messages = args.filter(function (a) {
        return typeof a == 'string';
      });

      for (const m in messages) {
        // alert(messages[m]);
        // console.log(messages[m]);
        // const warnEl = document.getElementById('warn-text');
        // warnEl?.append(messages[m] + '/n/n');
        errorList.push(messages[m]);
      }

      /**
       *  Calling console.oldWarn with previous args seems to lead to a
       *  infinite recurvise loop on iOS. Not sure why, disabled.
       *  then again, if you show your log message in alert why would you
       *  post them to console ?
       */

      // return console.oldWarn(arguments);
    }

    (console as any).oldWarn = console.warn;

    console.warn = myCustomWarn;
  }, []);

  const [, setHomeGameState] = useRecoilState(
    getGameStateAtomByMap(MapNames.HOME),
  );

  // change to selected map when app has multiple maps
  const [, setGameState] = useRecoilState(getGameStateAtomByMap(MapNames.TOWN));
  React.useEffect(() => {
    const { pathname } = location;
    switch (pathname) {
      case '/':
        setHomeGameState((prev) => ({ ...prev, scene: -5 }));
        setGameState((prev) => ({ ...prev, scene: -5 }));
        break;
      case '/onboarding':
        setHomeGameState((prev) => ({ ...prev, scene: -4 }));
        setGameState((prev) => ({ ...prev, scene: -4 }));
        break;
      case '/signselection':
        setHomeGameState((prev) => ({ ...prev, scene: -3 }));
        setGameState((prev) => ({ ...prev, scene: -3 }));
        break;
      case '/play':
        const searchUrl = window.location.search;
        const searchParams = new URLSearchParams(searchUrl);
        const openmodal = searchParams.get('openmodal');
        if (openmodal) {
          break;
        } else {
          setHomeGameState((prev) => ({ ...prev, scene: 0 }));
          setGameState((prev) => ({ ...prev, scene: 0 }));
        }
        break;
    }
  }, [location]);

  const handlePlaySound = () => {
    soundComponentRef.current?.handlePlaySound();
  };

  return (
    <Div100vh
      className="App"
      style={{
        overflow: 'hidden',
        background: `linear-gradient(
    to bottom,
    #f0f0f0,
    #f0f0f0 69.5%,
    black 69.5%,
    black ${69.5 + config.baselineWidth * 100}%,
    #dddddd ${69.5 + config.baselineWidth * 100}%,
    #dddddd
  )`,
      }}
      onClick={handlePlaySound}
    >
      {/* <WarnText id="warn-text"></WarnText> */}
      {!isError && (
        <>
          {/* <VersionTag>{version}</VersionTag> */}
          <Analytics />
          <SEO lang={i18n.language as iLang} />
          {isInitModel || isHomePage ? (
            <>
              {/* sound */}
              {/* Map */}
              <ParentSize>
                {(dimension) => {
                  return (
                    <svg width={dimension.width} height={dimension.height}>
                      <HomeMap mapName={MapNames.HOME} dimension={dimension} />
                      <TownMap mapName={MapNames.TOWN} dimension={dimension} />
                    </svg>
                  );
                }}
              </ParentSize>

              <SoundComponent ref={soundComponentRef} />
              {/* HTML */}
              <Switch>
                <Route exact path={['/', '/onboarding', '/signselection']}>
                  {/* {({ match }) => <Home />} */}
                  <Instruction
                    pathname={location.pathname}
                    handlePlaySound={soundComponentRef.current?.handlePlaySound}
                    onStartButtonClick={InitModel}
                  />
                </Route>
                {/* <Route path={'/onboarding'}>{({ match }) => <OnBoarding />}</Route> */}
                <Route path={'/play'}>
                  <Play />
                </Route>
              </Switch>
            </>
          ) : (
            <>
              <ProcessingModal>
                <LoaderText>{t('global.loading')}</LoaderText>
                <Loader />
                <SigntownSign src="assets/signtown_sign.png" />
              </ProcessingModal>
              <PartnerLogo />
            </>
          )}
        </>
      )}
      {isError && <Error />}
    </Div100vh>
  );
};

export default App;

interface iInstruction {
  pathname: string;
  handlePlaySound?: () => void;
  onStartButtonClick: () => void;
}
const Instruction: React.FC<iInstruction> = (props: iInstruction) => {
  const [, updateSoundState] = useRecoilState(soundStateAtom);
  const soundStatus = useRecoilValue(soundStatusAtom);

  const [, updateLastPage] = useRecoilState(lastPageAtom);
  const allowSoundPlayState = () => {
    if (soundStatus !== SOUNDSTATUS.DISABLED) updateSoundState(SOUNDSTATE.PLAY);
  };

  React.useEffect(() => {
    switch (props.pathname) {
      case '/':
        updateLastPage('home');
        break;
      case '/onboarding':
        updateLastPage('onboarding');
        break;
      case '/signselection':
        updateLastPage('signselection');
        break;
      default:
        break;
    }
  }, [props.pathname]);

  return (
    <>
      <Fonts />
      <Home
        show={props.pathname === '/'}
        allowSoundPlayState={allowSoundPlayState}
        onStartButtonClick={props.onStartButtonClick}
      />
      <OnBoarding
        show={props.pathname === '/onboarding'}
        handlePlaySound={props.handlePlaySound}
        allowSoundPlayState={allowSoundPlayState}
      />
      <SignSelectionContainer
        show={props.pathname === '/signselection'}
        handlePlaySound={props.handlePlaySound}
      />
    </>
  );
};
