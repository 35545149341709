import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';

import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

console.log = function () {
  null;
};
console.error = function () {
  null;
};
// console.warn = function () {
//   null;
// };
console.debug = function () {
  null;
};
if (typeof window !== undefined)
  window.addEventListener('load', () => {
    console.clear();
  });

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<p>hello</p>}>
      <BrowserRouter>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
