import { atomFamily } from 'recoil';
import { localStorageEffect } from '../utils/localStorageEffect';

export enum MapNames {
  HOME = 'home',
  TOWN = 'town',
}

export interface IGameState {
  scene: number;
  frame: number;
  zoom: boolean;
}

export const getGameStateAtomByMap = atomFamily<IGameState, MapNames>({
  key: `GameState`,
  default: {
    scene: 0,
    frame: 0,
    zoom: false,
  },
  effects_UNSTABLE: (mapName) => [
    localStorageEffect(`signTown__${mapName as string}__State`),
  ],
});
