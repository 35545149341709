export const partNames = [
  'nose',
  'leftEye',
  'rightEye',
  'leftEar',
  'rightEar',
  'leftShoulder',
  'rightShoulder',
  'leftElbow',
  'rightElbow',
  'leftWrist', // 9
  'rightWrist', // 10
  'leftHip',
  'rightHip',
  'leftKnee',
  'rightKnee',
  'leftAnkle',
  'rightAnkle',
  'leftMidfin', // 17
  'rightMidfin', // 18
  /*
    left hand oreintation using 9-17
    right hand oreintation using 10-18
    */
];

export const SELECTED_POSENET_JOINTS = [
  'nose',
  'leftEye',
  'rightEye',
  'leftEar',
  'rightEar',
  'leftShoulder',
  'rightShoulder',
  'leftElbow',
  'rightElbow',
  'leftWrist',
  'rightWrist',
  'leftMidfin',
  'rightMidfin',
];

export const SELECTED_FACE_POINTS = [
  78,
  191,
  80,
  13,
  310,
  415,
  308,
  324,
  318,
  14,
  88,
  95,
  107,
  69,
  105,
  52,
  159,
  145,
  336,
  299,
  334,
  282,
  386,
  374,
];

export const LABELS = [
  '0_Idle',
  'Hksl_able_to',
  'Hksl_bathroom',
  'Hksl_bicycle-Jsl_bicycle',
  'Hksl_body_soap',
  'Hksl_busy',
  'Hksl_carrot',
  'Hksl_cash',
  'Hksl_chef',
  'Hksl_coffee',
  'Hksl_correct',
  'Hksl_credit_cards',
  'Hksl_dog',
  'Hksl_double_bed',
  'Hksl_dragonfly',
  'Hksl_finish',
  'Hksl_flip_flops',
  'Hksl_fortune_teller',
  'Hksl_garden',
  'Hksl_ginger',
  'Hksl_good',
  'Hksl_grass',
  'Hksl_grey',
  'Hksl_hat-Jsl_cap',
  'Hksl_hearing_aid-Jsl_hearing_aid',
  'Hksl_high_heels',
  'Hksl_how_are_you',
  'Hksl_jacket',
  'Hksl_leisurely',
  'Hksl_lemon',
  'Hksl_let_it_go',
  'Hksl_lipstick',
  'Hksl_machine',
  'Hksl_mango',
  'Hksl_matter',
  'Hksl_milk_tea',
  'Hksl_motorcycle-Jsl_motorcycle',
  'Hksl_non_smoking_room',
  'Hksl_obstruct',
  'Hksl_onsite_payment',
  'Hksl_panda',
  'Hksl_pasta',
  'Hksl_post_office',
  'Hksl_purple',
  'Hksl_read',
  'Hksl_reservation_booking',
  'Hksl_shampoo',
  'Hksl_shark',
  'Hksl_shirt-Jsl_shirt',
  'Hksl_signature',
  'Hksl_single_bed',
  'Hksl_smoking_room',
  'Hksl_soft_drink',
  'Hksl_special',
  'Hksl_steak',
  'Hksl_strange',
  'Hksl_summer',
  'Hksl_sunglasses',
  'Hksl_supermarket',
  'Hksl_swimming_pool',
  'Hksl_tiger',
  'Hksl_tomato',
  'Hksl_trouble',
  'Hksl_understand',
  'Hksl_waiter',
  'Hksl_watermelon',
  'Hksl_welcome',
  'Hksl_wine',
  'Hksl_winter-Jsl_winter',
  'Hksl_worry',
  'Hksl_write',
  'Hksl_yes',
  'Jsl_age',
  'Jsl_ahh',
  'Jsl_bathroom',
  'Jsl_be_good_at',
  'Jsl_bird',
  'Jsl_body_soap',
  'Jsl_brown',
  'Jsl_canteen',
  'Jsl_cap_boshi',
  'Jsl_carrot',
  'Jsl_carrot_fist',
  'Jsl_cash',
  'Jsl_cat',
  'Jsl_credit_cards',
  'Jsl_department_store',
  'Jsl_dog',
  'Jsl_double_bed',
  'Jsl_dragonfly',
  'Jsl_draught_beer',
  'Jsl_dream',
  'Jsl_earring',
  'Jsl_elevator',
  'Jsl_example',
  'Jsl_fly',
  'Jsl_geta',
  'Jsl_get_up',
  'Jsl_goldfish',
  'Jsl_gray',
  'Jsl_hello_with_one_hand',
  'Jsl_hello_with_two_hands',
  'Jsl_high_heels',
  'Jsl_hot_spring',
  'Jsl_illness',
  'Jsl_imagination',
  'Jsl_insect',
  'Jsl_jacket',
  'Jsl_japanese_radish',
  'Jsl_law',
  'Jsl_lemon',
  'Jsl_lie',
  'Jsl_milk_tea',
  'Jsl_non_smoking_room',
  'Jsl_onsite_payment',
  'Jsl_orange_juice',
  'Jsl_oversleep',
  'Jsl_pasta',
  'Jsl_pine',
  'Jsl_programmer',
  'Jsl_reservation_booking',
  'Jsl_rice_field',
  'Jsl_shampoo',
  'Jsl_shirt',
  'Jsl_single_bed',
  'Jsl_smoking_room',
  'Jsl_sports_athelets',
  'Jsl_steak',
  'Jsl_strawberry',
  'Jsl_summer',
  'Jsl_swim',
  'Jsl_swimming_pool',
  'Jsl_tell_say',
  'Jsl_tomato',
  'Jsl_unique_not_usual',
  'Jsl_waiter',
  'Jsl_watch',
  'Jsl_wine',
  'Jsl_yellow',
];

interface MultitagsType {
  [key: string]: number;
}

export const MULTI_TAGS: MultitagsType = {
  Hksl_bicycle: 3,
  Jsl_bicycle: 3,
  Hksl_hat: 19,
  Jsl_cap: 19,
  Hksl_hearing_aid: 20,
  Jsl_hearing_aid: 20,
  Hksl_motorcycle: 29,
  Jsl_motorcycle: 29,
  Hksl_winter: 49,
  Jsl_winter: 49,
};

export const SELECTED_POSE_JOINTS = [
  0,
  2,
  5,
  7,
  8,
  11,
  12,
  15,
  16, //hand.
  17,
  18,
  19,
  20,
  21,
  22,
];

export const SELECTED_FACE_JOINTS = [
  1,
  78,
  191,
  80,
  13,
  310,
  415,
  308,
  324,
  318,
  14,
  88,
  95,
  107,
  69,
  105,
  52,
  159,
  145,
  336,
  299,
  334,
  282,
  386,
  374,
];

export const NUM_FRAME_SAMPLES = 16;
export const BLAZEPOSE_HANDS_THRES = 0.7;

export const L_HAND_IDX_RAW = [15, 17, 19, 21];
export const R_HAND_IDX_RAW = [16, 18, 20, 22];

export const L_HAND_IDX = [
  SELECTED_POSE_JOINTS.indexOf(15),
  SELECTED_POSE_JOINTS.indexOf(17),
  SELECTED_POSE_JOINTS.indexOf(19),
  SELECTED_POSE_JOINTS.indexOf(21),
];
export const R_HAND_IDX = [
  SELECTED_POSE_JOINTS.indexOf(16),
  SELECTED_POSE_JOINTS.indexOf(18),
  SELECTED_POSE_JOINTS.indexOf(20),
  SELECTED_POSE_JOINTS.indexOf(22),
];

export const LABELS_CLASSIFY = [
  '0_Idle',
  'Hksl_able_to',
  'Hksl_bicycle-Jsl_bicycle',
  'Hksl_busy',
  'Hksl_carrot',
  'Hksl_chef',
  'Hksl_coffee',
  'Hksl_correct',
  'Hksl_dog',
  'Hksl_dragonfly',
  'Hksl_finish',
  'Hksl_flip_flops',
  'Hksl_fortune_teller',
  'Hksl_garden',
  'Hksl_ginger',
  'Hksl_good',
  'Hksl_grass',
  'Hksl_grey',
  'Hksl_hat-Jsl_cap',
  'Hksl_hearing_aid-Jsl_hearing_aid',
  'Hksl_high_heels',
  'Hksl_how_are_you',
  'Hksl_leisurely',
  'Hksl_let_it_go',
  'Hksl_lipstick',
  'Hksl_machine',
  'Hksl_matter',
  'Hksl_milk_tea',
  'Hksl_motorcycle-Jsl_motorcycle',
  'Hksl_obstruct',
  'Hksl_panda',
  'Hksl_post_office',
  'Hksl_purple',
  'Hksl_read',
  'Hksl_shark',
  'Hksl_signature',
  'Hksl_soft_drink',
  'Hksl_special',
  'Hksl_strange',
  'Hksl_summer',
  'Hksl_sunglasses',
  'Hksl_supermarket',
  'Hksl_tiger',
  'Hksl_tomato',
  'Hksl_trouble',
  'Hksl_understand',
  'Hksl_watermelon',
  'Hksl_welcome',
  'Hksl_wine',
  'Hksl_winter-Jsl_winter',
  'Hksl_worry',
  'Hksl_write',
  'Hksl_yes',
  'Jsl_age',
  'Jsl_ahh',
  'Jsl_be_good_at',
  'Jsl_bird',
  'Jsl_brown',
  'Jsl_canteen',
  'Jsl_carrot',
  'Jsl_cat',
  'Jsl_department_store',
  'Jsl_dog',
  'Jsl_dragonfly',
  'Jsl_draught_beer',
  'Jsl_dream',
  'Jsl_earring',
  'Jsl_elevator',
  'Jsl_example',
  'Jsl_fly',
  'Jsl_geta',
  'Jsl_get_up',
  'Jsl_goldfish',
  'Jsl_grey',
  'Jsl_hello_with_one_hand',
  'Jsl_hello_with_two_hands',
  'Jsl_high_heels',
  'Jsl_hot_spring',
  'Jsl_illness',
  'Jsl_imagination',
  'Jsl_insect',
  'Jsl_japanese_radish',
  'Jsl_law',
  'Jsl_lemon',
  'Jsl_lie',
  'Jsl_milk_tea',
  'Jsl_orange_juice',
  'Jsl_oversleep',
  'Jsl_pine',
  'Jsl_programmer',
  'Jsl_rice_field',
  'Jsl_sports_athelets',
  'Jsl_strawberry',
  'Jsl_summer',
  'Jsl_swim',
  'Jsl_tell_say',
  'Jsl_unique_not_usual',
  'Jsl_watch',
  'Jsl_wine',
  'Jsl_yellow',
];
