import React from 'react';
import styled from 'styled-components';

const CanvasResultWrapper = styled.div`
  z-index: -1;
  position: absolute;
  width: 35%;
  height: 55%;
  top: 20%;
  left: 55%;
  opacity: 0;
  canvas {
    position: absolute;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: -1;
    top: 0;
    left: 0;
  }
`;

interface ICanvasResult {
  id?: string;
}

const CanvasResult: React.FC<ICanvasResult> = (props: ICanvasResult) => {
  return (
    <CanvasResultWrapper
      id={props.id ? props.id : 'canvas-result-wrapper'}
    ></CanvasResultWrapper>
  );
};

export default CanvasResult;
