import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import styled, { css } from 'styled-components';
import { ParentSizeProvidedProps } from '@vx/responsive/lib/components/ParentSize';

import getAssetPosition from '../../utils/getAssetStyle';
import { getGameStateAtomByMap, MapNames } from '../../recoils/gameStateAtom';
import SVGResponsivePlayer from '../../components/SVGResponsivePlayer';
import getAssetLottie from '../../utils/getAssetLottie';
import { IPlayerProps } from '../../components/SVGResponsivePlayer/CustomLottiePlayer';
import { AnimationSegment } from 'lottie-web';
import {
  deafCultureName,
  deafCultureStateAtom,
} from '../../recoils/deafCultureStateAtom';
import config from '../../config';

// export type AssetSegment = {
//   nocolor: AnimationSegment;
//   nocolortocolor: AnimationSegment;
//   color: AnimationSegment;
//   colortonocolor: AnimationSegment;
// };

export interface IAssetProps {
  sceneIndex?: number;
  mapName?: MapNames;
  sceneName?: string;
  layerName?: string;
  dimension?: ParentSizeProvidedProps;
  src?: string;
  size: number;
  name?: string;
  frame: { start: number; end: number; color?: number };
  start: { x: number; y: number };
  speed: number;
  lottieProps?: Partial<IPlayerProps>;
  segments?: AnimationSegment[];
  flip?: boolean;
  deafCulture?: deafCultureName | deafCultureName[];
  transitionWeight?: number;
  children?: React.ReactElement;
  startOnScene?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const LottieAssetContainer = styled.g<{
  shouldTransition: boolean;
  transitionWeight?: number;
  translate: number;
}>`
  ${(props) =>
    props.shouldTransition
      ? `transition: all ${
          config.transition * (props.transitionWeight || 1)
        }s ease-in-out;`
      : ''}
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};

  ${(props) =>
    props.onClick &&
    css`
      &:hover .ambassador > .ambassador {
        fill: rgb(239, 194, 191);
      }
    `}

  transform: ${(props) => `translate(${props.translate}px, 0);`}
`;

const Asset = function ({
  flip = false,
  ...props
}: IAssetProps): React.ReactElement {
  const gameState = useRecoilValue(
    getGameStateAtomByMap(props.mapName as MapNames),
  );

  const { x, y, opacity } = getAssetPosition(gameState, props);

  // const previousX = usePrevious(x, 0);

  const assetRef = React.useRef<SVGGElement>(null);
  const previousX = assetRef.current?.getBoundingClientRect().x || 0;
  const goBack = previousX > x;
  const shouldFlip = flip && goBack;

  if (!props.dimension) return <></>;
  const lottieSrc = React.useMemo(() => {
    if (props.src) return props.src;
    if (!props.layerName || !props.sceneName || !props.mapName || !props.name)
      throw new Error('--- Asset should have name ---');
    return getAssetLottie(
      props.mapName,
      props.sceneName,
      props.layerName,
      props.name,
    );
  }, []);

  function renderCustomAsset() {
    if (!props.children) return false;
    return React.cloneElement(props.children, {
      dimension: props.dimension,
    });
  }

  const [, setDeafCultureState] = useRecoilState(deafCultureStateAtom);

  function openDeafCulture() {
    setDeafCultureState((oldState) => ({
      ...oldState,
      open: true,
      name: props.deafCulture,
    }));
  }

  const [shouldTransition, setShouldTransition] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setShouldTransition(true), 1000);
  }, []);

  const onScene = props.startOnScene === gameState.scene;

  return props.startOnScene === undefined || onScene ? (
    <LottieAssetContainer
      ref={assetRef}
      translate={x}
      shouldTransition={shouldTransition}
      transitionWeight={props.transitionWeight}
      opacity={opacity}
      onClick={props.deafCulture && openDeafCulture}
    >
      <g transform={`translate(0, ${y})`}>
        <g
          transform={shouldFlip ? 'scale(-1, 1)' : ''}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
        >
          {renderCustomAsset() || (
            <SVGResponsivePlayer
              src={lottieSrc}
              fitBy={'width'}
              side={props.size * props.dimension.width}
              segments={props.segments}
              lottieProps={props.lottieProps}
            />
          )}
        </g>
      </g>
    </LottieAssetContainer>
  ) : (
    <></>
  );
};

Asset.displayName = 'ParallaxMapAsset';
export default Asset;
