import * as React from 'react';
import { Path } from '.';

export default function JSL({
  active,
}: {
  active: boolean;
}): React.ReactElement {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M29.3458 23.7548L24.2636 18.6639C25.0628 17.0011 25.5137 15.1126 25.5137 13.1419C25.5137 6.0803 19.7962 0.353027 12.7466 0.353027C5.71752 0.353027 0 6.0803 0 13.1214C0 16.3237 1.18859 19.2592 3.11492 21.4967V28.5788C3.13541 32.3354 6.16837 35.353 9.91857 35.353C13.1155 35.353 15.7795 33.1565 16.5173 30.18L19.7142 33.3824C22.3783 36.0099 26.6613 36.0099 29.3049 33.3618C31.9689 30.7137 31.9894 26.4029 29.3458 23.7548Z"
        fill={active ? '#7ECBBD' : '#C4C4C4'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1736 14.327C10.5981 14.327 10.1315 13.8605 10.1315 13.285L10.1315 12.4475C10.1315 11.872 10.5981 11.4055 11.1736 11.4055C11.749 11.4055 12.2156 11.872 12.2156 12.4475L12.2156 13.285C12.2156 13.8605 11.749 14.327 11.1736 14.327Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9247 14.327C14.3492 14.327 13.8826 13.8605 13.8826 13.285L13.8826 12.4475C13.8826 11.872 14.3492 11.4055 14.9247 11.4055C15.5001 11.4055 15.9667 11.872 15.9667 12.4475L15.9667 13.285C15.9667 13.8605 15.5001 14.327 14.9247 14.327Z"
        fill="black"
      />
    </svg>
  );
}
