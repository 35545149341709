import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import {
  SOUNDSTATE,
  soundStateAtom,
  SOUNDSTATUS,
  soundStatusAtom,
} from '../../recoils/soundStateAtom';
import useSound from '../../utils/useSound';
import { VolumeIcon } from '../Icons';

const Button = styled.button<{ active?: boolean }>`
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 5;

  outline: none;
  background: none;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 75px;
  height: 35px;

  color: ${(props) => (props.active ? '#7ecbbd' : '#696969')};
`;

const StatusText = styled.div`
  position: absolute;
  left: 45px;
  top: 0;
  height: 100%;
  width: max-content;

  display: flex;
  align-items: center;

  font-size: 17px;
  font-weight: bold;
`;

// const VolumeBar = styled.input<{ soundLevel: number }>`
//   height: 7.5px;
//   width: 100px;
//   background: #696969;
//   border-radius: 5px;
//   margin-left: 15px;

//   -webkit-appearance: none;
//   appearance: none;
//   outline: none;
//   cursor: pointer;

//   position: relative;

//   &::-webkit-slider-thumb {
//     /* -webkit-appearance: none;
//     appearance: none; */
//     opacity: 0;
//   }

//   &::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;

//     width: ${(props) => props.soundLevel * 100}%;
//     height: 100%;
//     background: #7ecbbd;

//     border-radius: 5px;
//   }
// `;

type SoundComponentHandle = {
  handlePlaySound: () => void;
};
const SoundComponent: ForwardRefRenderFunction<SoundComponentHandle> = (
  _,
  forwardedRef,
) => {
  const birdSound = useSound('./assets/sound/forest-birds-chirp.mp3');
  const [soundState] = useRecoilState(soundStateAtom);
  const [soundStatus, updateSoundStatus] = useRecoilState(soundStatusAtom);
  // const [soundLevel, updateSoundLevel] = useRecoilState(soundLevelAtom);
  const { t } = useTranslation();

  useEffect(() => {
    if (soundStatus === SOUNDSTATUS.DISABLED) {
      birdSound.pause();
      birdSound.setCurrentTime(0);
    } else {
      switch (soundState) {
        case SOUNDSTATE.PAUSE:
          birdSound.pause();
          birdSound.setCurrentTime(0);
          break;
        case SOUNDSTATE.PLAY:
          birdSound.play();
          break;
      }
    }
  }, [soundStatus, soundState]);

  // useEffect(() => {
  //   document.addEventListener(
  //     'visibilitychange',
  //     handleVisibilityChange,
  //     false,
  //   );
  // }, []);

  // const handleVisibilityChange = () => {
  //   if (document['hidden']) {
  //     console.log('hidden');
  //     updateSoundState(SOUNDSTATE.PAUSE);
  //   } else {
  //     console.log('visible');
  //     updateSoundState(SOUNDSTATE.PLAY);
  //   }
  // };

  const handlePlaySound = () => {
    if (soundState === SOUNDSTATE.PAUSE) {
      return;
    }
    birdSound.setAttribute('loop', 'true');
    birdSound.play();
  };

  useImperativeHandle(forwardedRef, () => ({
    handlePlaySound: handlePlaySound,
  }));

  function handleMuteClick() {
    if (soundStatus === SOUNDSTATUS.DISABLED) {
      updateSoundStatus(SOUNDSTATUS.ENABLE);
    } else {
      updateSoundStatus(SOUNDSTATUS.DISABLED);
    }
  }
  const active = soundStatus === SOUNDSTATUS.ENABLE;
  return (
    <Button active={active} onClick={handleMuteClick}>
      <VolumeIcon active={active} />
      {/* {soundStatus === SOUNDSTATUS.ENABLE && (
        <VolumeBar
          type="range"
          min={0}
          max={1}
          step={0.1}
          soundLevel={soundLevel}
          onInput={(e) => updateSoundLevel(+(e.target as any).value)}
        />
      )} */}
      <StatusText>{active ? t('global.on') : t('global.off')}</StatusText>
    </Button>
  );
};

export default forwardRef(SoundComponent);
