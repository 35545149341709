import { atom } from 'recoil';
import { localStorageEffect } from '../utils/localStorageEffect';
export enum SignLanguageList {
  HKSL = 'HKSL',
  JSL = 'JSL',
}

export const signLanguageAtom = atom<SignLanguageList>({
  key: 'signLanguage',
  default: SignLanguageList.JSL,
  effects_UNSTABLE: [localStorageEffect('signLanguage')],
});
