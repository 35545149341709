import * as React from 'react';

import styled from 'styled-components';
import { ParentSizeProvidedProps } from '@vx/responsive/lib/components/ParentSize';

import { ILayerProps } from './Layer';
import { MapNames } from '../../recoils/gameStateAtom';

const SceneGroup = styled.g`
  transition: transform 5s linear;
`;

export interface ISceneProps {
  name: string;
  mapName?: MapNames;
  layerName?: string;
  dimension?: ParentSizeProvidedProps;
  sceneIndex?: number;
  children: React.ReactElement | [React.ReactElement, React.ReactElement];
}

function Scene(props: ISceneProps): React.ReactElement {
  const position = { x: 0, y: 0 };

  function renderLayer() {
    if (Array.isArray(props.children))
      throw new Error(
        '--- Scene with multiple layer should not be rendered ---',
      );
    return React.cloneElement<ILayerProps>(props.children, {
      sceneIndex: props.sceneIndex,
      mapName: props.mapName,
      sceneName: props.name,
      layerName: props.layerName,
      dimension: props.dimension,
    });
  }

  return (
    <SceneGroup transform={`translate(${position.x}, ${position.y})`}>
      {renderLayer()}
    </SceneGroup>
  );
}

export default Scene;
