/**
 * The bundles here are configured so that each locale only requires loading a single webpack chunk.
 */

const bundles = {
  en: (): any => import('./locales/en.json'),
  jp: (): any => import('./locales/jp.json'),
  hk: (): any => import('./locales/hk.json'),
};

// generate whitelist for i18next
export const availableLocales = Object.keys(bundles);

export default bundles;
