import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ActionButton } from '../../components/Button';
import DropdownSelection from '../../components/DropdownSelection';
import TopBackground from '../../layout/TopBackground';
import shuwaHandbookLogo from '../../assets/logo/shuwa_handbook_logo.svg';

interface FullScreenI {
  show: boolean;
}
const FullScreen = styled.div<FullScreenI>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.show ? `translate(-50%, -50%)` : `translate(-50%, -45%)`};
  opacity: ${(props) => (props.show ? `1` : `0`)};
  z-index: ${(props) => (props.show ? `1` : `-1`)};

  transition: all 0.5s;
`;

const HeroWrapper = styled.section`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;
const ControllerWrapper = styled.section`
  position: absolute;
  top: 70%;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface iTitle {
  isShow: boolean;
}

const Title = styled.h1<iTitle>`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 1;

  height: 28vh;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 30px;
  font-weight: bold;
  text-align: center;

  font-family: Quicksand;

  color: #000;
`;

const Google = styled.p`
  position: absolute;
  bottom: 50px;
  left: 175px;

  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignTownHandbookBox = styled.div`
  position: absolute;
  bottom: 35px;
  right: 5%;

  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  background-color: transparent;
  border-radius: 4rem;
  padding: 10px;

  transition: all 0.2s;

  cursor: pointer;
  p {
    color: #1f1f1f;
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    font-family: Quicksand;
  }

  :hover {
    border-color: #1f1f1f;
    box-shadow: none;
    background-color: #f1f1f1;
  }
`;

interface iHome {
  show: boolean;
  allowSoundPlayState: () => void;
  onStartButtonClick: () => void;
}
const Home: React.FC<iHome> = (props: iHome) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isShow, updateIsShow] = React.useState<boolean>(false);

  React.useEffect(() => {
    updateIsShow(true);
  }, []);

  const handleClickStart = () => {
    props.onStartButtonClick();
    updateIsShow(false);
    history.push('/onboarding');
  };

  return (
    <FullScreen show={props.show}>
      <TopBackground>
        <HeroWrapper>
          <Title isShow={isShow}>{t('home.title')}</Title>
        </HeroWrapper>
      </TopBackground>
      <ControllerWrapper>
        <DropdownSelection />
        <ActionButton onClick={handleClickStart}>
          {t('home.start')}
        </ActionButton>
        <Google
          dangerouslySetInnerHTML={{
            __html: t('home.google', { interpolation: { escapeValue: false } }),
          }}
        ></Google>
        <a
          href={'https://handbook.sign.town/'}
          target="_blank"
          rel="noreferrer"
        >
          <SignTownHandbookBox>
            <img src={shuwaHandbookLogo} style={{ height: '3rem' }} />
            <p>{t('home.handbook')}</p>
          </SignTownHandbookBox>
        </a>
      </ControllerWrapper>
    </FullScreen>
  );
};

export default Home;
