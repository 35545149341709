import styled from 'styled-components';

export { default as HKSLIcon } from './HKSL';
export { default as JSLIcon } from './JSL';
export { default as AchievementIcon } from './Achievement';
export { default as ArrowIcon } from './Arrow';
export { default as VolumeIcon } from './Volume';

export const Path = styled.path`
  &:hover {
    fill: #7ecbbd;
  }
`;
