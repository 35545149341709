import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { ActionButton } from '../../components/Button';
import TopBackground from '../../layout/TopBackground';
import { useRecoilState } from 'recoil';
import { getGameStateAtomByMap, MapNames } from '../../recoils/gameStateAtom';
import InstructionModal from '../../components/InstructionModal';

/* 
structure
    hero wrapper
        text
        animation
    controller wrapper
        pagination
        button
*/

const HeroWrapper = styled.section`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`;

const HeroText = styled.div<{ left: string }>`
  position: absolute;
  top: 20vh;
  left: ${(props) => props.left};
  transform: translate(-50%, 0);
  height: 20vh;
  width: 33%;
  max-width: 300px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  transition: all 0.5s;
  color: #000;
  white-space: pre-line;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

/* const Animation = styled.div``; */
const ControllerWrapper = styled.section`
  position: absolute;
  top: 70%;
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  position: relative;
`;

const PermissionText = styled.p`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, -100%);

  font-size: 19px;
  font-weight: 600;
  white-space: pre;
`;

const Remark = styled.p`
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translate(-50%, 100%);

  font-size: 14px;
  font-weight: 600;
  white-space: pre;
`;

const Icon = styled.img``;

const INSTRUCTION_TEXT = [
  'onboarding.instruction.camera',
  'onboarding.instruction.record',
  'onboarding.instruction.ml',
];

interface InstructionProps {
  allowSoundPlayState: () => void;
}
const Instruction: React.FC<InstructionProps> = (props: InstructionProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, setGameState] = useRecoilState(getGameStateAtomByMap(MapNames.HOME));
  const [instructionModalIsOpen, setInstructionModalIsOpen] = React.useState(
    false,
  );

  // async function askForPermission() {
  //   const constraints = {
  //     audio: false,
  //     video: {
  //       facingMode: 'user', // 'user' or 'environment'
  //     },
  //   };
  //   try {
  //     const mediaStream = await navigator.mediaDevices.getUserMedia(
  //       constraints,
  //     );
  //     mediaStream.getVideoTracks().forEach(function (track) {
  //       track.stop();
  //     });
  //   } catch (err) {
  //     console.log(err.name);
  //     if (err.name === 'NotAllowedError') {
  //       updateCameraError(ErrorStatus.CAMERA_PERMISSION);
  //       return;
  //     } else {
  //       updateCameraError(ErrorStatus.CAMERA_UNDETECTED);
  //       return;
  //     }
  //   }
  // }

  const howToPlay = async () => {
    // await askForPermission();
    setInstructionModalIsOpen(true);
  };
  const goNext = () => {
    props.allowSoundPlayState();
    history.push('/signselection');
    setGameState((prev) => ({ ...prev, scene: prev.scene + 1 }));
  };
  // const goBack = () => {
  //   if (instructionState > 0) {
  //     props.handleGoBack();
  //   } else {
  //     props.allowSoundPlayState();
  //     history.push('/');
  //   }
  //   setGameState((prev) => ({ ...prev, scene: prev.scene - 1 }));
  // };
  return (
    <>
      <TopBackground>
        {/* <HeroWrapperFn /> */}

        <HeroWrapper>
          <HeroText left={'20%'}>
            <Icon src={'/assets/icons/camera.svg'} alt="camera icon" />
            <span>{t(INSTRUCTION_TEXT[0])}</span>
          </HeroText>
          <HeroText
            left={'50%'}
            /* style={{ whiteSpace: 'nowrap' }} */
          >
            <Icon src={'/assets/icons/person.svg'} alt="record icon" />
            <span>{t(INSTRUCTION_TEXT[1])}</span>
          </HeroText>
          <HeroText left={'80%'}>
            <Icon src={'/assets/icons/cog.svg'} alt="machine learning icon" />
            <span>{t(INSTRUCTION_TEXT[2])}</span>
          </HeroText>
        </HeroWrapper>
      </TopBackground>
      <ControllerWrapper>
        <ButtonWrapper>
          <PermissionText>{t('onboarding.instruction.open')}</PermissionText>
          <ActionButton onClick={howToPlay}>
            {t('onboarding.instruction.yes')}
          </ActionButton>
          <Remark>{t('onboarding.instruction.remark')}</Remark>
        </ButtonWrapper>
      </ControllerWrapper>
      <InstructionModal
        open={instructionModalIsOpen}
        onCloseButtonClick={() => {
          setInstructionModalIsOpen(false);
        }}
        onFinish={() => {
          setInstructionModalIsOpen(false);
          goNext();
        }}
      />
    </>
  );
};

export default Instruction;
