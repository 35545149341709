import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import Signing from './Signing';
import {
  Modal,
  ModalContainer,
  modalContainerTransition,
  modalTransition,
} from '../../layout/Modal';
import { MapNames } from '../../recoils/gameStateAtom';
import SignInstruction from './SignInstruction';
import SignSelection from './SignSelection';
import { useRecoilState, useRecoilValue } from 'recoil';
import { signLanguageAtom, SignLanguageList } from '../../recoils/signLanguage';
import { videoListConfig } from './config';
import { Transition } from 'react-transition-group';
import Achievement from './Achievement';
import usePrevious from '../../utils/usePrevious';
import Hooray from './Hooray';
import { PlayerEvent } from '../../components/SVGResponsivePlayer/CustomLottiePlayer';
import useSound from '../../utils/useSound';
import { lastPageAtom } from '../../recoils/playStateAtom';
import { useHistory, useLocation } from 'react-router';

const SignPlayWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100%;
  width: 100%;
`;

interface ISignPlay {
  open: boolean;
  Button: React.ReactElement;
  mapName: MapNames;
  nextScene: () => void;
  thisScene: number;
  children?: React.ReactNode;
  closeModal: () => void;
}

interface SelecSigningInput {
  signingNumberArray: number[];
  signingTextArray: string[];
}

export enum SignPlayState {
  Instruction,
  SignSelection,
  Signing,
  Hooray,
  Achievement,
}

let isInstructed = true;
const SignPlay: React.FC<ISignPlay> = (props: ISignPlay) => {
  const [signPlayState, updateSignPlayState] = useState<number>(
    SignPlayState.Instruction,
  );
  const previousSignPlayState = usePrevious(
    signPlayState,
    SignPlayState.Instruction,
  );
  const [signingNumberArraySelect, updateSigningNumberArraySelect] = useState<
    number[]
  >([-1, -1]);
  const [signingTextArraySelect, updateSigngingTextArraySelect] = useState<
    string[]
  >(['', '']);
  const signLanguage = useRecoilValue<SignLanguageList>(signLanguageAtom);
  const [videoList, updateVideoList] = useState<string[][]>(
    videoListConfig[props.mapName][props.thisScene][signLanguage.toLowerCase()],
  );
  const [showCloseButton, setShowCloseButton] = useState(true);

  // sound
  const cheersound = useSound('./assets/sound/cheer.wav');

  const [isGoNextSign, setIsGoNextSign] = useState(0);

  React.useEffect(() => {
    function onCloseEsc(e: KeyboardEvent) {
      if (e.keyCode === 27) {
        props.closeModal();
      }
    }
    document.addEventListener('keydown', onCloseEsc);
    return () => document.removeEventListener('keydown', onCloseEsc);
  }, [props.closeModal]);

  const [gonnaClose, set_gonnaClose] = useState<boolean>(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const searchUrl = window.location.search;
    const searchParams = new URLSearchParams(searchUrl);
    const signState = searchParams.get('signstate');
    if (signState) {
      if (gonnaClose && parseInt(signState) === 2) {
        props.closeModal();
      } else {
        console.log('set close modal');
        set_gonnaClose(true);
        updateSignPlayState(parseInt(signState));
      }
    }
  }, [location]);
  const nextState = () => {
    const nextSignState = signPlayState + 1;
    if (gonnaClose) {
      set_gonnaClose(false);
    }
    history.push(
      window.location.pathname +
        '?openmodal=true' +
        '&scene=' +
        props.thisScene +
        '&signstate=' +
        nextSignState,
    );
  };
  const nextStateFromSigning = () => {
    setIsGoNextSign(1);
    const nextSignState = signPlayState + 1;
    history.push(
      window.location.pathname +
        '?openmodal=true' +
        '&scene=' +
        props.thisScene +
        '&signstate=' +
        nextSignState,
    );
  };
  const backState = () => {
    const nextSignState = signPlayState - 1;
    history.push(
      window.location.pathname +
        '?openmodal=true' +
        '&scene=' +
        props.thisScene +
        '&signstate=' +
        nextSignState,
    );
  };

  const signSelectionState = () => {
    history.push(
      window.location.pathname +
        '?openmodal=true' +
        '&scene=' +
        props.thisScene +
        '&signstate=' +
        SignPlayState.SignSelection,
    );
  };

  const selectSigning = (input: SelecSigningInput) => {
    updateSigningNumberArraySelect(input.signingNumberArray);
    updateSigngingTextArraySelect(input.signingTextArray);
  };

  const [signPageSelect, updateSignPageSelect] = useState<number>(0);

  const handleSelectSignPage = (input: number) => {
    updateSignPageSelect(input);
  };

  const handleNextScene = () => {
    props.nextScene();
    // const nextSignState = isInstructed
    //   ? SignPlayState.SignSelection
    //   : SignPlayState.Instruction;
    // history.push(
    //   window.location.pathname + '?openmodal=true&signstate=' + nextSignState,
    // );
    updateSigningNumberArraySelect([-1, -1]);
  };

  useEffect(() => {
    if (props.open === false) {
      setTimeout(() => {
        updateSignPlayState(
          isInstructed
            ? SignPlayState.SignSelection
            : SignPlayState.Instruction,
        );
        updateSigningNumberArraySelect([-1, -1]);
        isInstructed = true;
      }, 500);
    }
    updateSignPageSelect(0);
  }, [props.open]);

  useEffect(() => {
    updateVideoList(
      videoListConfig[props.mapName][props.thisScene][
        signLanguage.toLowerCase()
      ],
    );
  }, [signLanguage, props.thisScene]);

  const [, updateLastPage] = useRecoilState(lastPageAtom);

  function goToAchievement() {
    updateLastPage('signing_badge');
    updateSignPlayState(SignPlayState.Achievement);
  }

  function goToHooray() {
    setIsGoNextSign(1);
    updateLastPage('signing_hurrah');
    updateSignPlayState(SignPlayState.Hooray);
    cheersound.play();
  }

  function skipSign() {
    setIsGoNextSign(1);
    set_gonnaClose(true);
    history.push(
      window.location.pathname +
        '?openmodal=true' +
        '&scene=' +
        props.thisScene +
        '&signstate=' +
        SignPlayState.SignSelection,
    );
  }

  const handleSignState = (newVal: boolean) => setShowCloseButton(newVal);

  const nodeRef = React.useRef(null);

  return (
    <>
      <Transition
        nodeRef={nodeRef}
        in={props.open}
        timeout={{ appear: 0, exit: 500 }}
        unmountOnExit
      >
        {(status) => (
          <ModalContainer
            ref={nodeRef}
            transition={{ css: modalContainerTransition, status: status }}
          >
            <Modal
              transition={{ css: modalTransition, status }}
              foldedColor={'white'}
            >
              {showCloseButton && props.children}
              <SignPlayWrapper>
                {signPlayState === SignPlayState.Instruction && (
                  <SignInstruction nextState={nextState} />
                )}
                {signPlayState === SignPlayState.SignSelection && (
                  <SignSelection
                    nextState={nextState}
                    selectSigning={selectSigning}
                    mapName={props.mapName}
                    videoList={videoList}
                    thisScene={props.thisScene}
                    handleSelectSignPage={handleSelectSignPage}
                    goToAchievement={goToAchievement}
                    signPageSelect={signPageSelect}
                    goNextSign={isGoNextSign}
                    resetIsGoNextSign={() => setIsGoNextSign(0)}
                    previousSignPlayState={previousSignPlayState}
                  />
                )}
                {signPlayState === SignPlayState.Signing && (
                  <Signing
                    nextState={nextStateFromSigning}
                    goToHooray={goToHooray}
                    backState={backState}
                    signingNumberArraySelect={signingNumberArraySelect}
                    signingTextArraySelect={signingTextArraySelect}
                    signSelectionState={signSelectionState}
                    mapName={props.mapName}
                    thisScene={props.thisScene}
                    signPageSelect={signPageSelect}
                    setShowCloseButton={handleSignState}
                    skipSign={skipSign}
                  />
                )}
                {signPlayState === SignPlayState.Hooray && (
                  <Hooray
                    onEnded={(event: PlayerEvent) => {
                      if (event === PlayerEvent.Complete) {
                        goToAchievement();
                      }
                    }}
                  />
                )}
                {signPlayState === SignPlayState.Achievement && (
                  <Achievement
                    nextScene={handleNextScene}
                    signSelectionState={signSelectionState}
                    videoList={videoList}
                    mapName={props.mapName}
                    thisScene={props.thisScene}
                    fromSignPlayState={previousSignPlayState}
                  />
                )}
              </SignPlayWrapper>
            </Modal>
          </ModalContainer>
        )}
      </Transition>
      {props.Button}
    </>
  );
};

export default SignPlay;
