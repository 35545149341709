import { atomFamily, selectorFamily, atom } from 'recoil';
import { localStorageEffect } from '../utils/localStorageEffect';
import { MapNames } from './gameStateAtom';
import { signLanguageAtom, SignLanguageList } from './signLanguage';

/*
play state : [jsl: [],hksl: []]
*/

export interface IPlayState {
  [key: number]: {
    [key in keyof typeof SignLanguageList]: string[];
  };
}

export const playStateAtomByMap = atomFamily({
  key: `PlayState`,
  default: { 0: { JSL: [], HKSL: [] } } as IPlayState,

  effects_UNSTABLE: (mapName) => [
    localStorageEffect(`signTown__${mapName as string}__playState`),
  ],
});

export const getSavedGameSelector = selectorFamily<string[], MapNames>({
  key: `SaveGameState`,
  get: (mapName) => ({ get }) => {
    const playState = get(playStateAtomByMap(mapName));
    const signLanguage = get(signLanguageAtom);

    const saveGame = Object.entries(playState).reduce(
      (save, playStateValues) => {
        const [sceneIdx, { [signLanguage]: passedSign }] = playStateValues;
        const passed = !!passedSign && passedSign.length;
        return passed ? [...save, sceneIdx] : save;
      },
      <string[]>[],
    );
    return saveGame;
  },
});

export enum e_playerPositionState {
  T_CLOSE = 'tooclose',
  OK = 'ok',
  T_FAR = 'toofar',
  NONE = 'none',
}

export const playerPositionState = atom<e_playerPositionState>({
  key: 'playerPositionState',
  default: e_playerPositionState.NONE,
});

export interface IplayerEngagement {
  sign_count: number;
  success_count: number;
  fail_count: number;
  os_name: string;
}

export const playerEngagementAtom = atom<IplayerEngagement>({
  key: 'player_engagement',
  default: {
    sign_count: 0,
    success_count: 0,
    fail_count: 0,
    os_name: 'unknown',
  },
});

export const osNameAtom = atom<string>({
  key: 'os_name',
  default: '',
});

export const lastPageAtom = atom<string>({
  key: 'last_page',
  default: '',
});
