import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ActionButton, PrimaryButton } from '../Button';

/**
 * new ux result
 */
const AnnotationText = styled.p`
  margin: 0;
  position: absolute;
  top: 60px;
  font-size: calc(18px * var(--scale, 1));
  font-weight: bold;
`;
const VideoWrapper = styled.div`
  position: absolute;
  width: 35%;
  height: 55%;
  top: 20%;
  right: 54%;
`;
const VideoTutorial = styled.video`
  border-radius: 15px;
  width: 100%;
  height: 100%;
`;

const RowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  z-index: 10;
  bottom: 10%;
  width: 100%;

  & button {
    margin: 0 0.5rem;
  }
`;
interface InstructionResultProps {
  handleTryAgain: () => void;
  onNextPage: () => void;
  pageState: string;
  isError?: boolean;
  kpDetectState?: string;
}

const AnnotationTextArr = [
  'onboarding.instruction.welldone',
  'onboarding.instruction.great',
  'onboarding.instruction.oops.',
  'onboarding.instruction.oops2',
];
const InstructionResult: React.FC<InstructionResultProps> = (
  props: InstructionResultProps,
) => {
  const { t } = useTranslation();

  if (props.pageState === 'trypage')
    return (
      <>
        <AnnotationText>
          {props.isError ? t(AnnotationTextArr[3]) : t(AnnotationTextArr[1])}
        </AnnotationText>
        <VideoWrapper className="video-tutorial-wrapper">
          <VideoTutorial
            src={`./assets/videos/HKSL/Hksl_panda.m4v`}
            muted
            playsInline
            loop
            id="video-tutorial-loop"
          />
        </VideoWrapper>

        <RowButtonWrapper>
          <ActionButton onClick={props.handleTryAgain}>
            {t('onboarding.instruction.tryagain')}
          </ActionButton>
          {!props.isError && (
            <PrimaryButton onClick={props.onNextPage}>
              {t('onboarding.instruction.next')}
            </PrimaryButton>
          )}
        </RowButtonWrapper>
      </>
    );
  else
    return (
      <>
        <AnnotationText>
          {props.kpDetectState === 'all' || props.kpDetectState === 'all-hand'
            ? t(AnnotationTextArr[0])
            : t(AnnotationTextArr[2] + props.kpDetectState)}
        </AnnotationText>
        <RowButtonWrapper>
          <ActionButton onClick={props.handleTryAgain}>
            {t('onboarding.instruction.tryagain')}
          </ActionButton>
          {(props.kpDetectState === 'all' ||
            props.kpDetectState === 'all-hand') && (
            <PrimaryButton onClick={props.onNextPage}>
              {t('onboarding.instruction.next')}
            </PrimaryButton>
          )}
        </RowButtonWrapper>
      </>
    );
};

export default InstructionResult;
