import { deafCultureName } from '../../recoils/deafCultureStateAtom';

export default function Background(cultureName: deafCultureName): string {
  return {
    [deafCultureName.LIGHT_ON]: '#eed66d',

    [deafCultureName.HAND_UP_DOWN]: '#bcb1da',

    [deafCultureName.HAND_WAVE_TAP_SHOULDER]: '#f3ab93',

    [deafCultureName.CHARACTER_SWITCH_POSITION]: '#d59bc5',

    [deafCultureName.BAG_CHARACTER]: '#7ecbbd',

    [deafCultureName.LIGHT_SWITCH]: 'rgb(154, 164, 176)',

    [deafCultureName.CLOCK_VIBRATE]: '#82b4dc',
  }[cultureName];
}
