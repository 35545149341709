import React from 'react';
import { useRecoilState } from 'recoil';

import {
  deafCultureName,
  deafCultureStateAtom,
} from '../../recoils/deafCultureStateAtom';
import DeafCultureModal, {
  DeafCultureContent,
} from '../../components/DeafCultureModal';
import Background from './Background';
import Viewbox from './Viewbox';

const DeafCulture = (): React.ReactElement => {
  const [deafCultureState, setDeafCultureState] = useRecoilState(
    deafCultureStateAtom,
  );

  function closeModal() {
    setDeafCultureState((oldState) => ({ ...oldState, open: false }));
  }

  let deafCultureContentProps: DeafCultureContent | DeafCultureContent[];

  if (
    typeof deafCultureState.name === 'string' ||
    typeof deafCultureState.name === 'undefined'
  ) {
    deafCultureContentProps = {
      name: deafCultureState.name as deafCultureName,
      background: Background(deafCultureState.name as deafCultureName),
      content: ['deafCulture', deafCultureState.name].join('.'),
      src: './assets/deafCulture/' + deafCultureState.name + '.json',
      viewBox: Viewbox(deafCultureState.name as deafCultureName),
    };
  } else {
    deafCultureContentProps = deafCultureState.name.map((name) => ({
      name: name as deafCultureName,
      background: Background(name as deafCultureName),
      content: ['deafCulture', name].join('.'),
      src: './assets/deafCulture/' + name + '.json',
      viewBox: Viewbox(name as deafCultureName),
    }));
  }

  return (
    <DeafCultureModal
      open={deafCultureState.open}
      onCloseButtonClick={closeModal}
      contents={deafCultureContentProps}
    />
  );
};

export default DeafCulture;
