import { atom } from 'recoil';

export enum ErrorStatus {
  CAMERA_UNDETECTED = 'CAMERA_UNDETECTED',
  CAMERA_PERMISSION = 'CAMERA_PERMISSION',
  DEVICE_INCOMPATIBLE = 'DEVICE_INCOMPATIBLE',
  ML_LOADING_FAILURE = 'ML_LOADING_FAILURE',
  BROWSER_INCOMPATIBLE = 'BROWSER_INCOMPATIBLE',
  WEBGL_ERROR = 'WEBGL_ERROR',
}

export const errorAtom = atom<ErrorStatus | undefined>({
  key: 'ErrorStatus',
  default: undefined,
});

export const errorListAtom = atom<string[]>({
  key: 'errorListAtom',
  default: [],
});
