import * as React from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import config from '../../config';
import {
  CharacterPose,
  characterStateAtom,
} from '../../recoils/characterStateAtom';
import { getGameStateAtomByMap, MapNames } from '../../recoils/gameStateAtom';
import useSound from '../../utils/useSound';
import { CustomLottiePlayer } from '../SVGResponsivePlayer/CustomLottiePlayer';
import { lastPageAtom } from '../../recoils/playStateAtom';

const Container = styled.div`
  position: fixed;
  top: 60vh;
  transform: translateY(-50%);
  left: 0;
  z-index: 10;

  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  padding: 0;

  pointer-events: none;
  & * {
    pointer-events: all;
  }
`;

const Controller = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  pointer-events: none;
  & * {
    pointer-events: all;
  }
`;

const Button = styled.button<{ left?: boolean }>`
  outline: none;
  border: none;
  background: transparent;

  font-size: 30px;
  border-radius: 15px;
  width: 150px;
  height: 100px;
`;

const sceneAnalytics = [
  'play_packing',
  'play_restaurant',
  'play_hotel',
  // 'play_zoo',
  'play_dog',
];

export default function SceneController({
  mapName,
  numScene,
}: {
  mapName: MapNames;
  numScene: number;
}): React.ReactElement {
  const [gameState, setGameState] = useRecoilState(
    getGameStateAtomByMap(mapName),
  );

  const [, setCharacterState] = useRecoilState(characterStateAtom);
  const [, updateLastPage] = useRecoilState(lastPageAtom);
  const dogsound = useSound('./assets/sound/dog.wav');
  const history = useHistory();

  function nextScene() {
    setGameState((oldState) => ({
      ...oldState,
      scene: oldState.scene + 1,
      frame: 0,
    }));
    history.push(
      window.location.pathname +
        '?openmodal=false' +
        '&scene=' +
        (gameState.scene + 1),
    );
    updateLastPage(sceneAnalytics[gameState.scene + 1]);
    walkFor(config.transition * 1000);
    if (gameState.scene === 4) {
      dogsound.setAttribute('loop', 'true');
      dogsound.play();
    }
  }

  function previousScene() {
    setGameState((oldState) => ({
      ...oldState,
      scene: Math.max(oldState.scene - 1, 0),
      frame: 0,
    }));
    history.push(
      window.location.pathname +
        '?openmodal=false' +
        '&scene=' +
        (gameState.scene - 1),
    );
    updateLastPage(sceneAnalytics[Math.max(gameState.scene - 1, 0)]);
    walkFor(config.transition * 1000);
    if (gameState.scene === 4) {
      dogsound.pause();
      dogsound.setCurrentTime(0);
    }
    if (gameState.scene === 0) {
      history.push('/signselection');
    }
  }
  function walkFor(duration: number): void {
    setCharacterState((oldState) => ({
      ...oldState,
      pose: CharacterPose.WALKING,
    }));
    setTimeout(() => {
      setCharacterState((oldState) => ({
        ...oldState,
        pose: CharacterPose.IDLE,
      }));
    }, duration);
  }
  return (
    <Container>
      <Controller>
        <Button left onClick={previousScene} style={{ cursor: 'pointer' }}>
          <svg width={'100%'} height={'100%'}>
            <g>
              <CustomLottiePlayer
                autoplay
                loop
                src={'./assets/icons/next_page_button_L.json'}
              />
            </g>
          </svg>
        </Button>
        <Button
          onClick={gameState.scene >= numScene - 1 ? undefined : nextScene}
          style={{
            cursor: gameState.scene >= numScene - 1 ? 'auto' : 'pointer',
          }}
        >
          <svg width={'100%'} height={'100%'}>
            <g>
              <CustomLottiePlayer
                autoplay
                loop
                src={'./assets/icons/next_page_button_R.json'}
              />
            </g>
          </svg>
        </Button>
      </Controller>
    </Container>
  );
}
