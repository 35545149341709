import * as React from 'react';

export default function Volume({
  active,
}: {
  active: boolean;
}): React.ReactElement {
  return (
    <svg
      width="32"
      height="28"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.492676 15.9983V11.4456C0.492676 9.78874 1.83582 8.44559 3.49267 8.44559H7.8641L16.089 0.220703V27.6112L7.47611 18.9983H3.49268C1.83582 18.9983 0.492676 17.6551 0.492676 15.9983Z"
        fill={active ? '#7ecbbd' : '#565656'}
      />
      <rect
        x="22.2805"
        y="12.2239"
        width="9.00086"
        height="3.71943"
        rx="1.85972"
        fill={active ? '#7ecbbd' : '#565656'}
      />
      <rect
        x="19.0085"
        y="7.60596"
        width="9.00086"
        height="3.71943"
        rx="1.85972"
        transform="rotate(-30 19.0085 7.60596)"
        fill={active ? '#7ecbbd' : '#565656'}
      />
      <rect
        width="9.00086"
        height="3.71943"
        rx="1.85972"
        transform="matrix(0.866025 0.5 0.5 -0.866025 19.0085 20.5613)"
        fill={active ? '#7ecbbd' : '#565656'}
      />
    </svg>
  );
}
