import React, { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  IplayerEngagement,
  lastPageAtom,
  osNameAtom,
  playerEngagementAtom,
} from '../../recoils/playStateAtom';
import { osName } from 'react-device-detect';
const tagManagerArgs = {
  gtmId: 'GTM-PQZJ475',
};

TagManager.initialize(tagManagerArgs);

const Analytics: React.FC = () => {
  const [, updateOsname] = useRecoilState<string>(osNameAtom);
  const playerEngagement = useRecoilValue<IplayerEngagement>(
    playerEngagementAtom,
  );
  const lastPage = useRecoilValue(lastPageAtom);

  useEffect(() => {
    console.log(lastPage);
    window.addEventListener('beforeunload', beforunloadHandler, {
      capture: true,
    });

    return () => {
      window.removeEventListener('beforeunload', beforunloadHandler, {
        capture: true,
      });
    };
  }, [lastPage]);
  const beforunloadHandler = (e: any) => {
    e.preventDefault();
    // send lastpage that user engage
    const args = {
      dataLayer: {
        event: 'leave_page',
        last_page: lastPage,
      },
    };
    TagManager.dataLayer(args);

    // check if user play the sign also send engagement
    if (playerEngagement.sign_count > 0) {
      const playerEngagement_eventArgs = {
        dataLayer: {
          event: 'sign_user_engagement',
          sign_count: playerEngagement.sign_count,
          success_count: playerEngagement.success_count,
          fail_count: playerEngagement.fail_count,
          os_name: osName,
        },
      };
      TagManager.dataLayer(playerEngagement_eventArgs);
    }

    return undefined;
  };

  useEffect(() => {
    updateOsname(osName);
    const args = {
      dataLayer: {
        event: 'device_engagement',
        os_name: osName,
      },
    };
    TagManager.dataLayer(args);
  }, []);
  return <></>;
};

export default Analytics;
