import { ParentSize } from '@vx/responsive';
import { AnimationSegment } from 'lottie-web';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import styled from 'styled-components';
import {
  // ActionButton,
  PrimaryButton,
} from '../../components/Button';
import SVGResponsivePlayer from '../../components/SVGResponsivePlayer';
import { MapNames } from '../../recoils/gameStateAtom';
import { playStateAtomByMap } from '../../recoils/playStateAtom';
import { signLanguageAtom } from '../../recoils/signLanguage';
import useSound from '../../utils/useSound';
import { SignPlayState } from './index';

const ControllerWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 2.6rem;
`;

const Title = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 2.6rem;
  z-index: 6;

  font-size: 18px;
  line-height: 22.5px;
  font-weight: 700;
  text-align: center;

  white-space: nowrap;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Wall = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const RowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface IAchievementProps {
  nextScene: () => void;
  signSelectionState: () => void;
  videoList: string[][];
  mapName: MapNames;
  thisScene: number;
  fromSignPlayState: number;
}
const SIGNSELECTIONSTATE = 1;
function Achievement(props: IAchievementProps): React.ReactElement {
  const badgesound = useSound('./assets/sound/badge.wav');
  const { t } = useTranslation();
  const playState = useRecoilValue(playStateAtomByMap(props.mapName));
  const signLanguage = useRecoilValue(signLanguageAtom);
  const passedSignMap = playState[props.thisScene];
  const passedSign = passedSignMap
    ? passedSignMap[signLanguage]
      ? passedSignMap[signLanguage]
      : []
    : [];
  const finished = passedSign.length === props.videoList.length;
  const continueIndex = [
    'signplay',
    'signresult',
    props.mapName,
    props.thisScene,
    'continue',
  ].join('.');
  const doneIndex = [
    'signplay',
    'signresult',
    props.mapName,
    props.thisScene,
    'donebtn',
  ].join('.');
  const titleIndex = [
    'signplay',
    'signresult',
    props.mapName,
    props.thisScene,
    finished
      ? 'finish'
      : props.fromSignPlayState === SIGNSELECTIONSTATE
      ? 'achievementpeek'
      : 'achievement',
  ].join('.');

  return (
    <Container>
      <Title>{t(titleIndex)}</Title>
      <Wall>
        {/* Placeholder Wall */}
        <ParentSize>
          {({ width, height }) => (
            <svg width={'100%'} height={'100%'}>
              <g transform={`translate(${width / 2}, ${height / 2})`}>
                <SVGResponsivePlayer
                  fitBy={'width'}
                  side={width}
                  src={`/assets/achievement/${props.mapName}/${props.thisScene}/wall.json`}
                />
              </g>
            </svg>
          )}
        </ParentSize>
      </Wall>
      {props.videoList.map((video) => {
        let playedSignIdx = -1;
        for (const sign of passedSign) {
          playedSignIdx = video.indexOf(sign);
          if (playedSignIdx !== -1) {
            break;
          }
        }
        const notPlayed = playedSignIdx === -1;
        const animated =
          notPlayed || props.fromSignPlayState !== SignPlayState.Hooray
            ? false
            : passedSign[passedSign.length - 1] === video[playedSignIdx];

        // console.log(`
        //   ----------------------------
        //   notPlayed : ${notPlayed}
        //   fromSignPlayState: ${props.fromSignPlayState}
        //   fromSelection: ${props.fromSignPlayState === SIGNSELECTIONSTATE}
        //   latestSign: ${
        //     passedSign[passedSign.length - 1] === video[playedSignIdx]
        //   }
        //   animated: ${animated}
        //   ----------------------------
        // `);

        /*
          props: {
            src: video.join('-').json
            isPlayed: playedSignIdx === -1 ? 'no' : `yes, ${video[playedSignIdx]}`
            animated: animated
          }
        */

        if (animated) {
          badgesound.play();
        }
        const src = `/assets/achievement/${props.mapName}/${
          props.thisScene
        }/${signLanguage.toString()}/${video.join('_')}.json`;

        let segment: AnimationSegment[];

        switch (playedSignIdx) {
          case -1:
            segment = [[0, 9]];
            break;
          case 0:
            segment = animated
              ? [
                  [0, 9],
                  [10, 49],
                  [49, 59],
                ]
              : [[49, 59]];
            break;
          case 1:
            segment = animated
              ? [
                  [0, 9],
                  [60, 99],
                  [100, 110],
                ]
              : [[100, 110]];
            break;
        }

        return (
          <Badge key={video.toString()}>
            <ParentSize>
              {({ width, height }) => (
                <svg width={'100%'} height={'100%'}>
                  <g transform={`translate(${width / 2}, ${height / 2})`}>
                    <SVGResponsivePlayer
                      fitBy={'width'}
                      side={width}
                      src={src}
                      segments={segment as AnimationSegment[]}
                    />
                  </g>
                </svg>
              )}
            </ParentSize>
          </Badge>
        );
      })}
      <ControllerWrapper>
        {props.fromSignPlayState === SIGNSELECTIONSTATE ? (
          <PrimaryButton onClick={props.signSelectionState}>
            {t(continueIndex)}
          </PrimaryButton>
        ) : (
          <RowButtonWrapper>
            <PrimaryButton
              onClick={finished ? props.nextScene : props.signSelectionState}
            >
              {t(finished ? doneIndex : continueIndex)}
            </PrimaryButton>
          </RowButtonWrapper>
        )}
      </ControllerWrapper>
    </Container>
  );
}

export default Achievement;
