import React from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';

const FontsLanguage: Record<string, string> = {
  en: `
    @import './fonts/Quicksand/typography.css';
    
    html {
      font-family: Quicksand, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    }
  `,
  jp: `
    @import './fonts/NotoSansJP/NotoSansJP.css';
    
    html {
      font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    }
  `,
  hk: `
    @import './fonts/NotoSansHK/NotoSansHK.css';
    
    html {
      font-family: 'Noto Sans HK', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    }
  `,
};

const FontStyle = createGlobalStyle<{ font: string }>`
    ${(props) => props.font}
  `;

export default function Fonts(): React.ReactElement {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <>
      <FontStyle font={FontsLanguage[language]} />
    </>
  );
}
