// Adds the CPU backend.
// Import @tensorflow/tfjs-core
import * as tf from '@tensorflow/tfjs';
import '@tensorflow/tfjs-backend-wasm';

// console.log(tf.ENV.features);
console.log('set backend wasm');
tf.setBackend('wasm');

// Import @tensorflow/tfjs-tflite.
// console.log('import tflite');
// import { loadTFLiteModel, setWasmPath } from '@tensorflow/tfjs-tflite';

// setWasmPath(window.location.origin + '/tflite_module/');

import mpHolistic from './holistic';
import mpFaceDetection from '@mediapipe/face_detection';
// import { knnClassify } from './knn';
// import {
//   SELECTED_POSE_JOINTS,
//   SELECTED_FACE_JOINTS,
//   NUM_FRAME_SAMPLES,
//   BLAZEPOSE_HANDS_THRES,
//   L_HAND_IDX_RAW,
//   R_HAND_IDX_RAW,
//   L_HAND_IDX,
//   R_HAND_IDX,
// } from './constant';

const NUM_FRAME_SAMPLES = 16;
const BLAZEPOSE_HANDS_THRES = 0.7;
const SELECTED_POSE_JOINTS = [
  0,
  2,
  5,
  7,
  8,
  11,
  12,
  15,
  16, //hand.
  17,
  18,
  19,
  20,
  21,
  22,
];

const L_HAND_IDX_RAW = [15, 17, 19, 21];
const R_HAND_IDX_RAW = [16, 18, 20, 22];

const L_HAND_IDX = [
  SELECTED_POSE_JOINTS.indexOf(15),
  SELECTED_POSE_JOINTS.indexOf(17),
  SELECTED_POSE_JOINTS.indexOf(19),
  SELECTED_POSE_JOINTS.indexOf(21),
];
const R_HAND_IDX = [
  SELECTED_POSE_JOINTS.indexOf(16),
  SELECTED_POSE_JOINTS.indexOf(18),
  SELECTED_POSE_JOINTS.indexOf(20),
  SELECTED_POSE_JOINTS.indexOf(22),
];

const POSE_CENTER = 0;

const SELECTED_FACE_JOINTS = [
  1,
  78,
  191,
  80,
  13,
  310,
  415,
  308,
  324,
  318,
  14,
  88,
  95,
  107,
  69,
  105,
  52,
  159,
  145,
  336,
  299,
  334,
  282,
  386,
  374,
];
const FACE_CENTER = 0;
const HAND_CENTER = 9;

const poseFramesBuffer = tf.buffer(
  [1, NUM_FRAME_SAMPLES, SELECTED_POSE_JOINTS.length, 3],
  'float32',
);
const faceFramesBuffer = tf.buffer(
  [1, NUM_FRAME_SAMPLES, SELECTED_FACE_JOINTS.length, 3],
  'float32',
);
const leftHandFramesBuffer = tf.buffer(
  [1, NUM_FRAME_SAMPLES, 21, 3],
  'float32',
);
const rightHandFramesBuffer = tf.buffer(
  [1, NUM_FRAME_SAMPLES, 21, 3],
  'float32',
);

let lHandVisibility = [];
let rHandVisibility = [];

let frameIdx = 0;

function setKPZeros(buffer, kpIdx) {
  buffer.set(0, 0, frameIdx, kpIdx, 0);
  buffer.set(0, 0, frameIdx, kpIdx, 1);
  buffer.set(0, 0, frameIdx, kpIdx, 2);
}

function setAllKPZeros(buffer) {
  for (let kpIdx = 0; kpIdx < buffer.shape[2]; kpIdx++) {
    setKPZeros(buffer, kpIdx);
  }
}

function filterVisibility() {
  if (lHandVisibility.every((e) => e == false)) {
    L_HAND_IDX.forEach((e) => setKPZeros(poseFramesBuffer, e));
    setAllKPZeros(leftHandFramesBuffer);
  }

  if (rHandVisibility.every((e) => e == false)) {
    R_HAND_IDX.forEach((e) => setKPZeros(poseFramesBuffer, e));
    setAllKPZeros(rightHandFramesBuffer);
  }
}

export default class SignLanguageClassifyModel {
  async loadModel(model_path) {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('load model');
        try {
          this.model = await tf.loadGraphModel(model_path);
        } catch (err) {
          console.log('cannot load model: ', err);
          resolve(false);
          return;
        }
        resolve(true);
      } catch (err) {
        console.log('cannot load model: ', err);
        resolve(false);
        return;
      }
    });
  }

  async initHolistic() {
    return new Promise((resolve, reject) => {
      try {
        console.log('init holistic');
        // zero image mockup
        // console.log('zero image mockup');
        const canvas = document.createElement('canvas');
        const imageData = canvas.getContext('2d').createImageData(257, 257);

        // create holistic instance.
        // console.log('download holistic');
        this.holistic = new mpHolistic.Holistic({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@0.4.1628005088/${file}`;
          },
        });

        const holisticOptions = {
          selfieMode: false,
          modelComplexity: 1,
          smoothLandmarks: false,
          minDetectionConfidence: 0.5,
          minTrackingConfidence: 0.5,
          hand1Visibility: 0.005,
          hand2Visibility: 0.005,
        };

        this.holistic.setOptions(holisticOptions);

        // console.log('download face detection');
        this.faceDetection = new mpFaceDetection.FaceDetection({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
          },
        });
        const faceDetectionOptions = {
          selfieMode: true,
          model: 'short',
          minDetectionConfidence: 0.5,
        };

        this.faceDetection.setOptions(faceDetectionOptions);

        function firstInference() {
          console.log('finish first inference');
          resolve(true);
        }
        // console.log('start first inference');
        this.holistic.onResults(firstInference);
        this.holistic.send({ image: imageData });
      } catch (err) {
        console.log('cannot load model: ', err);
        resolve(false);
        return;
      }
    });
  }

  async initFace() {
    this.faceDetection = new mpFaceDetection.FaceDetection({
      locateFile: (file) => {
        return `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection@0.4/${file}`;
      },
    });
    const faceDetectionOptions = {
      selfieMode: true,
      model: 'short',
      minDetectionConfidence: 0.5,
    };

    this.faceDetection.setOptions(faceDetectionOptions);
    return true;
  }

  async predictFace(imageData) {
    return new Promise(async (resolve, reject) => {
      try {
        function faceDetectionCallback(faceResults) {
          // console.log(faceResults.detections[0].landmarks);
          console.log(faceResults.detections);
          if (faceResults) {
            // console.log(faceResults.detections.length);
            if (faceResults.detections.length === 1) {
              if (faceResults.detections[0]) {
                const diffEar =
                  (faceResults.detections[0].landmarks[5].x -
                    faceResults.detections[0].landmarks[4].x) *
                  1000;
                // console.log('diffEye: ', diffEye);
                resolve(Math.round(diffEar));
              }
            } else if (faceResults.detections.length < 1) {
              resolve('cannot find your face');
            } else {
              resolve('please let only one person in the frame');
            }
          }
        }
        // const videoEl = document.getElementById('videoEl');
        // console.log('check videoElement: ', videoEl);
        // if (videoEl) {
        //   this.faceDetection.onResults(faceDetectionCallback);
        //   await this.faceDetection.send({ image: videoEl });
        // } else {
        //   resolve('creating videoEl');
        // }
        if (imageData) {
          this.faceDetection.onResults(faceDetectionCallback);
          await this.faceDetection.send({ image: imageData });
        } else {
          resolve('creating videoEl');
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async predictHolistic_image(image, canvasWrapperId) {
    return new Promise((resolve, reject) => {
      /**
       * check is canvas freamIdx exists?
       * if exists: selected,
       * else create new
       */
      try {
        const canvasBuffer_ = document.getElementById(
          'canvas-result-id' + frameIdx,
        )
          ? document.getElementById('canvas-result-id' + frameIdx)
          : document.createElement('canvas');
        if (canvasWrapperId) {
          canvasBuffer_.id = canvasWrapperId + frameIdx;
        } else {
          canvasBuffer_.id = 'canvas-result-id' + frameIdx;
        }
        canvasBuffer_.classList.contains('canvas-result')
          ? null
          : canvasBuffer_.classList.add('canvas-result');
        canvasBuffer_.width = 257;
        canvasBuffer_.height = 257;

        const ctxBuffer_ = canvasBuffer_.getContext('2d');
        ctxBuffer_.clearRect(0, 0, 257, 257);
        ctxBuffer_.putImageData(image, 0, 0);

        const colorCode = '#FFEE00';

        let face = 0,
          pose = 0,
          hand = 0;

        function holisticCallback(holisticResults) {
          console.log('run holistic' + frameIdx);

          lHandVisibility = [];
          rHandVisibility = [];

          // pose.
          if (holisticResults.poseLandmarks) {
            pose = 1;
            let i = 0;
            for (const jointIdx of SELECTED_POSE_JOINTS) {
              poseFramesBuffer.set(
                holisticResults.poseLandmarks[jointIdx].x,
                0,
                frameIdx,
                i,
                0,
              );
              poseFramesBuffer.set(
                holisticResults.poseLandmarks[jointIdx].y,
                0,
                frameIdx,
                i,
                1,
              );
              poseFramesBuffer.set(
                holisticResults.poseLandmarks[jointIdx].z,
                0,
                frameIdx,
                i,
                2,
              );
              i++;
            }
            // store visibility bool.
            L_HAND_IDX_RAW.forEach((e) =>
              lHandVisibility.push(
                holisticResults.poseLandmarks[e].visibility >
                  BLAZEPOSE_HANDS_THRES,
              ),
            );
            R_HAND_IDX_RAW.forEach((e) =>
              rHandVisibility.push(
                holisticResults.poseLandmarks[e].visibility >
                  BLAZEPOSE_HANDS_THRES,
              ),
            );
            const posePath = [16, 14, 12, 11, 13, 15];
            const drawpoint = [0, 1, 4, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
            for (const point of drawpoint) {
              let size = point > 10 ? 2 : 1;
              ctxBuffer_.beginPath();
              ctxBuffer_.arc(
                holisticResults.poseLandmarks[point].x * 257,
                holisticResults.poseLandmarks[point].y * 257,
                size,
                0,
                2 * Math.PI,
              );
              ctxBuffer_.fillStyle = colorCode;
              ctxBuffer_.fill();
            }

            ctxBuffer_.beginPath();
            let isMove = true;
            for (const poseKey of posePath) {
              // check value
              if (
                holisticResults.poseLandmarks[poseKey].x === 0 &&
                holisticResults.poseLandmarks[poseKey].y === 0
              ) {
                isMove = true;
              } else {
                // check is move
                if (isMove) {
                  ctxBuffer_.moveTo(
                    holisticResults.poseLandmarks[poseKey].x * 257,
                    holisticResults.poseLandmarks[poseKey].y * 257,
                  );
                  isMove = false;
                } else {
                  // draw line
                  ctxBuffer_.lineTo(
                    holisticResults.poseLandmarks[poseKey].x * 257,
                    holisticResults.poseLandmarks[poseKey].y * 257,
                  );
                }
              }
            }
          } else {
            setAllKPZeros(poseFramesBuffer);
          }

          ctxBuffer_.lineWidth = 1;
          ctxBuffer_.strokeStyle = colorCode;
          ctxBuffer_.stroke();
          // face.
          if (holisticResults.faceLandmarks) {
            face = 1;
            let i = 0;
            for (const jointIdx of SELECTED_FACE_JOINTS) {
              faceFramesBuffer.set(
                holisticResults.faceLandmarks[jointIdx].x,
                0,
                frameIdx,
                i,
                0,
              );
              faceFramesBuffer.set(
                holisticResults.faceLandmarks[jointIdx].y,
                0,
                frameIdx,
                i,
                1,
              );
              faceFramesBuffer.set(
                holisticResults.faceLandmarks[jointIdx].z,
                0,
                frameIdx,
                i,
                2,
              );

              // draw face on canvas
              // ctxBuffer_.fillStyle = 'blue';
              // ctxBuffer_.fillRect(
              //   holisticResults.faceLandmarks[jointIdx].x * 257,
              //   holisticResults.faceLandmarks[jointIdx].y * 257,
              //   1,
              //   1,
              // );

              i++;
            }

            const drawFace = [0, 17, 145, 374];

            for (const point of drawFace) {
              ctxBuffer_.beginPath();
              ctxBuffer_.arc(
                holisticResults.faceLandmarks[point].x * 257,
                holisticResults.faceLandmarks[point].y * 257,
                1,
                0,
                2 * Math.PI,
              );
              ctxBuffer_.fillStyle = colorCode;
              ctxBuffer_.fill();
            }
          } else {
            setAllKPZeros(faceFramesBuffer);
          }
          // draw hand on canvas
          ctxBuffer_.fillStyle = 'red';
          // left hand.
          if (holisticResults.leftHandLandmarks) {
            hand = 1;
            for (let i = 0; i < 21; i++) {
              leftHandFramesBuffer.set(
                holisticResults.leftHandLandmarks[i].x,
                0,
                frameIdx,
                i,
                0,
              );
              leftHandFramesBuffer.set(
                holisticResults.leftHandLandmarks[i].y,
                0,
                frameIdx,
                i,
                1,
              );
              leftHandFramesBuffer.set(
                holisticResults.leftHandLandmarks[i].z,
                0,
                frameIdx,
                i,
                2,
              );

              // ctxBuffer_.fillRect(
              //   holisticResults.leftHandLandmarks[i].x * 257,
              //   holisticResults.leftHandLandmarks[i].y * 257,
              //   1,
              //   1,
              // );
            }
            ctxBuffer_.beginPath();
            ctxBuffer_.arc(
              holisticResults.leftHandLandmarks[9].x * 257,
              holisticResults.leftHandLandmarks[9].y * 257,
              2,
              0,
              2 * Math.PI,
            );
            ctxBuffer_.fillStyle = colorCode;
            ctxBuffer_.fill();
          } else {
            setAllKPZeros(leftHandFramesBuffer);
            lHandVisibility = [false];
          }

          // right hand.
          if (holisticResults.rightHandLandmarks) {
            hand = 1;
            for (let i = 0; i < 21; i++) {
              rightHandFramesBuffer.set(
                holisticResults.rightHandLandmarks[i].x,
                0,
                frameIdx,
                i,
                0,
              );
              rightHandFramesBuffer.set(
                holisticResults.rightHandLandmarks[i].y,
                0,
                frameIdx,
                i,
                1,
              );
              rightHandFramesBuffer.set(
                holisticResults.rightHandLandmarks[i].z,
                0,
                frameIdx,
                i,
                2,
              );

              // ctxBuffer_.fillRect(
              //   holisticResults.rightHandLandmarks[i].x * 257,
              //   holisticResults.rightHandLandmarks[i].y * 257,
              //   1,
              //   1,
              // );
            }

            ctxBuffer_.beginPath();
            ctxBuffer_.arc(
              holisticResults.rightHandLandmarks[9].x * 257,
              holisticResults.rightHandLandmarks[9].y * 257,
              2,
              0,
              2 * Math.PI,
            );
            ctxBuffer_.fillStyle = colorCode;
            ctxBuffer_.fill();
          } else {
            setAllKPZeros(rightHandFramesBuffer);
            rHandVisibility = [false];
          }

          filterVisibility();

          const canvasWrapper = document.getElementById(
            canvasWrapperId ? canvasWrapperId : 'canvas-result-wrapper',
          );
          canvasWrapper.appendChild(canvasBuffer_);
          if (frameIdx === 15) {
            frameIdx = 0;
            resolve({
              frame: 15,
              face: face,
              pose: pose,
              hand: hand,
            });
          } else {
            frameIdx++;
            resolve({
              frame: frameIdx - 1,
              face: face,
              pose: pose,
              hand: hand,
            });
          }
        }
        this.holistic.onResults(holisticCallback);
        this.holistic.send({ image: image });
      } catch (err) {
        reject(err);
      }
    });
  }

  predictSign() {
    return new Promise((resolve, reject) => {
      tf.tidy(() => {
        const clsResults = this.model.predict([
          poseFramesBuffer.toTensor(),
          faceFramesBuffer.toTensor(),
          leftHandFramesBuffer.toTensor(),
          rightHandFramesBuffer.toTensor(),
        ]);
        // console.log(clsResults);
        const resultCls_arr = clsResults[1].dataSync();
        const indexCls = resultCls_arr.indexOf(Math.max(...resultCls_arr));
        const resultKnn = clsResults[0].dataSync();

        // const knnSignPrediction = knnClassify(resultKnn, 48, 67);
        // console.log('result from knn: ', knnSignPrediction.predictedClass);

        console.log({
          resultLabel: indexCls,
          resultKnn: resultKnn,
        });
        const clsResults_object = {
          resultLabel: indexCls,
          resultKnn: resultKnn,
        };
        resolve(clsResults_object);
      });
    });
  }
}
