import * as React from 'react';

export default function ArrowIcon({
  left = false,
}: {
  left?: boolean;
}): React.ReactElement {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 27"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: left ? '' : `scaleX(-1)`,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1225 1.35656C13.9341 0.607353 15.1994 0.657965 15.9486 1.46961C16.6978 2.28125 16.6472 3.54657 15.8356 4.29577L5.89727 13.4696L15.8356 22.6434C16.6472 23.3926 16.6978 24.658 15.9486 25.4696C15.1994 26.2812 13.9341 26.3319 13.1225 25.5827L0 13.4696L13.1225 1.35656Z"
      />
    </svg>
  );
}
