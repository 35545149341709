import { atom } from 'recoil';
// import SignLanguageClassifyModel from '../modules/ML/sign_classify';
import SignLanguageClassifyModel from '../modules/ML/holistic_classify';

export const sceneSelect = atom<string>({
  key: 'sceneSelect',
  default: 'packing',
});

// export const classifyModel = atom<SignLanguageClassifyModel>({
//   key: 'classifyModel',
//   default: new SignLanguageClassifyModel(),
// });

export const classifyModel = atom<SignLanguageClassifyModel>({
  key: 'classifyModel',
  default: new SignLanguageClassifyModel(),
});

export const isLoadedModelAtom = atom({
  key: 'loadedModel',
  default: false,
});

export const videoSelection = atom<string>({
  key: 'videoselection',
  default: '',
});
