import * as React from 'react';

import SVGResponsivePlayer from '../SVGResponsivePlayer';
import {
  CharacterNames,
  CharacterPose,
} from '../../recoils/characterStateAtom';
import { AnimationSegment } from 'lottie-web';

const playSegmentByCharacter = {
  [CharacterNames.NOH]: {
    [CharacterPose.IDLE]: [
      [65, 69],
      [0, 50],
    ],
    [CharacterPose.WALKING]: [
      [50, 54],
      [54, 65],
    ],
  },
  [CharacterNames.PINGPONG]: {
    [CharacterPose.IDLE]: [[0, 75]],
    [CharacterPose.WALKING]: [[75, 87]],
  },
};

interface ICharacterProps {
  side: number;
  character: CharacterNames;
  pose: CharacterPose;
}

export default function Character(props: ICharacterProps): React.ReactElement {
  const { character, pose } = props;
  const src = `/assets/character/${character}.json`;
  const playSegment: AnimationSegment[] = playSegmentByCharacter[character][
    pose
  ] as AnimationSegment[];

  return (
    <SVGResponsivePlayer
      src={src}
      fitBy={'width'}
      side={props.side}
      segments={playSegment}
    />
  );
}
