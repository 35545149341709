import * as React from 'react';

import { ParentSize } from '@vx/responsive';
import styled from 'styled-components';

import { Modal as ModalBase } from '../../layout/Modal';
import SVGResponsivePlayer from '../SVGResponsivePlayer';
// import { AnimationSegment } from 'lottie-web';
import { ActionButton } from '../Button';
import { useRecoilValue } from 'recoil';
import { errorListAtom } from '../../recoils/errorAtom';
import { useTranslation } from 'react-i18next';

const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  z-index: 99;
`;

const ModalContainer = styled.div`
  width: 90%;
  max-width: 800px;
  height: 75%;
  max-height: 500px;
`;
const VideoModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bbb;
  opacity: 0;
  z-index: 0;
  transition: all 0.2s;
`;
const WebglVideo = styled.video`
  width: 75%;
  height: 90%;
  border-radius: 25px;
  background-color: black;
  position: absolute;
  transform: translate(0%, 200%);
  transition: all 0.2s;
  opacity: 0;
`;
const CloseModalButton = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;

  // placeholder
  outline: none;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
`;

const Modal = styled(ModalBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 10%;
`;

// const ModalMobile = styled(ModalBase)`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-flow: column;

//   padding: 10%;
// `;

const Title = styled.h1`
  text-align: center;
  font-size: 30px;
`;

const Message = styled.p`
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  white-space: pre-wrap;
`;

const Icon = styled.img`
  width: 100%;
  max-width: 160px;
`;

// const SignLottieWrapper = styled.div`
//   width: 145%;
//   height: 100%;
//   position: absolute;
//   left: 50%;
//   transform: translate(-50%, 27%);
// `;

const HomeLottieWrapper = styled.div`
  width: 110%;
  height: 115%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
`;

const MobileAlertText = styled.div`
  white-space: pre-wrap;
  width: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  height: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 1.6rem;
`;

// const GroundWrapper = styled.div`
//   width: 100%;
//   height: 25%;
//   background-color: #dbd6d6;
//   border-top: 4px solid #000;
//   position: absolute;
//   left: 0;
//   bottom: 0;
// `;

export interface IErrorPageProps {
  icon?: string;
  title: string;
  message: string;
  children?: React.ReactNode;
}

// const SEGMENT_LANGUAGE: Record<string, AnimationSegment> = {
//   en: [0, 274],
//   hk: [275, 549],
//   jp: [550, 822],
// };

export default function ErrorPage(props: IErrorPageProps): React.ReactElement {
  const [reportId, updateReportId] = React.useState(Date.now().toString());
  const { t, i18n } = useTranslation();
  React.useEffect(() => {
    const chromeLink = document.getElementById('chrome-settings-link');
    const wrapper = document.getElementById('open-hardware-video-wrapper');
    const video = document.getElementById('open-hardware-video');
    chromeLink?.addEventListener('click', () => {
      // window.open('chrome://settings/system', '_blank');
      if (wrapper && video) {
        wrapper.style.opacity = '1';
        wrapper.style.zIndex = '10';
        video.style.opacity = '1';
        video.style.transform = 'translate(0%, 0%)';
      }
    });

    updateReportId(i18n.language + reportId);
  }, []);

  const clickCloseModal = () => {
    const wrapper = document.getElementById('open-hardware-video-wrapper');
    const video = document.getElementById('open-hardware-video');
    if (wrapper && video) {
      wrapper.style.opacity = '0';
      wrapper.style.zIndex = '-10';
      video.style.opacity = '0';
      video.style.transform = 'translate(0%, 200%)';
    }
  };

  const [submitting, setSubmitting] = React.useState('idle');

  const errorList = useRecoilValue(errorListAtom);

  const SendReport = async () => {
    // send json report to database
    // device check: user navigator
    // errorList: errorlist
    setSubmitting('submitting');
    try {
      const reportJson = {
        reportId: reportId,
        device: window.navigator.userAgent.toLowerCase(),
        error: errorList.join(','),
      };
      await fetch(
        'https://us-central1-bit-sign-language.cloudfunctions.net/sendErrorReport',
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'content-Type': 'application/json',
          },
          body: JSON.stringify(reportJson),
        },
      );
    } finally {
      setSubmitting('submitted');
    }
  };
  return (
    <FullScreen>
      <VideoModal id="open-hardware-video-wrapper">
        <WebglVideo
          src="assets/open_hardware_acceleration.mkv"
          controls
          muted
          id="open-hardware-video"
        />
        <CloseModalButton onClick={clickCloseModal}>
          <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.5416 8.2795L22.7203 6.45825L15.4999 13.6787L8.2795 6.45825L6.45825 8.2795L13.6787 15.4999L6.45825 22.7203L8.2795 24.5416L15.4999 17.3212L22.7203 24.5416L24.5416 22.7203L17.3212 15.4999L24.5416 8.2795Z"
              fill="black"
              fillOpacity="0.49"
            />
          </svg>
        </CloseModalButton>
      </VideoModal>
      <ModalContainer>
        <ParentSize>
          {() => (
            <Modal>
              {submitting === 'submitting' && (
                <>
                  <Icon src={props.icon} />
                  <Message>{t('error.sendReport.sending')}</Message>
                </>
              )}
              {submitting === 'submitted' && (
                <>
                  <Icon src={props.icon} />
                  <Message>{t('error.sendReport.sent') + reportId}</Message>
                </>
              )}
              {submitting === 'idle' && (
                <>
                  <Icon src={props.icon} />
                  <Title>{props.title}</Title>
                  <Message
                    dangerouslySetInnerHTML={{
                      __html: props.message,
                    }}
                  ></Message>
                  <ActionButton onClick={SendReport}>
                    {t('error.sendReport.sendButton')}
                  </ActionButton>
                </>
              )}
            </Modal>
          )}
        </ParentSize>
      </ModalContainer>
    </FullScreen>
  );
}

export function ErrorDevice(props: IErrorPageProps): React.ReactElement {
  console.log('error mobile');
  return (
    <FullScreen style={{ overflow: 'hidden' }}>
      <HomeLottieWrapper style={{ transform: 'translate(-50%, 0%)' }}>
        <SVGResponsivePlayer
          src={`/assets/error/mobile/export_shuwa_site.json`}
          fitBy={'width'}
          side={1024 * 0.75}
        />
      </HomeLottieWrapper>
      <MobileAlertText>
        {`This experience only\nworks on desktop\n\n手話タウンはパソコンで\nしかあそべないよ`}
      </MobileAlertText>
    </FullScreen>
  );
}
const UndetectedModal = styled(Modal)`
  padding: 10%;
  width: 100%;
  height: 100%;

  justify-content: start;
  align-items: start;

  ${Message} {
    text-align: start;
    margin-bottom: auto;
  }
`;

const DismissButton = styled.button`
  outline: none;
  background: transparent;
  border: none;

  font-size: 30px;
  color: #4285f4;
  align-self: flex-end;

  cursor: pointer;
  border-bottom: 1px dotted transparent;

  &:hover {
    border-bottom: 1px dotted #4285f4;
  }
`;

export function ErrorUndetected(
  props: IErrorPageProps & { onDismiss: () => void },
): React.ReactElement {
  return (
    <FullScreen>
      <ModalContainer>
        <ParentSize>
          {() => (
            <UndetectedModal>
              <Icon src={props.icon} />
              <Title>{props.title}</Title>
              <Message>{props.message}</Message>
              <DismissButton onClick={props.onDismiss}>Dismiss</DismissButton>
            </UndetectedModal>
          )}
        </ParentSize>
      </ModalContainer>
    </FullScreen>
  );
}
