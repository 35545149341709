import styled from 'styled-components';

interface IButton {
  deactivated?: boolean | undefined;
}

export const PrimaryButton = styled.button<IButton>`
  padding: calc(0.75rem * var(--scale, 1)) calc(25px * var(--scale, 1));
  margin: 0 calc(1rem * var(--scale, 1));
  min-width: calc(130px * var(--scale, 1));
  text-transform: capitalize;
  font-size: calc(18px * var(--scale, 1));

  border-radius: 30px;
  border: none;
  font-weight: bold;
  background-color: ${(props) => (props.deactivated ? `#f1f1f1` : `#7ecbbd`)};
  color: #000;

  ${(props) =>
    props.deactivated
      ? null
      : `&:hover {
    background-color: #48857a;
    color: #fff;
  }
  &:active {
    background-color: #48857a;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }`};

  cursor: ${(props) => (props.deactivated ? `not-allowed` : `pointer`)};
  transition: all 0.2s;
`;

export const ActionButton = styled.button<IButton>`
  padding: calc(0.75rem * var(--scale, 1)) calc(25px * var(--scale, 1));
  margin: 0 calc(1rem * var(--scale, 1));
  min-width: calc(130px * var(--scale, 1));
  text-transform: capitalize;
  font-size: calc(18px * var(--scale, 1));

  border-radius: 30px;
  background-color: #f1f1f1;
  border: none;
  color: #000;
  font-weight: bold;
  white-space: pre-wrap;

  ${(props) =>
    props.deactivated
      ? null
      : `&:hover {
    background-color: #000;
    color: #fff;
  }
  &:active {
    background-color: #000;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }`};

  pointer-events: ${(props) => (props.deactivated ? 'none' : 'all')};
  cursor: ${(props) => (props.deactivated ? `not-allowed` : `pointer`)};
  transition: all 0.2s;
`;
