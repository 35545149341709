import * as React from 'react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Button';
import config from '../../config';

import {
  CharacterPose,
  characterStateAtom,
} from '../../recoils/characterStateAtom';

interface IPlayButtonProps {
  deactivated?: boolean;
}

const transitionStyles: { [key in TransitionStatus]: { opacity: number } } = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const Button = styled(PrimaryButton)<
  { state: TransitionStatus } & IPlayButtonProps
>`
  position: fixed;
  top: 70vh;
  left: ${config.characterOffset * 100}vw;
  transform: translate(-50%, 100%);
  padding: 15px 40px;
  margin: 0;

  outline: none;
  border: none;
  border-radius: 30px;

  background: ${(props) => (props.deactivated ? 'grey' : '#7ecbbd')};
  color: ${(props) => (props.deactivated ? 'white' : 'black')};

  font-size: 23px;
  font-weight: bold;

  cursor: ${(props) => (props.deactivated ? 'auto' : 'pointer')};

  opacity: ${(props) => transitionStyles[props.state]['opacity']};
  transition: opacity ${config.fadeDuration}ms ease-in-out;
`;

export default function PlayButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement> & IPlayButtonProps,
): React.ReactElement {
  const { pose } = useRecoilValue(characterStateAtom);
  const { children, ...otherProps } = props;
  const childRef = React.useRef(children);
  const nodeRef = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => {
      childRef.current = children;
    }, config.fadeDuration);
  }, [children]);

  return (
    <Transition
      nodeRef={nodeRef}
      in={pose === CharacterPose.IDLE}
      timeout={config.fadeDuration}
    >
      {(state) => (
        <Button ref={nodeRef} state={state} {...otherProps}>
          {childRef.current}
        </Button>
      )}
    </Transition>
  );
}
