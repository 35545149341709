import styled from 'styled-components';
import config from '../../config';

const TopBackground = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  /* background: linear-gradient(
    to bottom,
    #f0f0f0,
    #f0f0f0 70%,
    black 70%,
    black ${70 + config.baselineWidth * 100}%,
    transparent ${70 + config.baselineWidth * 100}%,
    transparent
  ); ; */
`;

export default TopBackground;
