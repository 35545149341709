import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoaderKeyFrame = keyframes`
0% {
	transform: rotate(0deg);
  }
  100% {
	transform: rotate(360deg);
  }
`;

// const LoaderStyle = styled.div`
//   display: inline-block;
//   position: relative;
//   width: 20vh;
//   height: 20vh;
// `;
const DIV = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 8vh;
  height: 8vh;
  margin: 8px;
  border: 8px solid #7ecbbd;
  border-radius: 50%;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  animation: ${LoaderKeyFrame} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #7ecbbd transparent transparent transparent;
`;
// const LottieContainer = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   transform: translate(-50%, -50%);
//   top: 59%;
//   left: 50%;
// `;
// const LottieContainer = styled.svg``;

const Loader = (): React.ReactElement => {
  return (
    <>
      <DIV style={{ animationDelay: '-0.45s' }}></DIV>
      <DIV style={{ animationDelay: '-0.3s' }}></DIV>
      <DIV style={{ animationDelay: '-0.15s' }}></DIV>
      {/* <LottieContainer>
        <CustomLottiePlayer
          src={'assets/preparing_model_doodle.json'}
          autoplay
          loop
          rendererSettings={{
            viewBoxSize: '0 0 1000 800',
          }}
        />
      </LottieContainer> */}
    </>
  );
};

export default Loader;
