import * as React from 'react';
import { MapNames } from '../../recoils/gameStateAtom';

type TAchievementIcon = Record<
  MapNames,
  Record<number, () => React.ReactElement>
>;

const AchievementIcon: TAchievementIcon = {
  town: {
    '0': function BagIcon(): React.ReactElement {
      return (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="28" cy="28" r="28" fill="#DDDDDD" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.4 16.3002H25.6L25.6 17.9002H30.4V16.3002ZM25.6 14.7002C24.7163 14.7002 24 15.4165 24 16.3002V17.9002C24 18.7839 24.7163 19.5002 25.6 19.5002H30.4C31.2837 19.5002 32 18.7839 32 17.9002V16.3002C32 15.4165 31.2837 14.7002 30.4 14.7002H25.6Z"
            fill="black"
          />
          <path
            d="M11.2 20.324C11.2 18.6317 12.5843 17.2598 14.2919 17.2598H41.388C43.0956 17.2598 44.4799 18.6317 44.4799 20.324V37.2356C44.4799 38.9279 43.0957 40.2998 41.388 40.2998H14.2919C12.5843 40.2998 11.2 38.9279 11.2 37.2356V20.324Z"
            fill="#996D50"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.388 17.9492H14.2919C12.9685 17.9492 11.8956 19.0124 11.8956 20.324V37.2356C11.8956 38.5471 12.9685 39.6103 14.2919 39.6103H41.388C42.7114 39.6103 43.7843 38.5471 43.7843 37.2356V20.324C43.7843 19.0124 42.7114 17.9492 41.388 17.9492ZM14.2919 17.2598C12.5843 17.2598 11.2 18.6317 11.2 20.324V37.2356C11.2 38.9279 12.5843 40.2998 14.2919 40.2998H41.388C43.0957 40.2998 44.4799 38.9279 44.4799 37.2356V20.324C44.4799 18.6317 43.0956 17.2598 41.388 17.2598H14.2919Z"
            fill="black"
          />
          <path
            d="M11.2 20.324C11.2 18.6317 12.601 17.2598 14.3292 17.2598H19.84V40.2998H14.3292C12.601 40.2998 11.2 38.9279 11.2 37.2356V20.324Z"
            fill="#F3AB93"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.1359 17.9492H14.3292C12.9898 17.9492 11.904 19.0124 11.904 20.324V37.2356C11.904 38.5471 12.9898 39.6103 14.3292 39.6103H19.1359V17.9492ZM14.3292 17.2598C12.601 17.2598 11.2 18.6317 11.2 20.324V37.2356C11.2 38.9279 12.601 40.2998 14.3292 40.2998H19.84V17.2598H14.3292Z"
            fill="black"
          />
          <path
            d="M44.48 20.324C44.48 18.6317 43.079 17.2598 41.3508 17.2598H35.84V40.2998H41.3508C43.079 40.2998 44.48 38.9279 44.48 37.2356V20.324Z"
            fill="#F3AB93"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.5441 17.9492H41.3508C42.6901 17.9492 43.7759 19.0124 43.7759 20.324V37.2356C43.7759 38.5471 42.6901 39.6103 41.3508 39.6103H36.5441V17.9492ZM41.3508 17.2598C43.079 17.2598 44.48 18.6317 44.48 20.324V37.2356C44.48 38.9279 43.079 40.2998 41.3508 40.2998H35.84V17.2598H41.3508Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.3408 15.605C25.7398 15.605 25.2526 16.0776 25.2526 16.6606V17.5802H24.3198V16.6606C24.3198 15.5779 25.2247 14.7002 26.3408 14.7002H29.6588C30.775 14.7002 31.6798 15.5779 31.6798 16.6606V17.5802H30.7471V16.6606C30.7471 16.0776 30.2598 15.605 29.6588 15.605H26.3408Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41.5999 18.7006H14.4C13.5163 18.7006 12.8 19.4169 12.8 20.3006V37.1006C12.8 37.9842 13.5163 38.7006 14.4 38.7006H41.5999C42.4836 38.7006 43.1999 37.9842 43.1999 37.1006V20.3006C43.1999 19.4169 42.4836 18.7006 41.5999 18.7006ZM14.4 17.1006C12.6326 17.1006 11.2 18.5333 11.2 20.3006V37.1006C11.2 38.8679 12.6326 40.3006 14.4 40.3006H41.5999C43.3673 40.3006 44.7999 38.8679 44.7999 37.1006V20.3006C44.7999 18.5333 43.3673 17.1006 41.5999 17.1006H14.4Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3999 38.6994V17.8994H19.9999V38.6994H18.3999ZM35.5999 39.0994V17.8994H37.1999V39.0994H35.5999Z"
            fill="black"
          />
        </svg>
      );
    },
    '1': function RestaurantIcon(): React.ReactElement {
      return (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 466 328"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="240.034"
            cy="163.941"
            r="159.684"
            fill="#FFF7E7"
            stroke="black"
            strokeWidth="8"
          />
          <circle
            cx="240.032"
            cy="163.941"
            r="89.7811"
            stroke="black"
            strokeWidth="8"
          />
          <path
            d="M13.0737 231.417V155.099L28.425 154.003V231.417C28.2788 233.318 26.5828 237.119 20.9686 237.119C15.3544 237.119 13.3661 233.318 13.0737 231.417Z"
            fill="#A9A9A9"
          />
          <path
            d="M36.9773 130.538H5.17822C5.17822 137.775 5.17822 154.003 22.9418 154.003C37.1527 154.003 38.22 138.359 36.9773 130.538Z"
            fill="#A9A9A9"
          />
          <path
            d="M37.767 103.019C35.5326 103.019 33.7193 104.832 33.7193 107.067V127.773H25.1525V107.743C25.1525 105.509 23.3391 103.695 21.1048 103.695C18.8704 103.695 17.0606 105.509 17.0606 107.743V127.773H8.49028V107.369C8.49028 105.135 6.6769 103.321 4.44256 103.321C2.21182 103.321 0.398438 105.135 0.398438 107.369V137.952C0.398438 144.932 3.87408 151.113 9.18109 154.866V229.207C9.18109 235.781 14.5313 241.131 21.1048 241.131C27.6783 241.131 33.0285 235.781 33.0285 229.207V154.866C38.3355 151.113 41.8111 144.932 41.8111 137.952V107.067C41.8147 104.829 40.0049 103.019 37.767 103.019ZM24.9366 229.207C24.9366 231.319 23.2168 233.039 21.1048 233.039C18.9928 233.039 17.2729 231.319 17.2729 229.207V158.298C18.5142 158.532 19.7951 158.658 21.1048 158.658C22.4144 158.658 23.6953 158.532 24.9366 158.298V229.207ZM21.1084 150.566C14.1499 150.566 8.49388 144.907 8.49388 137.952V135.865C15.0242 135.865 27.2249 135.865 33.7265 135.865V137.952C33.7229 144.907 28.0632 150.566 21.1084 150.566Z"
            fill="black"
          />
          <path
            d="M440.71 229.839V195.227L455.715 190.025V220.236C455.648 226.238 454.075 238.242 448.313 238.242C442.55 238.242 440.843 232.64 440.71 229.839Z"
            fill="#A9A9A9"
          />
          <path
            d="M440.952 110.812V196.304C466.428 187.485 460.304 175.482 460.304 153.925C460.304 136.68 446.668 115.303 440.952 110.812Z"
            fill="#A9A9A9"
          />
          <path
            d="M465.845 171.855C462.018 120.555 443.358 104.6 442.563 103.945C441.355 102.949 439.682 102.736 438.265 103.405C436.848 104.074 435.945 105.499 435.945 107.063V229.211C435.945 235.782 441.294 241.131 447.865 241.131C454.436 241.131 459.784 235.782 459.784 229.211V190.518C464.147 185.349 466.348 178.616 465.845 171.855ZM444.034 119.152C444.736 120.378 445.462 121.741 446.2 123.256C453.918 139.114 456.756 158.766 457.777 172.455C458.148 177.444 456.479 182.213 453.076 185.892C450.602 188.568 447.48 190.378 444.034 191.176V119.152ZM447.865 233.045C445.754 233.045 444.034 231.326 444.034 229.215V199.405C446.707 198.974 449.293 198.128 451.695 196.909V229.215C451.695 231.326 449.976 233.045 447.865 233.045Z"
            fill="black"
          />
        </svg>
      );
    },
    '2': function HotelIcon(): React.ReactElement {
      return (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 326 324"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M124.219 186.541L120.231 176.734C113.308 160.407 120.456 130.753 141.872 121.146C155.979 114.817 166.93 112.562 180.779 119.217L314.062 196.168C319.369 199.232 318.544 204.653 317.469 206.981L281.257 269.702C277.914 275.492 272.176 274.288 269.725 272.962L148.543 200.585L157.248 185.508C165.345 184.018 180.251 187.174 185.596 168.906C190.83 151.02 177.16 143.128 167.711 137.673C158.263 132.218 147.202 139.77 141.398 149.821C135.595 159.873 139.792 167.924 141.089 174.034C142.386 180.144 143.887 179.402 137.52 186.716C132.427 192.567 126.53 189.037 124.219 186.541Z"
            fill="#DCDCDC"
          />
          <path
            d="M180.563 171.329C175.891 179.305 166.581 183.327 157.584 181.221C156.091 180.896 154.516 181.53 153.731 182.89L144.024 199.703C143.065 201.364 143.64 203.509 145.303 204.469L269.271 276.042C274.26 278.923 280.697 277.202 283.574 272.217L320.691 207.929C323.569 202.945 321.841 196.51 316.852 193.63L187.341 118.856C175.851 112.223 162.17 110.432 149.369 113.983C136.815 117.341 126.131 125.605 119.8 136.919C111.448 151.967 111.504 170.257 120.074 185.207C122.553 189.525 127.16 192.252 132.139 192.24C137.169 192.257 141.783 189.618 144.283 185.289C146.732 180.93 146.71 175.614 144.231 171.297C140.434 164.607 140.582 156.436 144.541 149.928C150.345 140.458 162.921 137.179 172.633 142.585C177.5 145.328 181.111 149.897 182.612 155.328C184.091 160.679 183.353 166.496 180.563 171.329ZM176.02 136.486C162.924 129.26 146.483 133.529 138.61 146.235C138.494 146.437 138.378 146.638 138.261 146.839C133.291 155.448 133.284 166.05 138.243 174.686C139.468 176.87 139.515 179.582 138.236 181.797C136.986 183.962 134.664 185.307 132.189 185.288C129.635 185.29 127.317 183.951 126.092 181.767C118.805 168.901 118.724 153.214 125.876 140.36C131.327 130.686 140.455 123.605 151.182 120.736C162.155 117.674 173.926 119.166 183.853 124.898L313.364 199.671C315.027 200.631 315.603 202.776 314.644 204.437L277.527 268.726C276.568 270.387 274.422 270.961 272.759 270.001L151.815 200.174L158.557 188.496C169.83 190.036 180.892 184.609 186.61 174.821C190.36 168.326 191.314 160.621 189.345 153.442C187.324 146.234 182.529 140.109 176.02 136.486Z"
            fill="black"
          />
          <path
            d="M165.168 209.714L154.681 208.263C137.079 206.096 114.973 185.078 117.36 161.728C118.933 146.346 122.456 135.735 135.144 127.069L268.427 50.1181C273.734 47.054 278.016 50.4785 279.494 52.5738L315.706 115.295C319.049 121.085 315.137 125.452 312.764 126.912L189.493 195.67L180.788 180.593C183.546 172.835 193.732 161.505 180.584 147.741C167.711 134.266 154.041 142.159 144.593 147.614C135.144 153.069 136.154 166.424 141.957 176.476C147.76 186.527 156.832 186.919 162.772 188.85C168.712 190.781 168.819 189.111 171.97 198.282C174.49 205.618 168.485 208.96 165.168 209.714Z"
            fill="#C1C1C1"
          />
          <path
            d="M180.166 153.312C184.738 161.346 183.566 171.42 177.243 178.158C176.216 179.288 175.977 180.97 176.762 182.329L186.469 199.143C187.429 200.804 189.574 201.378 191.237 200.418L315.205 128.845C320.194 125.964 321.921 119.53 319.044 114.546L281.927 50.2572C279.049 45.273 272.613 43.5518 267.624 46.4322L138.113 121.206C126.623 127.839 118.232 138.792 114.907 151.654C111.537 164.205 113.352 177.59 119.985 188.729C128.841 203.486 144.709 212.583 161.94 212.636C166.919 212.648 171.585 210.021 174.064 205.703C176.594 201.356 176.615 196.041 174.116 191.711C171.566 187.411 166.951 184.772 161.972 184.76C154.28 184.704 147.278 180.49 143.621 173.807C138.322 164.046 141.771 151.516 151.309 145.807C156.117 142.964 161.88 142.121 167.333 143.537C172.707 144.932 177.376 148.479 180.166 153.312ZM147.72 139.824C134.914 147.553 130.391 163.926 137.458 177.097C137.574 177.298 137.691 177.5 137.807 177.701C142.777 186.31 151.956 191.617 161.913 191.641C164.418 191.672 166.79 192.987 168.069 195.203C169.318 197.367 169.322 200.05 168.068 202.184C166.792 204.397 164.474 205.736 161.97 205.705C147.184 205.582 133.558 197.808 126.003 185.187C120.351 175.63 118.782 164.184 121.661 153.46C124.495 142.426 131.673 132.979 141.601 127.247L271.112 52.4735C272.775 51.5134 274.92 52.0872 275.88 53.7485L312.997 118.037C313.956 119.698 313.38 121.843 311.717 122.803L190.773 192.631L184.03 180.953C191 171.961 191.831 159.667 186.214 149.821C182.464 143.326 176.268 138.647 169.067 136.763C161.814 134.909 154.112 135.999 147.72 139.824Z"
            fill="black"
          />
          <path
            d="M204.788 181.112L198.288 189.469C187.61 203.629 158.355 212.265 139.327 198.522C126.792 189.469 119.364 181.112 118.203 165.792L118.203 11.8898C118.203 5.76154 123.31 3.76521 125.864 3.5331L198.288 3.5331C204.973 3.5331 206.8 9.1042 206.877 11.8898L204.788 153.025H187.378C182.039 146.757 177.32 132.271 158.826 136.776C140.72 141.186 140.72 156.971 140.72 167.881C140.72 178.791 152.791 184.594 164.397 184.594C176.004 184.594 180.878 176.934 185.521 172.756C190.164 168.577 188.771 167.649 198.288 169.506C205.902 170.991 205.793 177.863 204.788 181.112Z"
            fill="#D6D6D6"
          />
          <path
            d="M163.444 139.923C172.688 139.981 180.826 146.033 183.5 154.878C183.965 156.333 185.302 157.38 186.872 157.38H206.286C208.205 157.38 209.774 155.809 209.774 153.889L209.774 10.7428C209.774 4.9821 205.066 0.268752 199.31 0.268752L125.076 0.268752C119.321 0.268752 114.612 4.9821 114.612 10.7428L114.612 160.29C114.612 173.557 119.903 186.3 129.378 195.611C138.563 204.804 151.062 209.925 164.026 209.751C181.233 209.46 197.045 200.266 205.707 185.369C208.207 181.063 208.265 175.71 205.765 171.404C203.266 167.04 198.673 164.363 193.674 164.363C188.674 164.421 184.082 167.098 181.582 171.404C177.687 178.037 170.537 181.994 162.921 181.82C151.818 181.529 142.691 172.277 142.516 161.162C142.458 155.576 144.609 150.165 148.562 146.15C152.457 142.193 157.864 139.923 163.444 139.923ZM135.54 161.279C135.831 176.233 147.748 188.337 162.689 188.802C162.921 188.802 163.154 188.802 163.386 188.802C173.327 188.802 182.512 183.507 187.512 174.895C188.79 172.742 191.116 171.346 193.674 171.346C196.173 171.346 198.499 172.684 199.72 174.837C200.998 177.048 200.998 179.725 199.72 181.878C192.22 194.621 178.675 202.535 163.968 202.768C152.864 202.884 142.168 198.52 134.32 190.664C126.181 182.692 121.588 171.753 121.588 160.29L121.588 10.7428C121.588 8.8226 123.158 7.25148 125.076 7.25148L199.31 7.25148C201.229 7.25148 202.798 8.8226 202.798 10.7428L202.798 150.397H189.314C185.012 139.865 174.78 132.999 163.444 132.941C155.945 132.941 148.795 135.966 143.563 141.262C138.331 146.615 135.424 153.831 135.54 161.279Z"
            fill="black"
          />
          <path
            d="M201.818 137.031L205.805 146.838C212.729 163.166 205.58 192.819 184.164 202.427C170.057 208.756 159.106 211.01 145.257 204.355L11.9743 127.404C6.66707 124.34 7.49163 118.919 8.56732 116.592L44.7796 53.8705C48.1222 48.0808 53.86 49.2848 56.3111 50.6105L177.493 122.987L168.788 138.065C160.691 139.554 145.785 136.398 140.44 154.667C135.207 172.552 148.877 180.445 158.325 185.9C167.773 191.355 178.835 183.803 184.638 173.751C190.441 163.7 186.244 155.648 184.947 149.538C183.65 143.428 182.149 144.17 188.516 136.857C193.61 131.006 199.506 134.535 201.818 137.031Z"
            fill="#F1F1F1"
          />
          <path
            d="M145.473 152.243C150.145 144.268 159.456 140.245 168.452 142.352C169.945 142.676 171.52 142.042 172.305 140.683L182.012 123.869C182.972 122.208 182.396 120.063 180.733 119.103L56.7648 47.5301C51.7758 44.6498 45.3395 46.371 42.4619 51.3552L5.34496 115.644C2.46737 120.628 4.19485 127.062 9.18381 129.943L138.695 204.716C150.185 211.35 163.866 213.14 176.667 209.589C189.222 206.232 199.905 197.968 206.236 186.654C214.588 171.606 214.532 153.315 205.962 138.366C203.483 134.048 198.876 131.321 193.897 131.333C188.867 131.315 184.253 133.954 181.753 138.284C179.304 142.643 179.326 147.958 181.805 152.276C185.602 158.966 185.454 167.137 181.495 173.645C175.691 183.115 163.115 186.393 153.403 180.987C148.536 178.244 144.925 173.676 143.424 168.245C141.945 162.893 142.683 157.076 145.473 152.243ZM150.016 187.087C163.112 194.312 179.553 190.043 187.426 177.337C187.542 177.136 187.659 176.935 187.775 176.733C192.745 168.124 192.752 157.522 187.793 148.886C186.568 146.702 186.522 143.99 187.8 141.775C189.05 139.61 191.372 138.266 193.847 138.285C196.401 138.283 198.719 139.621 199.945 141.805C207.231 154.672 207.312 170.359 200.16 183.212C194.709 192.887 185.581 199.968 174.854 202.837C163.881 205.899 152.111 204.406 142.183 198.675L12.6718 123.901C11.0088 122.941 10.433 120.796 11.3922 119.135L48.5092 54.8465C49.4684 53.1851 51.6138 52.6114 53.2768 53.5715L174.221 123.399L167.479 135.077C156.207 133.536 145.144 138.964 139.426 148.752C135.676 155.246 134.722 162.952 136.692 170.13C138.712 177.338 143.507 183.463 150.016 187.087Z"
            fill="black"
          />
          <path
            d="M159.221 117.505L169.708 118.956C187.31 121.123 209.416 142.141 207.029 165.491C205.456 180.873 201.933 191.484 189.245 200.15L55.9624 277.101C50.6552 280.165 46.3729 276.74 44.8952 274.645L8.68297 211.924C5.3403 206.134 9.25192 201.767 11.6256 200.307L134.897 131.549L143.601 146.626C140.843 154.384 130.657 165.714 143.805 179.478C156.678 192.953 170.348 185.06 179.796 179.605C189.245 174.15 188.235 160.795 182.432 150.743C176.629 140.692 167.557 140.3 161.617 138.369C155.678 136.438 155.57 138.108 152.42 128.937C149.899 121.601 155.904 118.259 159.221 117.505Z"
            fill="#EDEDED"
          />
          <path
            d="M144.223 173.907C139.652 165.873 140.824 155.799 147.146 149.061C148.174 147.931 148.412 146.249 147.627 144.89L137.92 128.076C136.961 126.415 134.816 125.841 133.153 126.801L9.1847 198.374C4.19575 201.255 2.46827 207.689 5.34586 212.673L42.4629 276.962C45.3404 281.946 51.7767 283.667 56.7657 280.787L186.277 206.013C197.767 199.38 206.158 188.427 209.483 175.565C212.853 163.014 211.038 149.629 204.405 138.49C195.549 123.733 179.681 114.636 162.449 114.583C157.47 114.571 152.805 117.198 150.326 121.516C147.796 125.863 147.774 131.178 150.274 135.508C152.824 139.808 157.438 142.447 162.417 142.459C170.11 142.515 177.112 146.729 180.768 153.412C186.068 163.173 182.619 175.703 173.081 181.412C168.272 184.255 162.51 185.098 157.056 183.682C151.682 182.287 147.014 178.74 144.223 173.907ZM176.67 187.395C189.475 179.666 193.999 163.293 186.932 150.122C186.815 149.921 186.699 149.719 186.583 149.518C181.612 140.909 172.434 135.602 162.476 135.578C159.972 135.547 157.6 134.232 156.321 132.016C155.071 129.852 155.068 127.169 156.322 125.035C157.597 122.822 159.915 121.483 162.419 121.514C177.205 121.637 190.831 129.411 198.387 142.032C204.039 151.589 205.608 163.035 202.729 173.759C199.894 184.793 192.716 194.24 182.789 199.972L53.2777 274.745C51.6147 275.706 49.4693 275.132 48.5101 273.47L11.3931 209.182C10.4339 207.521 11.0097 205.376 12.6727 204.416L133.617 134.588L140.359 146.266C133.389 155.258 132.558 167.552 138.176 177.398C141.926 183.893 148.121 188.572 155.323 190.456C162.575 192.31 170.277 191.22 176.67 187.395Z"
            fill="black"
          />
          <path
            d="M121.243 142.374L127.743 134.018C138.421 119.858 167.675 111.222 186.704 124.965C199.239 134.018 206.667 142.374 207.828 157.695V311.597C207.828 317.725 202.721 319.721 200.167 319.953H127.743C121.057 319.953 119.231 314.382 119.154 311.597L121.243 170.462H138.653C143.992 176.729 148.711 191.216 167.205 186.711C185.311 182.301 185.311 166.516 185.311 155.606C185.311 144.696 173.24 138.892 161.634 138.892C150.027 138.892 145.152 146.553 140.51 150.731C135.867 154.909 137.26 155.838 127.743 153.981C120.129 152.495 120.237 145.624 121.243 142.374Z"
            fill="#FFEEA5"
          />
          <path
            d="M162.59 183.564C153.347 183.506 145.208 177.454 142.534 168.609C142.069 167.155 140.732 166.107 139.162 166.107H119.748C117.829 166.107 116.26 167.678 116.26 169.599V312.744C116.26 318.505 120.969 323.219 126.724 323.219H200.958C206.713 323.219 211.422 318.505 211.422 312.744V163.198C211.422 149.931 206.132 137.187 196.656 127.877C187.471 118.683 174.972 113.562 162.008 113.737C144.801 114.028 128.989 123.222 120.327 138.118C117.827 142.424 117.769 147.778 120.269 152.084C122.769 156.448 127.361 159.124 132.361 159.124C137.36 159.066 141.953 156.39 144.452 152.084C148.347 145.45 155.498 141.493 163.113 141.668C174.216 141.959 183.343 151.211 183.518 162.325C183.576 167.911 181.425 173.323 177.472 177.338C173.577 181.295 168.171 183.564 162.59 183.564ZM190.494 162.209C190.203 147.254 178.286 135.15 163.346 134.685C163.113 134.685 162.88 134.685 162.648 134.685C152.707 134.685 143.522 139.98 138.523 148.592C137.244 150.745 134.918 152.142 132.361 152.142C129.861 152.142 127.535 150.803 126.315 148.65C125.036 146.439 125.036 143.762 126.315 141.609C133.814 128.866 147.359 120.952 162.067 120.719C173.17 120.603 183.867 124.967 191.715 132.823C199.853 140.795 204.446 151.734 204.446 163.198V312.744C204.446 314.665 202.876 316.236 200.958 316.236H126.724C124.805 316.236 123.236 314.665 123.236 312.744V173.09H136.721C141.022 183.622 151.254 190.489 162.59 190.547C170.089 190.547 177.239 187.521 182.471 182.226C187.703 176.872 190.61 169.657 190.494 162.209Z"
            fill="black"
          />
        </svg>
      );
    },
  },
  home: {
    '0': function Icon(): React.ReactElement {
      return <svg></svg>;
    },
  },
};

export default AchievementIcon;
