import React, { useEffect, useRef, useState } from 'react';
import Camera from '../../components/Camera';
import CanvasResult from '../../components/Camera/CanvasResult';

import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  classifyModel as classifyModelAtom,
  isLoadedModelAtom,
} from '../../recoils/mlStateAtom';
import SigningResult from './SigningResult';
import { MapNames } from '../../recoils/gameStateAtom';
// import { playStateAtomByMap } from '../../recoils/playStateAtom';
import { signLanguageAtom, SignLanguageList } from '../../recoils/signLanguage';
import { useTranslation } from 'react-i18next';
import { KNN_Double } from './config';
import { knnClassify, KNN_LABEL } from '../../modules/ML/knn';
import { lastPageAtom, playStateAtomByMap } from '../../recoils/playStateAtom';
// import { errorAtom } from '../../recoils/errorAtom';
import { ParentSize } from '@vx/responsive';
import SVGResponsivePlayer from '../../components/SVGResponsivePlayer';
import { ArrowIcon } from '../../components/Icons';
// import { ActionButton as BaseActionButton } from '../../components/Button';
import SigningError from './SigningError';
import { LABELS_CLASSIFY } from '../../modules/ML/constant';

/*
  signing
  mlprogress
  result

  -- flow --
  count down 3 s
  record video / capture image stack
  send image stack to ml
  receive result from ml
  check result error or result correct
  click tryagain or go next
*/

const CameraWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 65%;
  height: 75%;
  margin: 0;
  margin-bottom: -30px;
`;
const WhiteBackground = styled.div`
  position: absolute;
  width: 78%;
  height: 80%;
  border-radius: 2rem;
  background-color: #000;
  opacity: 0.5;
`;
const RecordText = styled.p`
  position: absolute;
  font-size: calc(24px * var(--scale, 1));
  font-weight: 600;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
`;
// const CountDownText = styled.p`
//   font-weight: 600;
//   position: absolute;
//   font-size: 2.4rem;
//   width: max-content;
//   z-index: 1;
//   top: 30%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   color: #000;
// `;
const SigningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
interface IProcessingModal {
  isShow: boolean;
}
const ProcessingModal = styled.div<IProcessingModal>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: ${(props) => (props.isShow ? `1` : `0`)};
  z-index: ${(props) => (props.isShow ? `3` : `-3`)};
  border-radius: 3rem;
`;
// const ProcessBackButton = styled(BaseActionButton)`
//   position: absolute;
//   bottom: 10%;
// `;

type IWhiteWrapper = {
  isCapturing: boolean;
};
const WhiteWrapper = styled.div<IWhiteWrapper>`
  width: calc(64px * var(--scale, 1));
  height: calc(64px * var(--scale, 1));
  border-radius: 50%;
  /* border: ${(props) =>
    props.isCapturing ? `none` : `0.4rem solid #ccefc6`}; */

  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PressStartText = styled.p`
  font-weight: 600;
  position: absolute;
  bottom: 22%;

  font-size: calc(18px * var(--scale, 1));
`;

const ActionButton = styled.button`
  width: calc(64px * var(--scale, 1));
  height: calc(64px * var(--scale, 1));
  border-radius: 50%;
  border: none;
  color: #000;
  background-color: #fd9226;
  &:hover {
    background-color: #ffa1a1;
    color: #000;
  }
  &:active {
    background-color: #ffa1a1;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  cursor: pointer;
  transition: all 0.2s;
`;

const CountDownCaptureText = styled.p`
  font-weight: 600;
  position: absolute;
  font-size: calc(14px * var(--scale, 1));
  width: max-content;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
`;
const MLProgressText = styled.p`
  font-size: calc(20px * var(--scale, 1));
  position: absolute;
  bottom: 30%;
`;
const MLProgressNumberText = styled.p`
  font-size: calc(20px * var(--scale, 1));
  position: absolute;
  bottom: 25%;
`;
const LottieContainer = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const BackButton = styled.div`
  position: absolute;
  top: 40px;
  left: 40px;
  width: 20px;
  cursor: pointer;
`;
const RecordStatusCircle = styled.div`
  width: calc(32px * var(--scale, 1));
  height: calc(32px * var(--scale, 1));
  border-radius: 50%;
  border: none;
  background-color: #ff2626;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 1;

  transition: all 0.2s;
`;

interface IimageStack {
  imageData: ImageData;
  imageUrl: string;
}
interface SigningProps {
  nextState: () => void;
  goToHooray: () => void;
  backState: () => void;
  signSelectionState: () => void;
  signingNumberArraySelect: number[];
  signingTextArraySelect: string[];
  // signingNumberSelect: number;
  // signingTextSelect: string;
  mapName: MapNames;
  thisScene: number;
  signPageSelect: number;
  setShowCloseButton: (newVal: boolean) => void;
  skipSign: () => void;
}

interface IResultStack {
  poseStack: number[][][];
  faceStack: number[][][];
  leftHandStack: number[][][];
  rightHandStack: number[][][];
}

interface IClassifyResult {
  resultLabel?: string;
  resultArray?: number[];
  resultKnn?: number[];
}

const IMAGE_STACK: IimageStack[] = [];
const PREDICTION_IMAGE_STACK: ImageData[] = [];
const PREDICTION_IMAGE_URL: string[] = [];
const TIMER_STACK = ['signplay.signing.getready'];
const TITLE_TEXT = [
  'onboarding.instruction.clickstart',
  'onboarding.instruction.position',
  'onboarding.instruction.signnow',
];

let captureInterval: NodeJS.Timeout;
let canvasInterval: NodeJS.Timeout;
type CameraType = React.ElementRef<typeof Camera>;
const Signing: React.FC<SigningProps> = (props: SigningProps) => {
  const classifyModel = useRecoilValue(classifyModelAtom);
  const [isLoadedModel, updateIsLoadedModel] = useRecoilState(
    isLoadedModelAtom,
  );
  // const [, setErrorStatus] = useRecoilState(errorAtom);

  const [tipsRandom, updateTipsRandom] = useState<number>(0);

  const cameraRef = useRef<CameraType>(null);
  // const [timeState, updateTimeState] = useState<number>(0);
  const [signingState, updateSigningState] = useState<string>('idle');
  const [countDown, updateCountDown] = useState<number>(0);
  const [, updateCountDownRecording] = useState<number>(0);
  const [signingSelect, updateSigningSelect] = useState<string>('');
  const [signingSelectPair, updateSigningSelectPair] = useState<string>('');
  // const [signScore_left, updateSignScore_left] = useState<number[]>([]);
  // const [signScore_right, updateSignScore_right] = useState<number[]>([]);
  const [signGuessScore] = useState<number[]>([]);
  // signGuessScore: [distance, pose, face, hand]
  const [signGuess] = useState<string>('');

  const [signSelect_analytics, updateSignSelect_analytics] = useState<string>(
    '',
  );
  const [isResultError, setIsResultError] = useState<boolean>(true);

  const signLanguage = useRecoilValue<SignLanguageList>(signLanguageAtom);
  const [signProgress, updateSignProgress] = useRecoilState(
    playStateAtomByMap(props.mapName),
  );
  const [waitingCorrectSign, updateWaitingCorrectSign] = useState<string[]>([]);

  const { t } = useTranslation();

  const [, updateLastPage] = useRecoilState(lastPageAtom);

  useEffect(() => {
    console.log('--- Start Signing Props ---');
    console.log(props.signingNumberArraySelect);
    console.log(props.signingTextArraySelect);
    console.log('---  End Signing Props  ---');

    updateLastPage('signing_camera');
  }, []);

  useEffect(() => {
    switch (signingState) {
      case 'idle':
        updateTipsRandom(Math.floor(Math.random() * 4));
        clearInterval(canvasInterval);
        const canvasWrapper = document.getElementById('canvas-result-wrapper');
        if (canvasWrapper) {
          canvasWrapper.style.opacity = '0';
          canvasWrapper.style.zIndex = '-1';
          canvasWrapper.style.left = '54%';
        }

        for (let i = 0; i < 16; i++) {
          const canvasEl = document.getElementById('canvas-result-id' + i);
          if (canvasEl) canvasEl.remove();
        }
        break;
      case 'processing':
        updateLastPage('signing_processing');
        props.setShowCloseButton(false);
        startClassify();
        break;
      case 'result':
        props.setShowCloseButton(true);
        break;
      default:
        props.setShowCloseButton(true);
        break;
    }
  }, [signingState]);

  const handlePositioning = () => {
    updateSigningState('positioning');
  };

  const handleStart = () => {
    if (signingState === 'positioning') {
      updateSigningState('countdown');
      let count = 0;
      const CountDownInterval = setInterval(() => {
        count += 1;
        // updateCountDown(count);
        if (count === 1) {
          clearInterval(CountDownInterval);
          updateSigningState('capturing');
          handleCapture();
        }
      }, 2000);
    }
  };

  const handleCapture = () => {
    let time_set = 0;
    cameraRef.current?.handleClassify();
    captureInterval = setInterval(() => {
      time_set += 100;
      const imageCaptured = cameraRef.current?.captureImage();
      if (imageCaptured) IMAGE_STACK.push(imageCaptured);
      if (time_set % 1000 === 0) {
        updateCountDownRecording(time_set / 1000);
      }
      if (time_set === 3000) {
        clearInterval(captureInterval);
        updateSigningState('processing');
      }
    }, 100);
  };

  /**
   * Flow:
   * check is this sign pair each other.
   * check is the selected in the progress.
   * check is the pair-selected in the progress.
   * | !inSelected && inPair |
   * | delete inPair >> push inSelected |
   * ---- ---- ----
   * | inSelected && !inPair |
   * | delete inSelected >> push inSelected |
   * ---- ---- ----
   * | !inSelected && !inPair |
   * | push inSelected |
   *
   */

  const handleCorrectSigning = (signText: string, signPairText: string) => {
    let newSign: string[] = [''];

    if (signProgress[props.thisScene]) {
      if (signProgress[props.thisScene][signLanguage]) {
        // check in selected
        const inSelected = signProgress[props.thisScene][signLanguage].includes(
          signText,
        );
        const inPair = signProgress[props.thisScene][signLanguage].includes(
          signPairText,
        );

        if (!inSelected && inPair) {
          // delete inPair >> push inSelected
          const filteredSign = signProgress[props.thisScene][
            signLanguage
          ].filter((values) => {
            return values !== signPairText;
          });
          console.log('filteredsign: ', filteredSign);
          newSign = [...filteredSign, signText];
          console.log('newsign: ', newSign);
          updateSignProgress({
            ...signProgress,
            [props.thisScene]: {
              ...signProgress[props.thisScene],
              [signLanguage]: newSign,
            },
          });
        }
        if (inSelected && !inPair) {
          // filter array
          const filteredSign = signProgress[props.thisScene][
            signLanguage
          ].filter((values) => {
            return values !== signText;
          });
          console.log('filteredsign: ', filteredSign);
          newSign = [...filteredSign, signText];
          console.log('newsign: ', newSign);
          updateSignProgress({
            ...signProgress,
            [props.thisScene]: {
              ...signProgress[props.thisScene],
              [signLanguage]: newSign,
            },
          });
        }
        if (!inSelected && !inPair) {
          newSign = [...signProgress[props.thisScene][signLanguage], signText];
          console.log('newsign: ', newSign);
          updateSignProgress({
            ...signProgress,
            [props.thisScene]: {
              ...signProgress[props.thisScene],
              [signLanguage]: newSign,
            },
          });
        }
      } else {
        newSign = [signText];
        updateSignProgress({
          ...signProgress,
          [props.thisScene]: {
            ...signProgress[props.thisScene],
            [signLanguage]: newSign,
          },
        });
      }
    } else {
      newSign = [signText];
      updateSignProgress({
        ...signProgress,
        [props.thisScene]: {
          ...signProgress[props.thisScene],
          [signLanguage]: newSign,
        },
      });
    }
  };

  const [classifyState, updateClassifyState] = useState<string>('idle');
  const [frame, updateFrame] = useState<number>(0);
  const [classifyResult, updateClassifyResult] = useState<IClassifyResult>({});

  const [signingPosition] = useState<number>(0);
  const [, updateResultStack] = useState<IResultStack>({
    poseStack: [],
    faceStack: [],
    leftHandStack: [],
    rightHandStack: [],
  });
  /**
   * kp detectState
   * all
   * noface
   * nopose
   * nohand
   */
  const [kpDetectState, set_kpDetectState] = useState('all');

  const clearArray = () => {
    PREDICTION_IMAGE_STACK.length = 0;
    IMAGE_STACK.length = 0;
    PREDICTION_IMAGE_URL.length = 0;
  };

  useEffect(() => {
    const clearBuffer = () => {
      updateClassifyState('idle');
      setGuessingError(false);
      updateResultStack({
        poseStack: [],
        faceStack: [],
        leftHandStack: [],
        rightHandStack: [],
      });
    };
    switch (classifyState) {
      case 'idle':
        updateFrame(0);
        break;
      case 'classify_keypoints':
        // TODO: predict holistic each frame
        // predict image
        // classifyModel
        //   .predictImage(PREDICTION_IMAGE_STACK[frame])
        //   .then((result) => {
        //     updatePositionStatusStack([
        //       ...positionStatusStack,
        //       result.positionStatus,
        //     ]);
        //     updateResultStack({
        //       poseStack: [...resultStack.poseStack, result.pose],
        //       faceStack: [...resultStack.faceStack, result.face],
        //       leftHandStack: [...resultStack.leftHandStack, result.leftHand],
        //       rightHandStack: [...resultStack.rightHandStack, result.rightHand],
        //     });

        //     if (frame === 15) {
        //       updateClassifyState('classify_sign');
        //     } else {
        //       updateFrame(frame + 1);
        //     }
        //   })
        //   .catch(() => {
        //     window.location.reload();
        //   });

        // holistic classify image from image stack
        console.log('classify predict holistic keypoints');
        classifyModel
          .predictHolistic_image(PREDICTION_IMAGE_STACK[frame])
          .then((result) => {
            console.log(result);
            if (frame === 15) {
              console.log('classify_sign');
              updateClassifyState('classify_sign');
            } else {
              updateFrame(result.frame + 1);

              /**
               * check face, pose, hand
               * whenever face = 0 >> no face
               * face = 1; pose = 0 >> no pose
               * face = 1; pose = 1; hand ไม่เคย 1 เลย >> no hand
               */
              switch (kpDetectState) {
                case 'all':
                  if (result.face === 0) {
                    console.log('no face');
                    set_kpDetectState('noface');
                  } else if (result.pose === 0) {
                    console.log('no pose');
                    set_kpDetectState('nopose');
                  } else if (result.hand === 0) {
                    console.log('no hand');
                    set_kpDetectState('nohand');
                  }
                  break;
                case 'noface':
                  if (result.face === 1) {
                    set_kpDetectState('nopose');
                  }
                  break;
                case 'nopose':
                  if (result.pose === 1) {
                    console.log('no pose');
                    set_kpDetectState('nohand');
                  }
                  break;
                case 'nohand':
                  if (result.hand === 1) {
                    set_kpDetectState('all-hand');
                  }
                  break;
                case 'all-hand':
                  break;
                default:
                  break;
              }
            }
          });
        // classifyModel.predict(PREDICTION_IMAGE_STACK).then(() => {
        //   console.log('finish');
        // });
        break;
      case 'classify_sign':
        classifyModel
          .predictSign()
          .then((result) => {
            console.log('result from tfjs wasm predict: ', result);
            updateClassifyResult(result);
            updateClassifyState('post_process');
          })
          .catch((err) => {
            console.log(err);
            // window.location.reload();
            clearBuffer();
            // updateSigningState('result_error');
            updateSigningState('result');
            setIsResultError(true);
            updateSigningSelect(props.signingTextArraySelect[0]);
            updateSigningSelectPair(props.signingTextArraySelect[1]);

            updateSignSelect_analytics(
              `${toKNNLabel(props.signingTextArraySelect[0])} & ${toKNNLabel(
                props.signingTextArraySelect[1],
              )}`,
            );
          });
        break;
      /**
       * TODO: post process
       *
       * flow
       * receive knn
       * fire {clsKNN, select_1, select_2} to KNN model
       * recieve result from KNN model (select_1, select_2, not_match)
       * case - correct:
       *  isResultError(false)
       *  break;
       * case - no_correct:
       *  isResultError(true)
       *  show video tutorial and keypoints canvas frames,
       *  break;
       *
       * @return
       *
       * update signingSelect // knn result
       * update signingSelectPair // another
       *
       */
      case 'post_process':
        /**
         * flow
         * receive knn
         * run knn
         * result knn
         */
        // const score_benchmark = (input: number) => {
        //   if (input > 1) return 0;
        //   else if (input > 3 / 4) return 1;
        //   else if (input > 1 / 2) return 2;
        //   else if (input > 0) return 3;
        //   else return 3;
        // };

        const checkIsDouble = (input: string) => {
          if (KNN_Double.hasOwnProperty(input)) {
            return KNN_Double[input];
          } else {
            return false;
          }
        };

        const getKNN_number = (input: string) => {
          const doubleNumber = checkIsDouble(input);
          if (doubleNumber) return doubleNumber;
          else {
            const knn_text = toKNNLabel(input);
            console.log({
              knnText: knn_text,
              knnNumber: KNN_LABEL.indexOf(knn_text),
            });
            return KNN_LABEL.indexOf(knn_text);
          }
        };

        const toKNNLabel = (signingText: string) => {
          // check double
          const doubleNumber = checkIsDouble(signingText);
          if (doubleNumber) {
            return KNN_LABEL[doubleNumber] as string;
          } else {
            return (signLanguage.charAt(0) +
              signLanguage.toLowerCase().slice(1) +
              '_' +
              signingText) as string;
          }
        };

        clearArray();
        console.log('result from classifymodel: ', classifyResult.resultLabel);
        // classify result array >> KNN

        const left_knn_number = getKNN_number(props.signingTextArraySelect[0]);
        const right_knn_number = getKNN_number(props.signingTextArraySelect[1]);

        console.log('left knn number: ', left_knn_number);
        console.log('right knn number: ', right_knn_number);

        const knnSignPrediction = knnClassify(
          classifyResult.resultKnn,
          left_knn_number,
          right_knn_number,
        );
        const leftSign_knnLabel = toKNNLabel(
          props.signingTextArraySelect[0],
        ) as string;
        const rightSign_knnLabel = toKNNLabel(
          props.signingTextArraySelect[1],
        ) as string;

        console.log(
          'knn result prediction: ',
          knnSignPrediction.predictedClass,
        );
        console.log('left knn label: ', leftSign_knnLabel);
        console.log('right knn label: ', rightSign_knnLabel);

        updateSigningState('result');

        console.log(
          'labelClassify: ',
          LABELS_CLASSIFY[knnSignPrediction.predictedClassNumber],
        );

        if (
          knnSignPrediction.class_a.distance > 1.75 &&
          knnSignPrediction.class_b.distance > 1.75
        ) {
          console.log('result error');
          clearBuffer();
          // updateSigningState('result_error');
          setIsResultError(true);
          updateSigningSelect(props.signingTextArraySelect[0]);
          updateSigningSelectPair(props.signingTextArraySelect[1]);

          updateSignSelect_analytics(
            `${toKNNLabel(props.signingTextArraySelect[0])} & ${toKNNLabel(
              props.signingTextArraySelect[1],
            )}`,
          );
        } else {
          const moreClose =
            knnSignPrediction.class_a.distance <
            knnSignPrediction.class_b.distance
              ? 'a'
              : 'b';
          console.log('result correct');
          clearBuffer();
          setIsResultError(false);
          if (moreClose === 'a') {
            updateSigningSelect(props.signingTextArraySelect[0]);
            updateSignSelect_analytics(
              toKNNLabel(props.signingTextArraySelect[0]),
            );
            updateSigningSelectPair(props.signingTextArraySelect[1]);
            updateWaitingCorrectSign([
              props.signingTextArraySelect[0],
              props.signingTextArraySelect[1],
            ]);
          } else {
            updateSigningSelect(props.signingTextArraySelect[1]);
            updateSignSelect_analytics(
              toKNNLabel(props.signingTextArraySelect[1]),
            );
            updateSigningSelectPair(props.signingTextArraySelect[0]);
            updateWaitingCorrectSign([
              props.signingTextArraySelect[1],
              props.signingTextArraySelect[0],
            ]);
          }
        }
        break;
      // case 'post_process':
      //   break;
      default:
        break;
    }
  }, [classifyState, frame]);

  const startClassify = () => {
    // slice image stack to 16 frame uniform spread
    const thres = (IMAGE_STACK.length - 5) / 16;
    const imageTime = [];
    for (let i = 0; i < 16; i++) {
      imageTime.push(Math.round(thres * i));
    }
    console.log('image time stack: ', imageTime);
    for (const time of imageTime) {
      PREDICTION_IMAGE_STACK.push(IMAGE_STACK[time + 3].imageData);
      PREDICTION_IMAGE_URL.push(IMAGE_STACK[time + 3].imageUrl);
    }
    if (!isLoadedModel) {
      // const tic = +new Date();
      // classifyModel.initClassify().then((result) => {
      //   if (!result) {
      //     setErrorStatus(ErrorStatus.ML_LOADING_FAILURE);
      //     return;
      //   } else {
      //     updateIsLoadedModel(true);
      //     updateClassifyState('classify_keypoints');
      //     const toc = +new Date();
      //     console.log('first prediction: ', toc - tic);
      //   }
      // });
      updateIsLoadedModel(true);
      updateClassifyState('classify_keypoints');
    } else {
      updateClassifyState('classify_keypoints');
    }
    // updateClassifyState('classify_keypoints');
  };

  const userClickCorrect = () => {
    handleCorrectSigning(waitingCorrectSign[0], waitingCorrectSign[1]);
    PREDICTION_IMAGE_URL.length = 0;
    props.goToHooray();
  };
  const userClickTryAgain = () => {
    // PREDICTION_IMAGE_URL.length = 0;
    clearArray();
    updateSigningState('idle');
    updateCountDown(0);
    // props.backState();
  };

  function dataURLtoFile(dataurl: any, filename: string) {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  async function uploadImage(url: any, dataurl: any, imageName: string) {
    return new Promise((resolve, reject) => {
      // var fd = new FormData()
      // fd.append()
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
            console.log('upload complete');
            // $('.bottom-page3-container').show();
          } else {
            reject(xhr.response);
          }
        }
      };

      const imageFile = dataURLtoFile(dataurl, imageName);

      xhr.setRequestHeader('Content-Type', 'image/png');
      xhr.send(imageFile);
    });
  }

  async function getSignedURL(metadata: any) {
    return new Promise(async (resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open(
        'POST',
        'https://us-central1-bit-ml-research.cloudfunctions.net/sign_language_pipeline/get_signed_url',
        true,
      );
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(JSON.parse(xhr.response).url);
            console.log('get URL complete');
          } else {
            reject(xhr.response);
          }
        }
      };

      console.log(metadata);

      xhr.setRequestHeader('Content-Type', 'application/json');
      const obj = JSON.stringify(metadata);
      xhr.send(obj);
    });
  }

  const sendDataToCloud = () => {
    console.log('send data to cloud');

    updateSigningState('senddata');
    updateCountDownRecording(0);
    updateCountDown(0);
    /**
     * Flow: collect image stack array
     * key the right selection sign from user
     * send image stack array to cloud
     * return notation to the user
     */
    let frameCount = 1;
    const folderTime = +Date.now();
    const startSendData = async () => {
      for (const imageUrl of PREDICTION_IMAGE_URL) {
        const metadata = {
          directory: 'ML_demo_test/' + signingSelectPair + '/' + folderTime,
          filename: String(frameCount) + '.png',
          contentType: 'image/png',
          action: 'write',
          version: 'v4',
        };
        frameCount += 1;
        const url = await getSignedURL(metadata);
        uploadImage(url, imageUrl, signingSelectPair);
      }
      PREDICTION_IMAGE_URL.length = 0;
      updateSigningState('idle');
      console.log('finish upload data');
    };
    startSendData();
  };

  const [isGuessingError, setGuessingError] = useState(false);
  const [signDisplay, setSignDisplay] = useState('');

  const handleGuessingError = (input: string) => {
    setGuessingError(true);
    setSignDisplay(input);
  };

  useEffect(() => {
    if (isGuessingError) {
      // show video and canvas
      let currentFrame = 0;
      const canvasWrapper = document.getElementById('canvas-result-wrapper');
      if (canvasWrapper) {
        canvasWrapper.style.opacity = '1';
        canvasWrapper.style.zIndex = '100';
        canvasWrapper.style.left = '54%';
      }

      canvasInterval = setInterval(() => {
        // hidden previous canvas
        const prevCanvas = document.getElementById(
          'canvas-result-id' + currentFrame.toString(),
        );
        if (prevCanvas) {
          prevCanvas.style.opacity = '0';
          prevCanvas.style.zIndex = '-1';
        }

        currentFrame = (currentFrame + 1) % 16;
        const showCanvas = document.getElementById(
          'canvas-result-id' + currentFrame.toString(),
        );
        if (showCanvas) {
          showCanvas.style.opacity = '1';
          showCanvas.style.zIndex = '100';
        }
      }, 187.5);
      console.log('video frame');
      const videoTutorialEl = document.getElementById(
        'video-tutorial-loop',
      ) as HTMLVideoElement;
      if (videoTutorialEl) {
        videoTutorialEl.currentTime = 0;
        videoTutorialEl.play();
      }
    }
  }, [isGuessingError]);

  return (
    <SigningWrapper>
      {/* {(signingState === 'processing' || signingState === 'senddata') && ( */}
      {signingState === 'result' && !isGuessingError && (
        <SigningResult
          handleTryAgain={userClickTryAgain}
          signingResultSelect={signingSelect}
          signingResultSelectPair={signingSelectPair}
          isError={isResultError}
          isGuessingError={isGuessingError}
          handleGuessingError={handleGuessingError}
          nextState={userClickCorrect}
          signSelectionState={props.signSelectionState}
          mapName={props.mapName}
          thisScene={props.thisScene}
          skipSign={props.skipSign}
          // signScore_left={signScore_left}
          // signScore_right={signScore_right}
          signGuessScore={signGuessScore}
          signGuess={signGuess}
          sendDataToCloud={sendDataToCloud}
          signingPosition={signingPosition}
          signSelect_analytics={signSelect_analytics}
        />
      )}
      {signingState === 'result' && isGuessingError && (
        <SigningError
          signDisplay={signDisplay}
          handleTryAgain={userClickTryAgain}
          skipSign={props.skipSign}
        />
      )}

      <CanvasResult />
      {signingState !== 'result' && (
        <>
          <ProcessingModal
            isShow={
              signingState === 'processing' || signingState === 'senddata'
            }
          >
            {/* <ProcessingDoodle src="./assets/sign_play/processing_doodle.png" /> */}
            <ParentSize>
              {({ width, height }) => (
                <LottieContainer>
                  <g transform={`translate(${width / 2}, ${height * 0.6})`}>
                    <SVGResponsivePlayer
                      fitBy={'width'}
                      side={width}
                      src={'assets/loading.json'}
                    />
                  </g>
                </LottieContainer>
              )}
            </ParentSize>
            <MLProgressText>
              {/* {signingState === 'processing'
                ? t('signplay.signing.progress')
                : t('signplay.signing.improve')} */}
              {t('tips.tips')}
            </MLProgressText>
            {signingState === 'processing' && (
              <>
                {/* <MLProgressNumberText>{`${Math.floor(
                  (frame / 16) * 100,
                )} %`}</MLProgressNumberText>
                <ProcessBackButton onClick={handleBackToSign}>
                  {t('signplay.signing.backtosign')}
                </ProcessBackButton> */}
                <MLProgressNumberText>
                  {t('tips.' + tipsRandom)}
                </MLProgressNumberText>
              </>
            )}
          </ProcessingModal>
          {/* )} */}
          <BackButton onClick={props.backState}>
            <ArrowIcon left />
          </BackButton>
          {/* <InstructionText>
            {signingState === 'idle' && t('signplay.signing.instruction')}
          </InstructionText> */}
          <CameraWrapper>
            <Camera
              ref={cameraRef}
              action={props.signSelectionState}
              signingState={signingState}
              handleStart={handleStart}
            />
            {signingState === 'countdown' && (
              <>
                <WhiteBackground />
                <RecordText>{t(TIMER_STACK[countDown])}</RecordText>
              </>
            )}
          </CameraWrapper>
          <PressStartText>
            {t(
              TITLE_TEXT[
                signingState === 'idle'
                  ? 0
                  : signingState === 'capturing'
                  ? 2
                  : 1
              ],
            )}
          </PressStartText>
          <WhiteWrapper isCapturing={signingState === 'capturing'}>
            {signingState === 'capturing' && <RecordStatusCircle />}
            {signingState === 'idle' && (
              <ActionButton onClick={() => handlePositioning()}>
                <CountDownCaptureText>
                  {t('signplay.signing.start')}
                </CountDownCaptureText>
              </ActionButton>
            )}
          </WhiteWrapper>
        </>
      )}
    </SigningWrapper>
  );
};

export default Signing;
