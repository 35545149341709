import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  CustomLottiePlayer,
  PlayerEvent,
} from '../../components/SVGResponsivePlayer/CustomLottiePlayer';
import { ModalContext } from '../../layout/Modal';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HoorayTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;

  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
`;
const LottieSVG = styled.svg<{ scale: number }>`
  width: 874px;
  height: 550px;

  transform: scale(${(props) => props.scale});
  transform-origin: left top;
`;
interface IHoorayProps {
  onEnded: (event: PlayerEvent) => void;
}
export default function Hooray(props: IHoorayProps): React.ReactElement {
  const { t } = useTranslation();
  const { width } = React.useContext(ModalContext);
  const Hurrah = t('signplay.signresult.hurrah', { defaultValue: 'Hurrah!' });
  return (
    <Container>
      <LottieSVG scale={width / 874}>
        <CustomLottiePlayer
          src={'/assets/Ray_Spin_Characters_Jump_v2.json'}
          onEvent={({ event }) => props.onEnded(event)}
          autoplay
        />
      </LottieSVG>
      <HoorayTitle>{Hurrah}</HoorayTitle>
    </Container>
  );
}
