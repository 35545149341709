import { atom } from 'recoil';

export enum deafCultureName {
  LIGHT_ON = '001_Light_On',
  HAND_UP_DOWN = '002_Hand_Up_Down',
  HAND_WAVE_TAP_SHOULDER = '003_Hand_Wave_Tap_Shoulder',
  CHARACTER_SWITCH_POSITION = '004_Character_Switch_Position',
  BAG_CHARACTER = '005_Bag_Character',
  LIGHT_SWITCH = '006_Light_Switch',
  CLOCK_VIBRATE = '007_Clock_Vibrate',
}

export interface IDeafCultureState {
  open: boolean;
  name?: deafCultureName | deafCultureName[];
}

export const deafCultureStateAtom = atom<IDeafCultureState>({
  key: 'deafCultureState',
  default: {
    open: false,
    name: undefined,
  },
});
