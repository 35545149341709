import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

import { signLanguageAtom, SignLanguageList } from '../../recoils/signLanguage';
import { ActionButton } from '../../components/Button';
import TopBackground from '../../layout/TopBackground';
import { SOUNDSTATE, soundStateAtom } from '../../recoils/soundStateAtom';
import { lastPageAtom } from '../../recoils/playStateAtom';

interface AnimI {
  show: boolean;
}

const HeroWrapper = styled.section<AnimI>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 1000px;

  text-align: center;

  transform: ${(props) =>
    props.show ? `translate(0%, 0%)` : `translate(0%, 10%)`};
  opacity: ${(props) => (props.show ? `1` : `0`)};
  z-index: ${(props) => (props.show ? `1` : `-10`)};
  transition: all 0.5s;
`;
const HeroText = styled.h1`
  text-align: center;
  position: absolute;
  top: 21vh; // 30% of 70vh
  left: 50%;
  transform: translate(-50%, 0);
  color: #000;
`;
const ControllerWrapper = styled.section<AnimI>`
  position: absolute;
  top: 70%;
  width: 100%;
  left: 50%;
  height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;

  transform: ${(props) =>
    props.show ? `translate(-50%, 0%)` : `translate(-50%, 10%)`};
  opacity: ${(props) => (props.show ? `1` : `0`)};
  z-index: ${(props) => (props.show ? `1` : `-10`)};
  transition: all 0.5s;
`;

const JSLWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 25%;
  white-space: nowrap;
  transform: translate(-50%, 0);
`;
const HKSLWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 75%;
  white-space: nowrap;
  transform: translate(-50%, 0);
`;
const DescriptionText = styled.p`
  font-weight: bold;
  font-size: 18px;
  margin: 2rem 0 1rem 0;
`;
const LanguageSelectionButton = styled(ActionButton)`
  width: 6rem;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
`;

interface SignSelectionContainerI {
  show: boolean;
  handlePlaySound?: () => void;
}
// const FullScreenSVG = styled.svg``;

const SignSelectionContainer: React.FC<SignSelectionContainerI> = (
  props: SignSelectionContainerI,
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [, updateSignLanguage] = useRecoilState<SignLanguageList>(
    signLanguageAtom,
  );
  const [isButtonClick, updateIsButtonClick] = useState<boolean>(false);
  const [, updateSoundState] = useRecoilState(soundStateAtom);
  const [, updateLastPage] = useRecoilState(lastPageAtom);

  const selectSignLanguage = (input: SignLanguageList) => {
    updateSignLanguage(input);
    updateIsButtonClick(true);
    updateLastPage('play_packing');
    if (props.handlePlaySound) {
      updateSoundState(SOUNDSTATE.PLAY);
      // props.handlePlaySound();
    }
  };

  useEffect(() => {
    if (isButtonClick) history.push('/play');
  }, [isButtonClick]);
  return (
    <>
      <TopBackground style={{ zIndex: props.show ? 1 : -10 }}>
        <HeroWrapper show={props.show}>
          <HeroText>{t('onboarding.signselection.title')}</HeroText>
        </HeroWrapper>
      </TopBackground>
      <ControllerWrapper show={props.show}>
        <JSLWrapper>
          <DescriptionText>
            {t('onboarding.signselection.jslText')}
          </DescriptionText>
          <LanguageSelectionButton
            onClick={() => selectSignLanguage(SignLanguageList.JSL)}
          >
            {t('onboarding.signselection.jsl')}
          </LanguageSelectionButton>
        </JSLWrapper>
        <HKSLWrapper>
          <DescriptionText>
            {t('onboarding.signselection.hkslText')}
          </DescriptionText>
          <LanguageSelectionButton
            onClick={() => selectSignLanguage(SignLanguageList.HKSL)}
          >
            {t('onboarding.signselection.hksl')}
          </LanguageSelectionButton>
        </HKSLWrapper>
      </ControllerWrapper>
    </>
  );
};

export default SignSelectionContainer;
