interface TownType {
  [key: string]: {
    [key: string]: string[][];
  };
}

export const town: TownType = {
  0: {
    jsl: [
      ['grey', 'yellow'],
      ['shirt', 'jacket'],
      ['cap', 'watch'],
      ['dog', 'goldfish'],
      ['high_heels', 'geta'],
      ['bicycle', 'motorcycle'],
    ],
    hksl: [
      ['grey', 'purple'],
      ['shirt', 'jacket'],
      ['hat', 'sunglasses'],
      ['tiger', 'panda'],
      ['high_heels', 'flip_flops'],
      ['bicycle', 'motorcycle'],
    ],
  },
  1: {
    jsl: [
      ['summer', 'winter'],
      ['carrot', 'tomato'],
      ['pasta', 'steak'],
      ['lemon', 'strawberry'],
      ['draught_beer', 'wine'],
      ['waiter', 'department_store'],
    ],
    hksl: [
      ['summer', 'winter'],
      ['carrot', 'tomato'],
      ['pasta', 'steak'],
      ['lemon', 'mango'],
      ['milk_tea', 'coffee'],
      ['waiter', 'post_office'],
    ],
  },
  2: {
    jsl: [
      ['smoking_room', 'non_smoking_room'],
      ['single_bed', 'double_bed'],
      ['bathroom', 'swimming_pool'],
      ['reservation', 'onsite_payment'],
      ['body_soap', 'shampoo'],
      ['cash', 'credit_cards'],
    ],
    hksl: [
      ['smoking_room', 'non_smoking_room'],
      ['single_bed', 'double_bed'],
      ['bathroom', 'swimming_pool'],
      ['reservation', 'onsite_payment'],
      ['body_soap', 'shampoo'],
      ['cash', 'credit_cards'],
    ],
  },
};

interface videoListType {
  [key: string]: {
    [key: string]: {
      [key: string]: string[][];
    };
  };
}

export const videoListConfig: videoListType = {
  town: town,
  hotel: {
    0: {
      jsl: [
        ['summer', 'winter'],
        ['cat', 'dog'],
        ['cap', 'watch'],
        ['high_heels', 'sneakers'],
        ['gray', 'brown'],
        ['shirt', 'jacket'],
      ],
      hksl: [
        ['summer', 'winter'],
        ['tiger', 'panda'],
        ['sunglasses', 'hat'],
        ['high_heels', 'flip_flops'],
        ['purple', 'grey'],
        ['shirt', 'jacket'],
      ],
    },
    1: {
      jsl: [
        ['carrot', 'tomato'],
        ['lemon', 'strawberry'],
        ['draught_beer', 'wine'],
        ['customers', 'waiter'],
        ['pasta', 'steak'],
      ],
      hksl: [
        ['carrot', 'tomato'],
        ['lemon', 'mango'],
        ['coffee', 'milk_tea'],
        ['chef', 'waiter'],
        ['pasta', 'steak'],
      ],
    },
    2: {
      jsl: [
        ['bathroom', 'bed'],
        ['shampoo', 'body_soap'],
        ['keys', 'amenities'],
        ['toiletries', 'smoking_room'],
        ['reservation', 'booking'],
        ['cash', 'credit_cards'],
        ['swimming_pool', 'reception_center'],
        ['prepayment', 'free_of_charge'],
        ['wifi', 'password'],
      ],
      hksl: [
        ['bathroom', 'bed'],
        ['shampoo', 'body_soap'],
        ['keys', 'amenities'],
        ['toiletries', 'smoking_room'],
        ['reservation', 'booking'],
        ['cash', 'credit_cards'],
        ['swimming_pool', 'reception_center'],
        ['prepayment', 'free_of_charge'],
        ['wifi', 'password'],
      ],
    },
  },
};

interface IvideoListConfigTypeII {
  [key: string]: {
    [key: number]: {
      [key: string]: string[];
    };
  };
}

export const videoListConfigTypeII: IvideoListConfigTypeII = {
  hotel: {
    0: {
      jsl: [
        'summer',
        'winter',
        'cat',
        'dog',
        'cap',
        'watch',
        'high_heels',
        'sneakers',
        'gray',
        'brown',
        'shirt',
        'jacket',
      ],
      hksl: [
        'summer',
        'winter',
        'tiger',
        'panda',
        'sunglasses',
        'hat',
        'high_heels',
        'flip_flops',
        'purple',
        'grey',
        'shirt',
        'jacket',
      ],
    },
    1: {
      jsl: [
        'carrot',
        'tomato',
        'lemon',
        'strawberry',
        'draught_beer',
        'wine',
        'customers',
        'waiter',
        'pasta',
        'steak',
      ],
      hksl: [
        'carrot',
        'tomato',
        'lemon',
        'mango',
        'coffee',
        'milk_tea',
        'chef',
        'waiter',
        'pasta',
        'steak',
      ],
    },
    2: {
      jsl: [
        'bathroom',
        'bed',
        'shampoo',
        'body_soap',
        'keys',
        'amenities',
        'toiletries',
        'smoking_room',
        'reservation',
        'booking',
        'cash',
        'credit_cards',
        'swimming_pool',
        'reception_center',
        'prepayment',
        'free_of_charge',
        'wifi',
        'password',
      ],
      hksl: [
        'bathroom',
        'bed',
        'shampoo',
        'body_soap',
        'keys',
        'amenities',
        'toiletries',
        'smoking_room',
        'reservation',
        'booking',
        'cash',
        'credit_cards',
        'swimming_pool',
        'reception_center',
        'prepayment',
        'free_of_charge',
        'wifi',
        'password',
      ],
    },
  },
  town: {
    0: {
      jsl: [
        'summer',
        'winter',
        'cat',
        'dog',
        'cap',
        'watch',
        'high_heels',
        'sneakers',
        'gray',
        'brown',
        'shirt',
        'jacket',
      ],
      hksl: [
        'summer',
        'winter',
        'tiger',
        'panda',
        'sunglasses',
        'hat',
        'high_heels',
        'flip_flops',
        'purple',
        'grey',
        'shirt',
        'jacket',
      ],
    },
    1: {
      jsl: [
        'carrot',
        'tomato',
        'lemon',
        'strawberry',
        'draught_beer',
        'wine',
        'customers',
        'waiter',
        'pasta',
        'steak',
      ],
      hksl: [
        'carrot',
        'tomato',
        'lemon',
        'mango',
        'coffee',
        'milk_tea',
        'chef',
        'waiter',
        'pasta',
        'steak',
      ],
    },
    2: {
      jsl: [
        'bathroom',
        'bed',
        'shampoo',
        'body_soap',
        'keys',
        'amenities',
        'toiletries',
        'smoking_room',
        'reservation',
        'booking',
        'cash',
        'credit_cards',
        'swimming_pool',
        'reception_center',
        'prepayment',
        'free_of_charge',
        'wifi',
        'password',
      ],
      hksl: [
        'bathroom',
        'bed',
        'shampoo',
        'body_soap',
        'keys',
        'amenities',
        'toiletries',
        'smoking_room',
        'reservation',
        'booking',
        'cash',
        'credit_cards',
        'swimming_pool',
        'reception_center',
        'prepayment',
        'free_of_charge',
        'wifi',
        'password',
      ],
    },
  },
};

/**
 * both hksl and jsl
 * bicycle
 * motorcycle
 * winter
 */

export const KNN_LIST = {
  JSL: [
    'bathroom',
    'body_soap',
    'bicycle',
    'cap',
    'carrot',
    'cash',
    'credit_cards',
    'department_store',
    'dog',
    'double_bed',
    'draught_beer',
    'geta',
    'goldfish',
    'grey',
    'high_heels',
    'jacket',
    'lemon',
    'motorcycle',
    'non_smoking_room',
    'onsite_payment',
    'pasta',
    'reservation',
    'shampoo',
    'single_bed',
    'smoking_room',
    'steak',
    'strawberry',
    'summer',
    'swimming_pool',
    'shirt',
    'tomato',
    'waiter',
    'watch',
    'wine',
    'yellow',
    'winter',
  ],
  HKSL: [
    'bathroom',
    'bicycle',
    'body_soap',
    'carrot',
    'cash',
    'coffee',
    'credit_cards',
    'double_bed',
    'flip_flops',
    'grey',
    'hat',
    'high_heels',
    'jacket',
    'lemon',
    'mango',
    'milk_tea',
    'motorcycle',
    'non_smoking_room',
    'onsite_payment',
    'panda',
    'pasta',
    'post_office',
    'purple',
    'reservation',
    'shampoo',
    'shirt',
    'single_bed',
    'smoking_room',
    'steak',
    'summer',
    'sunglasses',
    'swimming_pool',
    'tiger',
    'tomato',
    'waiter',
    'winter',
  ],
};

interface iKNN_Double {
  [key: string]: number;
}
export const KNN_Double: iKNN_Double = {
  bicycle: 2,
  motorcycle: 17,
  shirt: 26,
  winter: 36,
};
