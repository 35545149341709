import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InstructionPageProps } from '.';
import config from '../../config';
import { ModalContext } from '../../layout/Modal';
import { ActionButton } from '../Button';
import SVGResponsivePlayer from '../SVGResponsivePlayer';

const Lottie = styled.div`
  flex: 1;
  width: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  background: linear-gradient(
    to bottom,
    #f0f0f0,
    #f0f0f0 69.5%,
    black 69.5%,
    black ${69.5 + config.baselineWidth * 100}%,
    #dddddd ${69.5 + config.baselineWidth * 100}%,
    #dddddd
  );
`;

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 7.5%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Title = styled.h2`
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%, 0);

  font-size: calc(21px * var(--scale, 1));
  font-weight: 600;
`;

const ReadyPage = (props: InstructionPageProps): React.ReactElement => {
  const { width, height } = React.useContext(ModalContext);
  const { t } = useTranslation();

  const style: { [key: string]: string | number } = {
    width,
    height,
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: -1,
  };
  return (
    <Lottie>
      <Title>{t('onboarding.instruction.readytoplay')}</Title>
      <div style={style}>
        <svg
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <g
            transform={`translate(${(style.width as number) * 0.45}, ${
              (style.height as number) * 0.68
            })`}
          >
            <SVGResponsivePlayer
              src={`/assets/map/home/onboarding_instruction/back/export_Onboarding.json`}
              fitBy={'width'}
              side={(style.width as number) * 0.75}
            />
          </g>
        </svg>
      </div>
      <ButtonContainer>
        <ActionButton onClick={props.onFinish}>
          {t('onboarding.instruction.playsigntown')}
        </ActionButton>
      </ButtonContainer>
    </Lottie>
  );
};

export default ReadyPage;
