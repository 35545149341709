import * as React from 'react';

import ParallaxMap, { Scene, Layer, Asset } from '../containers/ParallaxMap';
import { deafCultureName } from '../recoils/deafCultureStateAtom';
import MainCharacter from '../containers/MainCharacter';

import { IMap } from './index';
import useSound from '../utils/useSound';

export default function TownMap({
  mapName,
  dimension,
}: IMap): React.ReactElement {
  const characterSound = useSound('./assets/sound/character.wav');
  const playSound = () => {
    characterSound.play();
  };
  const stopSound = () => {
    characterSound.pause();
    characterSound.setCurrentTime(0);
  };
  return (
    <ParallaxMap
      name={mapName}
      dimension={dimension}
      Background={<Layer></Layer>}
      Character={
        <Layer>
          <MainCharacter />
        </Layer>
      }
    >
      {/* Packing Scene */}
      <Scene name={'packing'}>
        <Layer>
          <Asset
            name="export_01_parking_E01" // birds
            size={0.7}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.3 }}
            speed={-1}
            transitionWeight={6}
          />
          <Asset
            name="packing_scene_02_C01" // tree
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.3}
          />
          <Asset
            name="packing_scene_02_D01" // building
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.15}
          />
          <Asset
            name="packing_scene_02_A01" // bus stop
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.15}
          />
          <Asset
            name="packing_scene_02_B01" // bus sign
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.15}
          />
          <Asset
            name="packing_scene_02_ambassador" // pedestrian
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0}
            deafCulture={deafCultureName.BAG_CHARACTER}
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
          />
        </Layer>
        <Layer></Layer>
      </Scene>

      {/* Restaurant Scene */}
      <Scene name={'restaurant'}>
        <Layer>
          <Asset
            name="export_02_restaurant_E01_birds" // birds
            size={0.3}
            frame={{ start: 0, end: 9 }}
            start={{ x: 1.1, y: 0.35 }}
            speed={2.2}
            transitionWeight={6}
            flip
          />
          <Asset
            name="export_02_restaurant_B02" // mountain
            size={0.7}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={-0.1}
          />
          <Asset
            name="export_02_restaurant_A02" // building
            size={0.7}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={-0.2}
          />
          <Asset
            name="export_02_restaurant_D02_character" // character
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.52, y: 0.7 }}
            speed={-0.5}
            deafCulture={deafCultureName.CHARACTER_SWITCH_POSITION}
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
          />
          <Asset
            name="export_02_restaurant_F01_character" // character 2
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.52, y: 0.7 }}
            speed={-0.5}
            deafCulture={deafCultureName.LIGHT_ON}
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
          />
        </Layer>
        <Layer></Layer>
      </Scene>

      {/* hotel Scene */}
      <Scene name={'hotel'}>
        <Layer>
          <Asset
            name="export_03_hotel_E02" // plane
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 1.1, y: 0.3 }}
            speed={2.2}
            transitionWeight={4}
            flip
          />
          <Asset
            name="export_03_hotel_A02" // building
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.3}
          />
          <Asset
            name="export_03_hotel_C02_character" // ambassador
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.1}
            deafCulture={[
              deafCultureName.CLOCK_VIBRATE,
              deafCultureName.LIGHT_SWITCH,
            ]}
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
          />
          <Asset
            name="export_03_hotel_B02" // taxi
            size={0.42}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.39}
          />
          <Asset
            name="export_03_hotel_D02" // tree
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.6, y: 0.7 }}
            speed={0.19}
          />
        </Layer>
        <Layer></Layer>
      </Scene>

      {/* zoo Scene */}
      {/* <Scene name={'zoo'}>
        <Layer>
          <Asset
            name="export_Zoo_04" // building
            size={1}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.65, y: 0.7 }}
            speed={0.2}
          />
          <Asset
            name="export_04_park_E01" // "pedestrian small"
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.37, y: 0.7 }}
            speed={0.1}
            deafCulture={deafCultureName.HAND_WAVE_TAP_SHOULDER}
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
          />
        </Layer>
        <Layer></Layer>
      </Scene> */}

      {/* Park Scene */}
      {/* <Scene name={'park'}>
        <Layer>
          <Asset
            name="export_04_park_D02" // bird
            size={0.7}
            frame={{ start: 0, end: 9 }}
            start={{ x: -0.1, y: 0.3 }}
            speed={0}
            transitionWeight={8}
          />
          <Asset
            name="export_04_park_B02" // bench
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            speed={0.2}
          />
          <Asset
            name="export_04_park_C02" // bike
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            speed={0}
          />
          <Asset
            name="export_04_park_E01" // "pedestrian small"
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            speed={0.1}
            deafCulture={deafCultureName.HAND_WAVE_TAP_SHOULDER}
            onMouseEnter={playSound}
            onMouseLeave={stopSound}
          />
          <Asset
            name="export_04_park_A02_character" // "pedestrian"
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            speed={0.1}
          />
          <Asset
            name="export_04_park_F01_leaf" // leaf
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            speed={-0.5}
          />
        </Layer>
        <Layer></Layer>
      </Scene> */}

      {/* Busstop Scene */}
      <Scene name={'thankyou'}>
        <Layer>
          <Asset
            name="export_07_dog_B01" // dog
            size={0.5}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.7 }}
            speed={0.3}
          />
          <Asset
            name="export_07_dog_A01" // cloud
            size={0.45}
            frame={{ start: 0, end: 9 }}
            start={{ x: 0.5, y: 0.65 }}
            speed={0.3}
          />
        </Layer>
        <Layer></Layer>
      </Scene>
    </ParallaxMap>
  );
}
