(function () {
  /*

     Copyright The Closure Library Authors.
     SPDX-License-Identifier: Apache-2.0
    */
  'use strict';
  var A;

  function aa(a) {
    var c = 0;
    return function () {
      return c < a.length ? { done: !1, value: a[c++] } : { done: !0 };
    };
  }
  var D =
    'function' == typeof Object.defineProperties
      ? Object.defineProperty
      : function (a, c, b) {
          if (a == Array.prototype || a == Object.prototype) return a;
          a[c] = b.value;
          return a;
        };

  function ba(a) {
    a = [
      'object' == typeof globalThis && globalThis,
      a,
      'object' == typeof window && window,
      'object' == typeof self && self,
      'object' == typeof global && global,
    ];
    for (var c = 0; c < a.length; ++c) {
      var b = a[c];
      if (b && b.Math == Math) return b;
    }
    throw Error('Cannot find global object');
  }
  var E = ba(this);

  function F(a, c) {
    if (c)
      a: {
        var b = E;
        a = a.split('.');
        for (var d = 0; d < a.length - 1; d++) {
          var g = a[d];
          if (!(g in b)) break a;
          b = b[g];
        }
        a = a[a.length - 1];
        d = b[a];
        c = c(d);
        c != d &&
          null != c &&
          D(b, a, { configurable: !0, writable: !0, value: c });
      }
  }
  F('Symbol', function (a) {
    function c(h) {
      if (this instanceof c) throw new TypeError('Symbol is not a constructor');
      return new b(d + (h || '') + '_' + g++, h);
    }

    function b(h, e) {
      this.g = h;
      D(this, 'description', { configurable: !0, writable: !0, value: e });
    }
    if (a) return a;
    b.prototype.toString = function () {
      return this.g;
    };
    var d = 'jscomp_symbol_' + ((1e9 * Math.random()) >>> 0) + '_',
      g = 0;
    return c;
  });
  F('Symbol.iterator', function (a) {
    if (a) return a;
    a = Symbol('Symbol.iterator');
    for (
      var c = 'Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array'.split(
          ' ',
        ),
        b = 0;
      b < c.length;
      b++
    ) {
      var d = E[c[b]];
      'function' === typeof d &&
        'function' != typeof d.prototype[a] &&
        D(d.prototype, a, {
          configurable: !0,
          writable: !0,
          value: function () {
            return ca(aa(this));
          },
        });
    }
    return a;
  });

  function ca(a) {
    a = { next: a };
    a[Symbol.iterator] = function () {
      return this;
    };
    return a;
  }

  function H(a) {
    var c =
      'undefined' != typeof Symbol && Symbol.iterator && a[Symbol.iterator];
    return c ? c.call(a) : { next: aa(a) };
  }

  function J(a) {
    if (!(a instanceof Array)) {
      a = H(a);
      for (var c, b = []; !(c = a.next()).done; ) b.push(c.value);
      a = b;
    }
    return a;
  }
  var M;
  if ('function' == typeof Object.setPrototypeOf) M = Object.setPrototypeOf;
  else {
    var N;
    a: {
      var da = { a: !0 },
        ha = {};
      try {
        ha.__proto__ = da;
        N = ha.a;
        break a;
      } catch (a) {}
      N = !1;
    }
    M = N
      ? function (a, c) {
          a.__proto__ = c;
          if (a.__proto__ !== c) throw new TypeError(a + ' is not extensible');
          return a;
        }
      : null;
  }
  var ia = M;

  function O() {
    this.l = !1;
    this.i = null;
    this.h = void 0;
    this.g = 1;
    this.s = this.m = 0;
    this.j = null;
  }

  function S(a) {
    if (a.l) throw new TypeError('Generator is already running');
    a.l = !0;
  }
  O.prototype.o = function (a) {
    this.h = a;
  };

  function T(a, c) {
    a.j = { S: c, T: !0 };
    a.g = a.m || a.s;
  }
  O.prototype.return = function (a) {
    this.j = { return: a };
    this.g = this.s;
  };

  function U(a, c, b) {
    a.g = b;
    return { value: c };
  }

  function ja(a) {
    this.g = new O();
    this.h = a;
  }

  function ka(a, c) {
    S(a.g);
    var b = a.g.i;
    if (b)
      return V(
        a,
        'return' in b
          ? b['return']
          : function (d) {
              return { value: d, done: !0 };
            },
        c,
        a.g.return,
      );
    a.g.return(c);
    return W(a);
  }

  function V(a, c, b, d) {
    try {
      var g = c.call(a.g.i, b);
      if (!(g instanceof Object))
        throw new TypeError('Iterator result ' + g + ' is not an object');
      if (!g.done) return (a.g.l = !1), g;
      var h = g.value;
    } catch (e) {
      return (a.g.i = null), T(a.g, e), W(a);
    }
    a.g.i = null;
    d.call(a.g, h);
    return W(a);
  }

  function W(a) {
    for (; a.g.g; )
      try {
        var c = a.h(a.g);
        if (c) return (a.g.l = !1), { value: c.value, done: !1 };
      } catch (b) {
        (a.g.h = void 0), T(a.g, b);
      }
    a.g.l = !1;
    if (a.g.j) {
      c = a.g.j;
      a.g.j = null;
      if (c.T) throw c.S;
      return { value: c.return, done: !0 };
    }
    return { value: void 0, done: !0 };
  }

  function la(a) {
    this.next = function (c) {
      S(a.g);
      a.g.i ? (c = V(a, a.g.i.next, c, a.g.o)) : (a.g.o(c), (c = W(a)));
      return c;
    };
    this.throw = function (c) {
      S(a.g);
      a.g.i ? (c = V(a, a.g.i['throw'], c, a.g.o)) : (T(a.g, c), (c = W(a)));
      return c;
    };
    this.return = function (c) {
      return ka(a, c);
    };
    this[Symbol.iterator] = function () {
      return this;
    };
  }

  function X(a, c) {
    c = new la(new ja(c));
    ia && a.prototype && ia(c, a.prototype);
    return c;
  }
  var ma =
    'function' == typeof Object.assign
      ? Object.assign
      : function (a, c) {
          for (var b = 1; b < arguments.length; b++) {
            var d = arguments[b];
            if (d)
              for (var g in d)
                Object.prototype.hasOwnProperty.call(d, g) && (a[g] = d[g]);
          }
          return a;
        };
  F('Object.assign', function (a) {
    return a || ma;
  });
  F('Promise', function (a) {
    function c(e) {
      this.h = 0;
      this.i = void 0;
      this.g = [];
      this.o = !1;
      var f = this.j();
      try {
        e(f.resolve, f.reject);
      } catch (k) {
        f.reject(k);
      }
    }

    function b() {
      this.g = null;
    }

    function d(e) {
      return e instanceof c
        ? e
        : new c(function (f) {
            f(e);
          });
    }
    if (a) return a;
    b.prototype.h = function (e) {
      if (null == this.g) {
        this.g = [];
        var f = this;
        this.i(function () {
          f.l();
        });
      }
      this.g.push(e);
    };
    var g = E.setTimeout;
    b.prototype.i = function (e) {
      g(e, 0);
    };
    b.prototype.l = function () {
      for (; this.g && this.g.length; ) {
        var e = this.g;
        this.g = [];
        for (var f = 0; f < e.length; ++f) {
          var k = e[f];
          e[f] = null;
          try {
            k();
          } catch (m) {
            this.j(m);
          }
        }
      }
      this.g = null;
    };
    b.prototype.j = function (e) {
      this.i(function () {
        throw e;
      });
    };
    c.prototype.j = function () {
      function e(m) {
        return function (n) {
          k || ((k = !0), m.call(f, n));
        };
      }
      var f = this,
        k = !1;
      return { resolve: e(this.B), reject: e(this.l) };
    };
    c.prototype.B = function (e) {
      if (e === this)
        this.l(new TypeError('A Promise cannot resolve to itself'));
      else if (e instanceof c) this.D(e);
      else {
        a: switch (typeof e) {
          case 'object':
            var f = null != e;
            break a;
          case 'function':
            f = !0;
            break a;
          default:
            f = !1;
        }
        f ? this.v(e) : this.m(e);
      }
    };
    c.prototype.v = function (e) {
      var f = void 0;
      try {
        f = e.then;
      } catch (k) {
        this.l(k);
        return;
      }
      'function ' == typeof f ? this.F(f, e) : this.m(e);
    };
    c.prototype.l = function (e) {
      this.s(2, e);
    };
    c.prototype.m = function (e) {
      this.s(1, e);
    };
    c.prototype.s = function (e, f) {
      if (0 != this.h)
        throw Error(
          'Cannot settle(' +
            e +
            ', ' +
            f +
            '): Promise already settled in state ' +
            this.h,
        );
      this.h = e;
      this.i = f;
      2 === this.h && this.C();
      this.u();
    };
    c.prototype.C = function () {
      var e = this;
      g(function () {
        if (e.A()) {
          var f = E.console;
          'undefined ' !== typeof f && f.error(e.i);
        }
      }, 1);
    };
    c.prototype.A = function () {
      if (this.o) return !1;
      var e = E.CustomEvent,
        f = E.Event,
        k = E.dispatchEvent;
      if ('undefined' === typeof k) return !0;
      'function' === typeof e
        ? (e = new e('unhandledrejection', { cancelable: !0 }))
        : 'function' === typeof f
        ? (e = new f('unhandledrejection', { cancelable: !0 }))
        : ((e = E.document.createEvent('CustomEvent')),
          e.initCustomEvent('unhandledrejection', !1, !0, e));
      e.promise = this;
      e.reason = this.i;
      return k(e);
    };
    c.prototype.u = function () {
      if (null != this.g) {
        for (var e = 0; e < this.g.length; ++e) h.h(this.g[e]);
        this.g = null;
      }
    };
    var h = new b();
    c.prototype.D = function (e) {
      var f = this.j();
      e.I(f.resolve, f.reject);
    };
    c.prototype.F = function (e, f) {
      var k = this.j();
      try {
        e.call(f, k.resolve, k.reject);
      } catch (m) {
        k.reject(m);
      }
    };
    c.prototype.then = function (e, f) {
      function k(u, p) {
        return 'function' == typeof u
          ? function (q) {
              try {
                m(u(q));
              } catch (l) {
                n(l);
              }
            }
          : p;
      }
      var m,
        n,
        w = new c(function (u, p) {
          m = u;
          n = p;
        });
      this.I(k(e, m), k(f, n));
      return w;
    };
    c.prototype.catch = function (e) {
      return this.then(void 0, e);
    };
    c.prototype.I = function (e, f) {
      function k() {
        switch (m.h) {
          case 1:
            e(m.i);
            break;
          case 2:
            f(m.i);
            break;
          default:
            throw Error('Unexpected state: ' + m.h);
        }
      }
      var m = this;
      null == this.g ? h.h(k) : this.g.push(k);
      this.o = !0;
    };
    c.resolve = d;
    c.reject = function (e) {
      return new c(function (f, k) {
        k(e);
      });
    };
    c.race = function (e) {
      return new c(function (f, k) {
        for (var m = H(e), n = m.next(); !n.done; n = m.next())
          d(n.value).I(f, k);
      });
    };
    c.all = function (e) {
      var f = H(e),
        k = f.next();
      return k.done
        ? d([])
        : new c(function (m, n) {
            function w(q) {
              return function (l) {
                u[q] = l;
                p--;
                0 == p && m(u);
              };
            }
            var u = [],
              p = 0;
            do
              u.push(void 0),
                p++,
                d(k.value).I(w(u.length - 1), n),
                (k = f.next());
            while (!k.done);
          });
    };
    return c;
  });
  F('Object.is', function (a) {
    return a
      ? a
      : function (c, b) {
          return c === b ? 0 !== c || 1 / c === 1 / b : c !== c && b !== b;
        };
  });
  F('Array.prototype.includes', function (a) {
    return a
      ? a
      : function (c, b) {
          var d = this;
          d instanceof String && (d = String(d));
          var g = d.length;
          b = b || 0;
          for (0 > b && (b = Math.max(b + g, 0)); b < g; b++) {
            var h = d[b];
            if (h === c || Object.is(h, c)) return !0;
          }
          return !1;
        };
  });
  F('String.prototype.includes', function (a) {
    return a
      ? a
      : function (c, b) {
          if (null == this)
            throw new TypeError(
              "The 'this' value for String.prototype.includes must not be null or undefined",
            );
          if (c instanceof RegExp)
            throw new TypeError(
              'First argument to String.prototype.includes must not be a regular expression',
            );
          return -1 !== this.indexOf(c, b || 0);
        };
  });

  function na(a, c) {
    a instanceof String && (a += '');
    var b = 0,
      d = !1,
      g = {
        next: function () {
          if (!d && b < a.length) {
            var h = b++;
            return { value: c(h, a[h]), done: !1 };
          }
          d = !0;
          return { done: !0, value: void 0 };
        },
      };
    g[Symbol.iterator] = function () {
      return g;
    };
    return g;
  }
  F('Array.prototype.keys', function (a) {
    return a
      ? a
      : function () {
          return na(this, function (c) {
            return c;
          });
        };
  });
  var oa = this || self;

  function Y(a, c) {
    a = a.split('.');
    var b = oa;
    a[0] in b ||
      'undefined' == typeof b.execScript ||
      b.execScript('var ' + a[0]);
    for (var d; a.length && (d = a.shift()); )
      a.length || void 0 === c
        ? b[d] && b[d] !== Object.prototype[d]
          ? (b = b[d])
          : (b = b[d] = {})
        : (b[d] = c);
  }

  function Z(a, c) {
    var b = void 0;
    return new (b || (b = Promise))(function (d, g) {
      function h(k) {
        try {
          f(c.next(k));
        } catch (m) {
          g(m);
        }
      }

      function e(k) {
        try {
          f(c['throw'](k));
        } catch (m) {
          g(m);
        }
      }

      function f(k) {
        k.done
          ? d(k.value)
          : new b(function (m) {
              m(k.value);
            }).then(h, e);
      }
      f((c = c.apply(a, void 0)).next());
    });
  }
  var pa = [
      [61, 146],
      [146, 91],
      [91, 181],
      [181, 84],
      [84, 17],
      [17, 314],
      [314, 405],
      [405, 321],
      [321, 375],
      [375, 291],
      [61, 185],
      [185, 40],
      [40, 39],
      [39, 37],
      [37, 0],
      [0, 267],
      [267, 269],
      [269, 270],
      [270, 409],
      [409, 291],
      [78, 95],
      [95, 88],
      [88, 178],
      [178, 87],
      [87, 14],
      [14, 317],
      [317, 402],
      [402, 318],
      [318, 324],
      [324, 308],
      [78, 191],
      [191, 80],
      [80, 81],
      [81, 82],
      [82, 13],
      [13, 312],
      [312, 311],
      [311, 310],
      [310, 415],
      [415, 308],
    ],
    qa = [
      [263, 249],
      [249, 390],
      [390, 373],
      [373, 374],
      [374, 380],
      [380, 381],
      [381, 382],
      [382, 362],
      [263, 466],
      [466, 388],
      [388, 387],
      [387, 386],
      [386, 385],
      [385, 384],
      [384, 398],
      [398, 362],
    ],
    ra = [
      [276, 283],
      [283, 282],
      [282, 295],
      [295, 285],
      [300, 293],
      [293, 334],
      [334, 296],
      [296, 336],
    ],
    sa = [
      [33, 7],
      [7, 163],
      [163, 144],
      [144, 145],
      [145, 153],
      [153, 154],
      [154, 155],
      [155, 133],
      [33, 246],
      [246, 161],
      [161, 160],
      [160, 159],
      [159, 158],
      [158, 157],
      [157, 173],
      [173, 133],
    ],
    ta = [
      [46, 53],
      [53, 52],
      [52, 65],
      [65, 55],
      [70, 63],
      [63, 105],
      [105, 66],
      [66, 107],
    ],
    ua = [
      [10, 338],
      [338, 297],
      [297, 332],
      [332, 284],
      [284, 251],
      [251, 389],
      [389, 356],
      [356, 454],
      [454, 323],
      [323, 361],
      [361, 288],
      [288, 397],
      [397, 365],
      [365, 379],
      [379, 378],
      [378, 400],
      [400, 377],
      [377, 152],
      [152, 148],
      [148, 176],
      [176, 149],
      [149, 150],
      [150, 136],
      [136, 172],
      [172, 58],
      [58, 132],
      [132, 93],
      [93, 234],
      [234, 127],
      [127, 162],
      [162, 21],
      [21, 54],
      [54, 103],
      [103, 67],
      [67, 109],
      [109, 10],
    ],
    za = [].concat(J(pa), J(qa), J(ra), J(sa), J(ta), J(ua));

  function Aa(a, c, b) {
    b = a.createShader(0 === b ? a.VERTEX_SHADER : a.FRAGMENT_SHADER);
    a.shaderSource(b, c);
    a.compileShader(b);
    if (!a.getShaderParameter(b, a.COMPILE_STATUS))
      throw Error(
        'Could not compile WebGL shader.\n\n' + a.getShaderInfoLog(b),
      );
    return b;
  }

  function Ba(a, c, b) {
    this.h = a;
    this.g = c;
    this.u = b;
    this.l = 0;
  }

  function Ca(a) {
    if ('function' === typeof a.g.canvas.transferToImageBitmap)
      return Promise.resolve(a.g.canvas.transferToImageBitmap());
    if (a.u) return Promise.resolve(a.g.canvas);
    if ('function' === typeof createImageBitmap)
      return createImageBitmap(a.g.canvas);
    void 0 === a.j && (a.j = document.createElement('img'));
    return new Promise(function (c) {
      a.j.onload = function () {
        requestAnimationFrame(function () {
          c(a.j);
        });
      };
      a.j.src = a.g.canvas.toDataURL();
    });
  }

  function Da(a, c) {
    var b = a.g;
    if (void 0 === a.m) {
      var d = Aa(
          b,
          '\n  attribute vec2 aVertex;\n  attribute vec2 aTex;\n  varying vec2 vTex;\n  void main(void) {\n    gl_Position = vec4(aVertex, 0.0, 1.0);\n    vTex = aTex;\n  }',
          0,
        ),
        g = Aa(
          b,
          '\n  precision mediump float;\n  varying vec2 vTex;\n  uniform sampler2D sampler0;\n  void main(){\n    gl_FragColor = texture2D(sampler0, vTex);\n  }',
          1,
        ),
        h = b.createProgram();
      b.attachShader(h, d);
      b.attachShader(h, g);
      b.linkProgram(h);
      if (!b.getProgramParameter(h, b.LINK_STATUS))
        throw Error(
          'Could not compile WebGL program.\n\n' + b.getProgramInfoLog(h),
        );
      d = a.m = h;
      b.useProgram(d);
      g = b.getUniformLocation(d, 'sampler0');
      a.i = {
        H: b.getAttribLocation(d, 'aVertex'),
        G: b.getAttribLocation(d, 'aTex'),
        W: g,
      };
      a.s = b.createBuffer();
      b.bindBuffer(b.ARRAY_BUFFER, a.s);
      b.enableVertexAttribArray(a.i.H);
      b.vertexAttribPointer(a.i.H, 2, b.FLOAT, !1, 0, 0);
      b.bufferData(
        b.ARRAY_BUFFER,
        new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]),
        b.STATIC_DRAW,
      );
      b.bindBuffer(b.ARRAY_BUFFER, null);
      a.o = b.createBuffer();
      b.bindBuffer(b.ARRAY_BUFFER, a.o);
      b.enableVertexAttribArray(a.i.G);
      b.vertexAttribPointer(a.i.G, 2, b.FLOAT, !1, 0, 0);
      b.bufferData(
        b.ARRAY_BUFFER,
        new Float32Array([0, 1, 0, 0, 1, 0, 1, 1]),
        b.STATIC_DRAW,
      );
      b.bindBuffer(b.ARRAY_BUFFER, null);
      b.uniform1i(g, 0);
    }
    d = a.i;
    b.useProgram(a.m);
    b.canvas.width = c.width;
    b.canvas.height = c.height;
    b.viewport(0, 0, c.width, c.height);
    b.activeTexture(b.TEXTURE0);
    a.h.bindTexture2d(c.glName);
    b.enableVertexAttribArray(d.H);
    b.bindBuffer(b.ARRAY_BUFFER, a.s);
    b.vertexAttribPointer(d.H, 2, b.FLOAT, !1, 0, 0);
    b.enableVertexAttribArray(d.G);
    b.bindBuffer(b.ARRAY_BUFFER, a.o);
    b.vertexAttribPointer(d.G, 2, b.FLOAT, !1, 0, 0);
    b.bindFramebuffer(
      b.DRAW_FRAMEBUFFER ? b.DRAW_FRAMEBUFFER : b.FRAMEBUFFER,
      null,
    );
    b.clearColor(0, 0, 0, 0);
    b.clear(b.COLOR_BUFFER_BIT);
    b.colorMask(!0, !0, !0, !0);
    b.drawArrays(b.TRIANGLE_FAN, 0, 4);
    b.disableVertexAttribArray(d.H);
    b.disableVertexAttribArray(d.G);
    b.bindBuffer(b.ARRAY_BUFFER, null);
    a.h.bindTexture2d(0);
  }

  function Ea(a) {
    this.g = a;
  }
  var Fa = new Uint8Array([
    0,
    97,
    115,
    109,
    1,
    0,
    0,
    0,
    1,
    4,
    1,
    96,
    0,
    0,
    3,
    2,
    1,
    0,
    10,
    9,
    1,
    7,
    0,
    65,
    0,
    253,
    15,
    26,
    11,
  ]);

  function Ga(a, c) {
    return c + a;
  }

  function Ha(a, c) {
    window[a] = c;
  }

  function Ia(a) {
    var c = document.createElement('script');
    c.setAttribute('src', a);
    c.setAttribute('crossorigin', 'anonymous');
    return new Promise(function (b) {
      c.addEventListener(
        'load',
        function () {
          b();
        },
        !1,
      );
      c.addEventListener(
        'error',
        function () {
          b();
        },
        !1,
      );
      document.body.appendChild(c);
    });
  }

  function Ja(a) {
    for (var c = [], b = a.size(), d = 0; d < b; ++d) {
      var g = a.get(d);
      c.push({
        x: g.x,
        y: g.y,
        z: g.z,
        visibility: g.hasVisibility ? g.visibility : void 0,
      });
      g.delete();
    }
    return c;
  }

  function Ka(a) {
    for (var c = [], b = a.size(), d = 0; d < b; ++d) {
      var g = a.get(d);
      c.push({
        index: g.index,
        score: g.score,
        label: g.hasLabel ? g.label : void 0,
        displayName: g.hasDisplayName ? g.displayName : void 0,
      });
    }
    return c;
  }

  function La() {
    return Z(this, function c() {
      return X(c, function (b) {
        switch (b.g) {
          case 1:
            return (b.m = 2), U(b, WebAssembly.instantiate(Fa), 4);
          case 4:
            b.g = 3;
            b.m = 0;
            break;
          case 2:
            return (b.m = 0), (b.j = null), b.return(!1);
          case 3:
            return b.return(!0);
        }
      });
    });
  }

  function Ma(a) {
    this.g = a;
    this.listeners = {};
    this.j = {};
    this.D = {};
    this.m = {};
    this.s = {};
    this.B = this.o = this.O = !0;
    this.F = Promise.resolve();
    this.N = '';
    this.A = {};
    this.locateFile = (a && a.locateFile) || Ga;
    if ('object' === typeof window)
      var c =
        window.location.pathname
          .toString()
          .substring(0, window.location.pathname.toString().lastIndexOf('/')) +
        '/';
    else if ('undefined' !== typeof location)
      c =
        location.pathname
          .toString()
          .substring(0, location.pathname.toString().lastIndexOf('/')) + '/';
    else
      throw Error(
        'solutions can only be loaded on a web page or in a web worker',
      );
    this.P = c;
    if (a.options) {
      c = H(Object.keys(a.options));
      for (var b = c.next(); !b.done; b = c.next()) {
        b = b.value;
        var d = a.options[b].default;
        void 0 !== d && (this.j[b] = 'function' === typeof d ? d() : d);
      }
    }
  }
  A = Ma.prototype;
  A.close = function () {
    this.i && this.i.delete();
    return Promise.resolve();
  };

  function Na(a, c) {
    return void 0 === a.g.files
      ? []
      : 'function' === typeof a.g.files
      ? a.g.files(c)
      : a.g.files;
  }

  function Oa(a) {
    return Z(a, function b() {
      var d = this,
        g,
        h,
        e,
        f,
        k,
        m,
        n,
        w,
        u,
        p,
        q;
      return X(b, function (l) {
        switch (l.g) {
          case 1:
            g = d;
            if (!d.O) return l.return();
            h = Na(d, d.j);
            return U(l, La(), 2);
          case 2:
            e = l.h;
            if ('object' === typeof window)
              return (
                Ha('createMediapipeSolutionsWasm', {
                  locateFile: d.locateFile,
                }),
                Ha('createMediapipeSolutionsPackedAssets', {
                  locateFile: d.locateFile,
                }),
                (m = h.filter(function (r) {
                  return void 0 !== r.data;
                })),
                (n = h.filter(function (r) {
                  return void 0 === r.data;
                })),
                (w = Promise.all(
                  m.map(function (r) {
                    var t = Pa(g, r.url);
                    if (void 0 !== r.path) {
                      var v = r.path;
                      t = t.then(function (y) {
                        g.overrideFile(v, y);
                        return Promise.resolve(y);
                      });
                    }
                    return t;
                  }),
                )),
                (u = Promise.all(
                  n.map(function (r) {
                    return void 0 === r.simd || (r.simd && e) || (!r.simd && !e)
                      ? Ia(g.locateFile(r.url, g.P))
                      : Promise.resolve();
                  }),
                ).then(function () {
                  return Z(g, function t() {
                    var v,
                      y,
                      z = this;
                    return X(t, function (B) {
                      if (1 == B.g)
                        return (
                          (v = window.createMediapipeSolutionsWasm),
                          (y = window.createMediapipeSolutionsPackedAssets),
                          U(B, v(y), 2)
                        );
                      z.h = B.h;
                      B.g = 0;
                    });
                  });
                })),
                (p = (function () {
                  return Z(g, function t() {
                    var v = this;
                    return X(t, function (y) {
                      v.g.graph && v.g.graph.url
                        ? (y = U(y, Pa(v, v.g.graph.url), 0))
                        : ((y.g = 0), (y = void 0));
                      return y;
                    });
                  });
                })()),
                U(l, Promise.all([u, w, p]), 7)
              );
            if ('function' !== typeof importScripts)
              throw Error(
                'solutions can only be loaded on a web page or in a web worker',
              );
            f = h
              .filter(function (r) {
                return void 0 === r.simd || (r.simd && e) || (!r.simd && !e);
              })
              .map(function (r) {
                return g.locateFile(r.url, g.P);
              });
            importScripts.apply(null, J(f));
            return U(l, createMediapipeSolutionsWasm(Module), 6);
          case 6:
            d.h = l.h;
            d.l = new OffscreenCanvas(1, 1);
            d.h.canvas = d.l;
            k = d.h.GL.createContext(d.l, {
              antialias: !1,
              alpha: !1,
              V: 'undefined' !== typeof WebGL2RenderingContext ? 2 : 1,
            });
            d.h.GL.makeContextCurrent(k);
            l.g = 4;
            break;
          case 7:
            d.l = document.createElement('canvas');
            q = d.l.getContext('webgl2', {});
            if (!q && ((q = d.l.getContext('webgl', {})), !q))
              return (
                alert(
                  'Failed to create WebGL canvas context when passing video frame.',
                ),
                l.return()
              );
            d.C = q;
            d.h.canvas = d.l;
            d.h.createContext(d.l, !0, !0, {});
          case 4:
            (d.i = new d.h.SolutionWasm()), (d.O = !1), (l.g = 0);
        }
      });
    });
  }

  function Qa(a) {
    return Z(a, function b() {
      var d = this,
        g,
        h,
        e,
        f,
        k,
        m,
        n,
        w;
      return X(b, function (u) {
        if (1 == u.g) {
          if (d.g.graph && d.g.graph.url && d.N === d.g.graph.url)
            return u.return();
          d.o = !0;
          if (!d.g.graph || !d.g.graph.url) {
            u.g = 2;
            return;
          }
          d.N = d.g.graph.url;
          return U(u, Pa(d, d.g.graph.url), 3);
        }
        2 != u.g && ((g = u.h), d.i.loadGraph(g));
        h = H(Object.keys(d.A));
        for (e = h.next(); !e.done; e = h.next())
          (f = e.value), d.i.overrideFile(f, d.A[f]);
        d.A = {};
        if (d.g.listeners)
          for (k = H(d.g.listeners), m = k.next(); !m.done; m = k.next())
            (n = m.value), Ra(d, n);
        w = d.j;
        d.j = {};
        d.setOptions(w);
        u.g = 0;
      });
    });
  }
  A.reset = function () {
    return Z(this, function c() {
      var b = this;
      return X(c, function (d) {
        b.i && (b.i.reset(), (b.m = {}), (b.s = {}));
        d.g = 0;
      });
    });
  };
  A.setOptions = function (a, c) {
    var b = this;
    if ((c = c || this.g.options)) {
      for (
        var d = [], g = [], h = {}, e = H(Object.keys(a)), f = e.next();
        !f.done;
        h = { J: h.J, K: h.K }, f = e.next()
      ) {
        var k = f.value;
        (k in this.j && this.j[k] === a[k]) ||
          ((this.j[k] = a[k]),
          (f = c[k]),
          void 0 !== f &&
            (f.onChange &&
              ((h.J = f.onChange),
              (h.K = a[k]),
              d.push(
                (function (m) {
                  return function () {
                    return Z(b, function w() {
                      var u,
                        p = this;
                      return X(w, function (q) {
                        if (1 == q.g) return U(q, m.J(m.K), 2);
                        u = q.h;
                        !0 === u && (p.o = !0);
                        q.g = 0;
                      });
                    });
                  };
                })(h),
              )),
            f.graphOptionXref &&
              ((k = {
                valueNumber: 1 === f.type ? a[k] : 0,
                valueBoolean: 0 === f.type ? a[k] : !1,
                valueString: 2 === f.type ? a[k] : '',
              }),
              (f = Object.assign(
                Object.assign(
                  Object.assign({}, { calculatorName: '', calculatorIndex: 0 }),
                  f.graphOptionXref,
                ),
                k,
              )),
              g.push(f))));
      }
      if (0 !== d.length || 0 !== g.length)
        (this.o = !0),
          (this.u = (void 0 === this.u ? [] : this.u).concat(g)),
          (this.v = (void 0 === this.v ? [] : this.v).concat(d));
    }
  };

  function Sa(a) {
    return Z(a, function b() {
      var d = this,
        g,
        h,
        e,
        f,
        k,
        m,
        n;
      return X(b, function (w) {
        switch (w.g) {
          case 1:
            if (!d.o) return w.return();
            if (!d.v) {
              w.g = 2;
              break;
            }
            g = H(d.v);
            h = g.next();
          case 3:
            if (h.done) {
              w.g = 5;
              break;
            }
            e = h.value;
            return U(w, e(), 4);
          case 4:
            h = g.next();
            w.g = 3;
            break;
          case 5:
            d.v = void 0;
          case 2:
            if (d.u) {
              f = new d.h.GraphOptionChangeRequestList();
              k = H(d.u);
              for (m = k.next(); !m.done; m = k.next())
                (n = m.value), f.push_back(n);
              d.i.changeOptions(f);
              f.delete();
              d.u = void 0;
            }
            d.o = !1;
            w.g = 0;
        }
      });
    });
  }
  A.initialize = function () {
    return Z(this, function c() {
      var b = this;
      return X(c, function (d) {
        return 1 == d.g
          ? U(d, Oa(b), 2)
          : 3 != d.g
          ? U(d, Qa(b), 3)
          : U(d, Sa(b), 0);
      });
    });
  };

  function Pa(a, c) {
    return Z(a, function d() {
      var g = this,
        h,
        e;
      return X(d, function (f) {
        if (c in g.D) return f.return(g.D[c]);
        h = g.locateFile(c, '');
        e = fetch(h).then(function (k) {
          return k.arrayBuffer();
        });
        g.D[c] = e;
        return f.return(e);
      });
    });
  }
  A.overrideFile = function (a, c) {
    this.i ? this.i.overrideFile(a, c) : (this.A[a] = c);
  };
  A.clearOverriddenFiles = function () {
    this.A = {};
    this.i && this.i.clearOverriddenFiles();
  };
  A.send = function (a, c) {
    return Z(this, function d() {
      var g = this,
        h,
        e,
        f,
        k,
        m,
        n,
        w,
        u,
        p;
      return X(d, function (q) {
        if (1 == q.g) {
          if (!g.g.inputs) return q.return();
          h = 1e3 * (void 0 === c || null === c ? performance.now() : c);
          return U(q, g.F, 2);
        }
        if (3 != q.g) return U(q, g.initialize(), 3);
        e = new g.h.PacketDataList();
        f = H(Object.keys(a));
        for (k = f.next(); !k.done; k = f.next())
          if (((m = k.value), (n = g.g.inputs[m]))) {
            a: {
              var l = g;
              var r = a[m];
              switch (n.type) {
                case 'video':
                  var t = l.m[n.stream];
                  t || ((t = new Ba(l.h, l.C, l.B)), (l.m[n.stream] = t));
                  l = t;
                  0 === l.l && (l.l = l.h.createTexture());
                  if (
                    'undefined' !== typeof HTMLVideoElement &&
                    r instanceof HTMLVideoElement
                  ) {
                    var v = r.videoWidth;
                    t = r.videoHeight;
                  } else
                    'undefined' !== typeof HTMLImageElement &&
                    r instanceof HTMLImageElement
                      ? ((v = r.naturalWidth), (t = r.naturalHeight))
                      : ((v = r.width), (t = r.height));
                  t = { glName: l.l, width: v, height: t };
                  v = l.g;
                  v.canvas.width = t.width;
                  v.canvas.height = t.height;
                  v.activeTexture(v.TEXTURE0);
                  l.h.bindTexture2d(l.l);
                  v.texImage2D(
                    v.TEXTURE_2D,
                    0,
                    v.RGBA,
                    v.RGBA,
                    v.UNSIGNED_BYTE,
                    r,
                  );
                  l.h.bindTexture2d(0);
                  l = t;
                  break a;
                case 'detections':
                  t = l.m[n.stream];
                  t || ((t = new Ea(l.h)), (l.m[n.stream] = t));
                  l = t;
                  l.data || (l.data = new l.g.DetectionListData());
                  l.data.reset(r.length);
                  for (t = 0; t < r.length; ++t) {
                    v = r[t];
                    l.data.setBoundingBox(t, v.R);
                    if (v.M)
                      for (var y = 0; y < v.M.length; ++y) {
                        var z = v.M[y],
                          B = z.visibility ? !0 : !1;
                        l.data.addNormalizedLandmark(
                          t,
                          Object.assign(Object.assign({}, z), {
                            hasVisibility: B,
                            visibility: B ? z.visibility : 0,
                          }),
                        );
                      }
                    if (v.L)
                      for (y = 0; y < v.L.length; ++y) {
                        z = v.L[y];
                        B = z.index ? !0 : !1;
                        var L = z.label ? !0 : !1,
                          P = z.displayName ? !0 : !1;
                        l.data.addClassification(t, {
                          score: z.score,
                          hasIndex: B,
                          index: B ? z.index : -1,
                          hasLabel: L,
                          label: L ? z.label : '',
                          hasDisplayName: P,
                          displayName: P ? z.displayName : '',
                        });
                      }
                  }
                  l = l.data;
                  break a;
                default:
                  l = {};
              }
            }
            w = l;
            u = n.stream;
            switch (n.type) {
              case 'video':
                e.pushTexture2d(
                  Object.assign(Object.assign({}, w), {
                    stream: u,
                    timestamp: h,
                  }),
                );
                break;
              case 'detections':
                p = w;
                p.stream = u;
                p.timestamp = h;
                e.pushDetectionList(p);
                break;
              default:
                throw Error("Unknown input config type: '" + n.type + "'");
            }
          }
        g.i.send(e);
        e.delete();
        q.g = 0;
      });
    });
  };

  function Ta(a, c, b) {
    return Z(a, function g() {
      var h,
        e,
        f,
        k,
        m,
        n,
        w = this,
        u,
        p,
        q,
        l,
        r,
        t,
        v,
        y,
        z,
        B,
        L,
        P,
        va,
        wa;
      return X(g, function (C) {
        switch (C.g) {
          case 1:
            if (!b) return C.return(c);
            h = {};
            e = 0;
            f = H(Object.keys(b));
            for (k = f.next(); !k.done; k = f.next())
              (m = k.value),
                (n = b[m]),
                'string' !== typeof n && 'texture' === n.type && ++e;
            1 < e && (w.B = !1);
            u = H(Object.keys(b));
            k = u.next();
          case 2:
            if (k.done) {
              C.g = 4;
              break;
            }
            p = k.value;
            q = b[p];
            if ('string' === typeof q)
              return (va = h), (wa = p), U(C, Ua(w, p, c[q]), 17);
            l = c[q.stream];
            if (void 0 === l) {
              C.g = 3;
              break;
            }
            if ('detection_list' === q.type) {
              var x = l.getRectList(),
                I = l.getLandmarksList(),
                G = l.getClassificationsList(),
                K = [];
              if (x)
                for (var Q = 0; Q < x.size(); ++Q) {
                  var ea = { R: x.get(Q), M: Ja(I.get(Q)), L: Ka(G.get(Q)) };
                  K.push(ea);
                }
              h[p] = K;
              C.g = 7;
              break;
            }
            if ('proto' === q.type) {
              h[p] = l.isProto() ? l.getProto() : void 0;
              C.g = 7;
              break;
            }
            if ('proto_list' === q.type) {
              if ((r = l.getProtoList())) {
                x = Array(r.size());
                for (I = 0; I < r.size(); I++) x[I] = r.get(I);
                r.delete();
              } else x = void 0;
              h[p] = x;
              C.g = 7;
              break;
            }
            if ('landmarks' === q.type) {
              t = l.getLandmarks();
              h[p] = t ? Ja(t) : void 0;
              C.g = 7;
              break;
            }
            if ('landmarks_list' === q.type) {
              if ((v = l.getLandmarksList()))
                for (x = [], I = v.size(), G = 0; G < I; ++G)
                  (K = v.get(G)), x.push(Ja(K)), K.delete();
              else x = void 0;
              h[p] = x;
              C.g = 7;
              break;
            }
            if ('rect_list' === q.type) {
              if ((y = l.getRectList()))
                for (x = [], I = y.size(), G = 0; G < I; ++G)
                  (K = y.get(G)), x.push(K);
              else x = void 0;
              h[p] = x;
              C.g = 7;
              break;
            }
            if ('classifications_list' === q.type) {
              if ((z = l.getClassificationsList()))
                for (x = [], I = z.size(), G = 0; G < I; ++G)
                  (K = z.get(G)), x.push(Ka(K));
              else x = void 0;
              h[p] = x;
              C.g = 7;
              break;
            }
            if ('object_detection_list' === q.type) {
              if ((B = l.getObjectDetectionList()))
                for (x = [], I = B.size(), G = 0; G < I; ++G) {
                  K = B.get(G);
                  Q = x;
                  ea = Q.push;
                  for (
                    var Wa = K.id,
                      xa = K.keypoints,
                      ya = [],
                      Xa = xa.size(),
                      fa = 0;
                    fa < Xa;
                    ++fa
                  ) {
                    var R = xa.get(fa);
                    ya.push({
                      id: R.id,
                      point3d: {
                        x: R.point3d.x,
                        y: R.point3d.y,
                        z: R.point3d.z,
                      },
                      point2d: {
                        x: R.point2d.x,
                        y: R.point2d.y,
                        depth: R.point2d.depth,
                      },
                    });
                  }
                  ea.call(Q, {
                    id: Wa,
                    keypoints: ya,
                    visibility: K.visibility,
                  });
                }
              else x = void 0;
              h[p] = x;
              C.g = 7;
              break;
            }
            if ('texture' !== q.type)
              throw Error("Unknown output config type: '" + q.type + "'");
            L = w.s[p];
            L || ((L = new Ba(w.h, w.C, w.B)), (w.s[p] = L));
            x = L;
            I = l.getTexture2d();
            Da(x, I);
            x = Ca(x);
            return U(C, x, 16);
          case 16:
            (P = C.h), (h[p] = P);
          case 7:
            q.transform && h[p] && (h[p] = q.transform(h[p]));
            C.g = 3;
            break;
          case 17:
            va[wa] = C.h;
          case 3:
            k = u.next();
            C.g = 2;
            break;
          case 4:
            return C.return(h);
        }
      });
    });
  }

  function Ua(a, c, b) {
    return Z(a, function g() {
      var h = this,
        e;
      return X(g, function (f) {
        if (b.isProto()) return f.return(b.getProto());
        if (b.isProtoList()) return f.return(b.getProtoList());
        if (b.isNumber()) return f.return(b.getNumber());
        if (b.isRect()) return f.return(b.getRect());
        if (b.isLandmarks()) return f.return(b.getLandmarks());
        if (b.isLandmarksList()) return f.return(b.getLandmarksList());
        if (b.isClassificationsList())
          return f.return(b.getClassificationsList());
        if (b.isObjectDetectionList())
          return f.return(b.getObjectDetectionList());
        if (b.isTexture2d()) {
          e = h.s[c];
          e || ((e = new Ba(h.h, h.C, h.B)), (h.s[c] = e));
          var k = f.return;
          var m = e;
          var n = b.getTexture2d();
          Da(m, n);
          m = Ca(m);
          return k.call(f, m);
        }
        return f.return(void 0);
      });
    });
  }

  function Ra(a, c) {
    for (
      var b = c.name || '$',
        d = [].concat(J(c.wants)),
        g = new a.h.StringList(),
        h = H(c.wants),
        e = h.next();
      !e.done;
      e = h.next()
    )
      g.push_back(e.value);
    h = a.h.PacketListener.implement({
      onResults: function (f) {
        return Z(a, function m() {
          var n = this,
            w,
            u,
            p,
            q,
            l;
          return X(m, function (r) {
            w = n;
            u = {};
            for (p = 0; p < c.wants.length; ++p) u[d[p]] = f.get(p);
            q = Ta(n, u, c.outs);
            if ((l = n.listeners[b]))
              return r.return(
                n.F.then(function () {
                  return q;
                }).then(function (t) {
                  return Z(w, function y() {
                    var z,
                      B,
                      L = this;
                    return X(y, function (P) {
                      z = l(t);
                      for (B = 0; B < c.wants.length; ++B) u[d[B]].delete();
                      if (z) return (L.F = z), P.return(z);
                      P.g = 0;
                    });
                  });
                }),
              );
            r.g = 0;
          });
        });
      },
    });
    a.i.attachMultiListener(g, h);
    g.delete();
  }
  A.onResults = function (a, c) {
    this.listeners[c || '$'] = a;
  };
  Y('Solution', Ma);
  Y('OptionType', {
    BOOL: 0,
    NUMBER: 1,
    U: 2,
    0: 'BOOL',
    1: 'NUMBER',
    2: 'STRING',
  });

  function Va(a) {
    var c = this;
    a = a || {};
    this.g = new Ma({
      locateFile: a.locateFile,
      files: [
        { url: 'holistic_solution_packed_assets_loader.js' },
        { simd: !1, url: 'holistic_solution_wasm_bin.js' },
        { simd: !0, url: 'holistic_solution_simd_wasm_bin.js' },
      ],
      graph: { url: 'holistic.binarypb' },
      inputs: { image: { type: 'video', stream: 'input_frames_gpu' } },
      listeners: [
        {
          wants: 'left_hand_landmarks right_hand_landmarks face_landmarks pose_landmarks world_landmarks image_transformed'.split(
            ' ',
          ),
          outs: {
            image: 'image_transformed',
            leftHandLandmarks: {
              type: 'landmarks',
              stream: 'left_hand_landmarks',
            },
            rightHandLandmarks: {
              type: 'landmarks',
              stream: 'right_hand_landmarks',
            },
            faceLandmarks: { type: 'landmarks', stream: 'face_landmarks' },
            poseLandmarks: { type: 'landmarks', stream: 'pose_landmarks' },
            X: { type: 'landmarks', stream: 'world_landmarks' },
          },
        },
      ],
      options: {
        useCpuInference: {
          type: 0,
          graphOptionXref: {
            calculatorType: 'InferenceCalculator',
            fieldName: 'use_cpu_inference',
          },
          default:
            'iPad Simulator;iPhone Simulator;iPod Simulator;iPad;iPhone;iPod'
              .split(';')
              .includes(navigator.platform) ||
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document),
        },
        selfieMode: {
          type: 0,
          graphOptionXref: {
            calculatorType: 'GlScalerCalculator',
            calculatorIndex: 1,
            fieldName: 'flip_horizontal',
          },
        },
        modelComplexity: {
          type: 1,
          default: 1,
          graphOptionXref: {
            calculatorType: 'ConstantSidePacketCalculator',
            calculatorName: 'ConstantSidePacketCalculatorModelComplexity',
            fieldName: 'int_value',
          },
          onChange: function (b) {
            return Z(c, function g() {
              var h,
                e,
                f = this,
                k,
                m;
              return X(g, function (n) {
                if (1 == n.g) {
                  h = '';
                  switch (b) {
                    case 1:
                      h = 'pose_landmark_full.tflite';
                      break;
                    case 2:
                      h = 'pose_landmark_heavy.tflite';
                      break;
                    default:
                      h = 'pose_landmark_lite.tflite';
                  }
                  e = 'third_party/mediapipe/modules/pose_landmark/' + h;
                  k = f.g.locateFile(h, '');
                  return U(n, fetch(k), 3);
                }
                if (2 != n.g) return U(n, n.h.arrayBuffer(), 2);
                m = n.h;
                f.g.overrideFile(e, m);
                return U(n, f.g.reset(), 0);
              });
            });
          },
        },
        smoothLandmarks: {
          type: 0,
          graphOptionXref: {
            calculatorType: 'ConstantSidePacketCalculator',
            calculatorName: 'ConstantSidePacketCalculatorSmoothLandmarks',
            fieldName: 'bool_value',
          },
        },
        minDetectionConfidence: {
          type: 1,
          graphOptionXref: {
            calculatorType: 'TensorsToDetectionsCalculator',
            calculatorName:
              'holisticlandmarkgpu__poselandmarkgpu__posedetectiongpu__TensorsToDetectionsCalculator',
            fieldName: 'min_score_thresh',
          },
        },

        minTrackingConfidence: {
          type: 1,
          graphOptionXref: {
            calculatorType: 'ThresholdingCalculator',
            calculatorName:
              'holisticlandmarkgpu__poselandmarkgpu__poselandmarkbyroigpu__poselandmarkbyroipostprocessing__ThresholdingCalculator',
            fieldName: 'threshold',
          },
        },

        hand1Visibility: {
          type: 1,
          graphOptionXref: {
            calculatorType: 'ThresholdingCalculator',
            calculatorName:
              'holisticlandmarkgpu__handlandmarksleftandrightgpu__handlandmarksfromposegpu_1__handvisibilityfromhandlandmarksfrompose__ThresholdingCalculator',
            fieldName: 'threshold',
          },
        },

        hand2Visibility: {
          type: 1,
          graphOptionXref: {
            calculatorType: 'ThresholdingCalculator',
            calculatorName:
              'holisticlandmarkgpu__handlandmarksleftandrightgpu__handlandmarksfromposegpu_2__handvisibilityfromhandlandmarksfrompose__ThresholdingCalculator',
            fieldName: 'threshold',
          },
        },
      },
    });
  }
  A = Va.prototype;
  A.close = function () {
    this.g.close();
    return Promise.resolve();
  };
  A.onResults = function (a) {
    this.g.onResults(a);
  };
  A.initialize = function () {
    return Z(this, function c() {
      var b = this;
      return X(c, function (d) {
        return U(d, b.g.initialize(), 0);
      });
    });
  };
  A.reset = function () {
    this.g.reset();
  };
  A.send = function (a) {
    return Z(this, function b() {
      var d = this;
      return X(b, function (g) {
        return U(g, d.g.send(a), 0);
      });
    });
  };
  A.setOptions = function (a) {
    this.g.setOptions(a);
  };
  Y('Holistic', Va);
  Y('FACEMESH_LIPS', pa);
  Y('FACEMESH_LEFT_EYE', qa);
  Y('FACEMESH_LEFT_EYEBROW', ra);
  Y('FACEMESH_RIGHT_EYE', sa);
  Y('FACEMESH_RIGHT_EYEBROW', ta);
  Y('FACEMESH_FACE_OVAL', ua);
  Y('FACEMESH_CONTOURS', za);
  Y('FACEMESH_TESSELATION', [
    [127, 34],
    [34, 139],
    [139, 127],
    [11, 0],
    [0, 37],
    [37, 11],
    [232, 231],
    [231, 120],
    [120, 232],
    [72, 37],
    [37, 39],
    [39, 72],
    [128, 121],
    [121, 47],
    [47, 128],
    [232, 121],
    [121, 128],
    [128, 232],
    [104, 69],
    [69, 67],
    [67, 104],
    [175, 171],
    [171, 148],
    [148, 175],
    [118, 50],
    [50, 101],
    [101, 118],
    [73, 39],
    [39, 40],
    [40, 73],
    [9, 151],
    [151, 108],
    [108, 9],
    [48, 115],
    [115, 131],
    [131, 48],
    [194, 204],
    [204, 211],
    [211, 194],
    [74, 40],
    [40, 185],
    [185, 74],
    [80, 42],
    [42, 183],
    [183, 80],
    [40, 92],
    [92, 186],
    [186, 40],
    [230, 229],
    [229, 118],
    [118, 230],
    [202, 212],
    [212, 214],
    [214, 202],
    [83, 18],
    [18, 17],
    [17, 83],
    [76, 61],
    [61, 146],
    [146, 76],
    [160, 29],
    [29, 30],
    [30, 160],
    [56, 157],
    [157, 173],
    [173, 56],
    [106, 204],
    [204, 194],
    [194, 106],
    [135, 214],
    [214, 192],
    [192, 135],
    [203, 165],
    [165, 98],
    [98, 203],
    [21, 71],
    [71, 68],
    [68, 21],
    [51, 45],
    [45, 4],
    [4, 51],
    [144, 24],
    [24, 23],
    [23, 144],
    [77, 146],
    [146, 91],
    [91, 77],
    [205, 50],
    [50, 187],
    [187, 205],
    [201, 200],
    [200, 18],
    [18, 201],
    [91, 106],
    [106, 182],
    [182, 91],
    [90, 91],
    [91, 181],
    [181, 90],
    [85, 84],
    [84, 17],
    [17, 85],
    [206, 203],
    [203, 36],
    [36, 206],
    [148, 171],
    [171, 140],
    [140, 148],
    [92, 40],
    [40, 39],
    [39, 92],
    [193, 189],
    [189, 244],
    [244, 193],
    [159, 158],
    [158, 28],
    [28, 159],
    [247, 246],
    [246, 161],
    [161, 247],
    [236, 3],
    [3, 196],
    [196, 236],
    [54, 68],
    [68, 104],
    [104, 54],
    [193, 168],
    [168, 8],
    [8, 193],
    [117, 228],
    [228, 31],
    [31, 117],
    [189, 193],
    [193, 55],
    [55, 189],
    [98, 97],
    [97, 99],
    [99, 98],
    [126, 47],
    [47, 100],
    [100, 126],
    [166, 79],
    [79, 218],
    [218, 166],
    [155, 154],
    [154, 26],
    [26, 155],
    [209, 49],
    [49, 131],
    [131, 209],
    [135, 136],
    [136, 150],
    [150, 135],
    [47, 126],
    [126, 217],
    [217, 47],
    [223, 52],
    [52, 53],
    [53, 223],
    [45, 51],
    [51, 134],
    [134, 45],
    [211, 170],
    [170, 140],
    [140, 211],
    [67, 69],
    [69, 108],
    [108, 67],
    [43, 106],
    [106, 91],
    [91, 43],
    [230, 119],
    [119, 120],
    [120, 230],
    [226, 130],
    [130, 247],
    [247, 226],
    [63, 53],
    [53, 52],
    [52, 63],
    [238, 20],
    [20, 242],
    [242, 238],
    [46, 70],
    [70, 156],
    [156, 46],
    [78, 62],
    [62, 96],
    [96, 78],
    [46, 53],
    [53, 63],
    [63, 46],
    [143, 34],
    [34, 227],
    [227, 143],
    [123, 117],
    [117, 111],
    [111, 123],
    [44, 125],
    [125, 19],
    [19, 44],
    [236, 134],
    [134, 51],
    [51, 236],
    [216, 206],
    [206, 205],
    [205, 216],
    [154, 153],
    [153, 22],
    [22, 154],
    [39, 37],
    [37, 167],
    [167, 39],
    [200, 201],
    [201, 208],
    [208, 200],
    [36, 142],
    [142, 100],
    [100, 36],
    [57, 212],
    [212, 202],
    [202, 57],
    [20, 60],
    [60, 99],
    [99, 20],
    [28, 158],
    [158, 157],
    [157, 28],
    [35, 226],
    [226, 113],
    [113, 35],
    [160, 159],
    [159, 27],
    [27, 160],
    [204, 202],
    [202, 210],
    [210, 204],
    [113, 225],
    [225, 46],
    [46, 113],
    [43, 202],
    [202, 204],
    [204, 43],
    [62, 76],
    [76, 77],
    [77, 62],
    [137, 123],
    [123, 116],
    [116, 137],
    [41, 38],
    [38, 72],
    [72, 41],
    [203, 129],
    [129, 142],
    [142, 203],
    [64, 98],
    [98, 240],
    [240, 64],
    [49, 102],
    [102, 64],
    [64, 49],
    [41, 73],
    [73, 74],
    [74, 41],
    [212, 216],
    [216, 207],
    [207, 212],
    [42, 74],
    [74, 184],
    [184, 42],
    [169, 170],
    [170, 211],
    [211, 169],
    [170, 149],
    [149, 176],
    [176, 170],
    [105, 66],
    [66, 69],
    [69, 105],
    [122, 6],
    [6, 168],
    [168, 122],
    [123, 147],
    [147, 187],
    [187, 123],
    [96, 77],
    [77, 90],
    [90, 96],
    [65, 55],
    [55, 107],
    [107, 65],
    [89, 90],
    [90, 180],
    [180, 89],
    [101, 100],
    [100, 120],
    [120, 101],
    [63, 105],
    [105, 104],
    [104, 63],
    [93, 137],
    [137, 227],
    [227, 93],
    [15, 86],
    [86, 85],
    [85, 15],
    [129, 102],
    [102, 49],
    [49, 129],
    [14, 87],
    [87, 86],
    [86, 14],
    [55, 8],
    [8, 9],
    [9, 55],
    [100, 47],
    [47, 121],
    [121, 100],
    [145, 23],
    [23, 22],
    [22, 145],
    [88, 89],
    [89, 179],
    [179, 88],
    [6, 122],
    [122, 196],
    [196, 6],
    [88, 95],
    [95, 96],
    [96, 88],
    [138, 172],
    [172, 136],
    [136, 138],
    [215, 58],
    [58, 172],
    [172, 215],
    [115, 48],
    [48, 219],
    [219, 115],
    [42, 80],
    [80, 81],
    [81, 42],
    [195, 3],
    [3, 51],
    [51, 195],
    [43, 146],
    [146, 61],
    [61, 43],
    [171, 175],
    [175, 199],
    [199, 171],
    [81, 82],
    [82, 38],
    [38, 81],
    [53, 46],
    [46, 225],
    [225, 53],
    [144, 163],
    [163, 110],
    [110, 144],
    [52, 65],
    [65, 66],
    [66, 52],
    [229, 228],
    [228, 117],
    [117, 229],
    [34, 127],
    [127, 234],
    [234, 34],
    [107, 108],
    [108, 69],
    [69, 107],
    [109, 108],
    [108, 151],
    [151, 109],
    [48, 64],
    [64, 235],
    [235, 48],
    [62, 78],
    [78, 191],
    [191, 62],
    [129, 209],
    [209, 126],
    [126, 129],
    [111, 35],
    [35, 143],
    [143, 111],
    [117, 123],
    [123, 50],
    [50, 117],
    [222, 65],
    [65, 52],
    [52, 222],
    [19, 125],
    [125, 141],
    [141, 19],
    [221, 55],
    [55, 65],
    [65, 221],
    [3, 195],
    [195, 197],
    [197, 3],
    [25, 7],
    [7, 33],
    [33, 25],
    [220, 237],
    [237, 44],
    [44, 220],
    [70, 71],
    [71, 139],
    [139, 70],
    [122, 193],
    [193, 245],
    [245, 122],
    [247, 130],
    [130, 33],
    [33, 247],
    [71, 21],
    [21, 162],
    [162, 71],
    [170, 169],
    [169, 150],
    [150, 170],
    [188, 174],
    [174, 196],
    [196, 188],
    [216, 186],
    [186, 92],
    [92, 216],
    [2, 97],
    [97, 167],
    [167, 2],
    [141, 125],
    [125, 241],
    [241, 141],
    [164, 167],
    [167, 37],
    [37, 164],
    [72, 38],
    [38, 12],
    [12, 72],
    [38, 82],
    [82, 13],
    [13, 38],
    [63, 68],
    [68, 71],
    [71, 63],
    [226, 35],
    [35, 111],
    [111, 226],
    [101, 50],
    [50, 205],
    [205, 101],
    [206, 92],
    [92, 165],
    [165, 206],
    [209, 198],
    [198, 217],
    [217, 209],
    [165, 167],
    [167, 97],
    [97, 165],
    [220, 115],
    [115, 218],
    [218, 220],
    [133, 112],
    [112, 243],
    [243, 133],
    [239, 238],
    [238, 241],
    [241, 239],
    [214, 135],
    [135, 169],
    [169, 214],
    [190, 173],
    [173, 133],
    [133, 190],
    [171, 208],
    [208, 32],
    [32, 171],
    [125, 44],
    [44, 237],
    [237, 125],
    [86, 87],
    [87, 178],
    [178, 86],
    [85, 86],
    [86, 179],
    [179, 85],
    [84, 85],
    [85, 180],
    [180, 84],
    [83, 84],
    [84, 181],
    [181, 83],
    [201, 83],
    [83, 182],
    [182, 201],
    [137, 93],
    [93, 132],
    [132, 137],
    [76, 62],
    [62, 183],
    [183, 76],
    [61, 76],
    [76, 184],
    [184, 61],
    [57, 61],
    [61, 185],
    [185, 57],
    [212, 57],
    [57, 186],
    [186, 212],
    [214, 207],
    [207, 187],
    [187, 214],
    [34, 143],
    [143, 156],
    [156, 34],
    [79, 239],
    [239, 237],
    [237, 79],
    [123, 137],
    [137, 177],
    [177, 123],
    [44, 1],
    [1, 4],
    [4, 44],
    [201, 194],
    [194, 32],
    [32, 201],
    [64, 102],
    [102, 129],
    [129, 64],
    [213, 215],
    [215, 138],
    [138, 213],
    [59, 166],
    [166, 219],
    [219, 59],
    [242, 99],
    [99, 97],
    [97, 242],
    [2, 94],
    [94, 141],
    [141, 2],
    [75, 59],
    [59, 235],
    [235, 75],
    [24, 110],
    [110, 228],
    [228, 24],
    [25, 130],
    [130, 226],
    [226, 25],
    [23, 24],
    [24, 229],
    [229, 23],
    [22, 23],
    [23, 230],
    [230, 22],
    [26, 22],
    [22, 231],
    [231, 26],
    [112, 26],
    [26, 232],
    [232, 112],
    [189, 190],
    [190, 243],
    [243, 189],
    [221, 56],
    [56, 190],
    [190, 221],
    [28, 56],
    [56, 221],
    [221, 28],
    [27, 28],
    [28, 222],
    [222, 27],
    [29, 27],
    [27, 223],
    [223, 29],
    [30, 29],
    [29, 224],
    [224, 30],
    [247, 30],
    [30, 225],
    [225, 247],
    [238, 79],
    [79, 20],
    [20, 238],
    [166, 59],
    [59, 75],
    [75, 166],
    [60, 75],
    [75, 240],
    [240, 60],
    [147, 177],
    [177, 215],
    [215, 147],
    [20, 79],
    [79, 166],
    [166, 20],
    [187, 147],
    [147, 213],
    [213, 187],
    [112, 233],
    [233, 244],
    [244, 112],
    [233, 128],
    [128, 245],
    [245, 233],
    [128, 114],
    [114, 188],
    [188, 128],
    [114, 217],
    [217, 174],
    [174, 114],
    [131, 115],
    [115, 220],
    [220, 131],
    [217, 198],
    [198, 236],
    [236, 217],
    [198, 131],
    [131, 134],
    [134, 198],
    [177, 132],
    [132, 58],
    [58, 177],
    [143, 35],
    [35, 124],
    [124, 143],
    [110, 163],
    [163, 7],
    [7, 110],
    [228, 110],
    [110, 25],
    [25, 228],
    [356, 389],
    [389, 368],
    [368, 356],
    [11, 302],
    [302, 267],
    [267, 11],
    [452, 350],
    [350, 349],
    [349, 452],
    [302, 303],
    [303, 269],
    [269, 302],
    [357, 343],
    [343, 277],
    [277, 357],
    [452, 453],
    [453, 357],
    [357, 452],
    [333, 332],
    [332, 297],
    [297, 333],
    [175, 152],
    [152, 377],
    [377, 175],
    [347, 348],
    [348, 330],
    [330, 347],
    [303, 304],
    [304, 270],
    [270, 303],
    [9, 336],
    [336, 337],
    [337, 9],
    [278, 279],
    [279, 360],
    [360, 278],
    [418, 262],
    [262, 431],
    [431, 418],
    [304, 408],
    [408, 409],
    [409, 304],
    [310, 415],
    [415, 407],
    [407, 310],
    [270, 409],
    [409, 410],
    [410, 270],
    [450, 348],
    [348, 347],
    [347, 450],
    [422, 430],
    [430, 434],
    [434, 422],
    [313, 314],
    [314, 17],
    [17, 313],
    [306, 307],
    [307, 375],
    [375, 306],
    [387, 388],
    [388, 260],
    [260, 387],
    [286, 414],
    [414, 398],
    [398, 286],
    [335, 406],
    [406, 418],
    [418, 335],
    [364, 367],
    [367, 416],
    [416, 364],
    [423, 358],
    [358, 327],
    [327, 423],
    [251, 284],
    [284, 298],
    [298, 251],
    [281, 5],
    [5, 4],
    [4, 281],
    [373, 374],
    [374, 253],
    [253, 373],
    [307, 320],
    [320, 321],
    [321, 307],
    [425, 427],
    [427, 411],
    [411, 425],
    [421, 313],
    [313, 18],
    [18, 421],
    [321, 405],
    [405, 406],
    [406, 321],
    [320, 404],
    [404, 405],
    [405, 320],
    [315, 16],
    [16, 17],
    [17, 315],
    [426, 425],
    [425, 266],
    [266, 426],
    [377, 400],
    [400, 369],
    [369, 377],
    [322, 391],
    [391, 269],
    [269, 322],
    [417, 465],
    [465, 464],
    [464, 417],
    [386, 257],
    [257, 258],
    [258, 386],
    [466, 260],
    [260, 388],
    [388, 466],
    [456, 399],
    [399, 419],
    [419, 456],
    [284, 332],
    [332, 333],
    [333, 284],
    [417, 285],
    [285, 8],
    [8, 417],
    [346, 340],
    [340, 261],
    [261, 346],
    [413, 441],
    [441, 285],
    [285, 413],
    [327, 460],
    [460, 328],
    [328, 327],
    [355, 371],
    [371, 329],
    [329, 355],
    [392, 439],
    [439, 438],
    [438, 392],
    [382, 341],
    [341, 256],
    [256, 382],
    [429, 420],
    [420, 360],
    [360, 429],
    [364, 394],
    [394, 379],
    [379, 364],
    [277, 343],
    [343, 437],
    [437, 277],
    [443, 444],
    [444, 283],
    [283, 443],
    [275, 440],
    [440, 363],
    [363, 275],
    [431, 262],
    [262, 369],
    [369, 431],
    [297, 338],
    [338, 337],
    [337, 297],
    [273, 375],
    [375, 321],
    [321, 273],
    [450, 451],
    [451, 349],
    [349, 450],
    [446, 342],
    [342, 467],
    [467, 446],
    [293, 334],
    [334, 282],
    [282, 293],
    [458, 461],
    [461, 462],
    [462, 458],
    [276, 353],
    [353, 383],
    [383, 276],
    [308, 324],
    [324, 325],
    [325, 308],
    [276, 300],
    [300, 293],
    [293, 276],
    [372, 345],
    [345, 447],
    [447, 372],
    [352, 345],
    [345, 340],
    [340, 352],
    [274, 1],
    [1, 19],
    [19, 274],
    [456, 248],
    [248, 281],
    [281, 456],
    [436, 427],
    [427, 425],
    [425, 436],
    [381, 256],
    [256, 252],
    [252, 381],
    [269, 391],
    [391, 393],
    [393, 269],
    [200, 199],
    [199, 428],
    [428, 200],
    [266, 330],
    [330, 329],
    [329, 266],
    [287, 273],
    [273, 422],
    [422, 287],
    [250, 462],
    [462, 328],
    [328, 250],
    [258, 286],
    [286, 384],
    [384, 258],
    [265, 353],
    [353, 342],
    [342, 265],
    [387, 259],
    [259, 257],
    [257, 387],
    [424, 431],
    [431, 430],
    [430, 424],
    [342, 353],
    [353, 276],
    [276, 342],
    [273, 335],
    [335, 424],
    [424, 273],
    [292, 325],
    [325, 307],
    [307, 292],
    [366, 447],
    [447, 345],
    [345, 366],
    [271, 303],
    [303, 302],
    [302, 271],
    [423, 266],
    [266, 371],
    [371, 423],
    [294, 455],
    [455, 460],
    [460, 294],
    [279, 278],
    [278, 294],
    [294, 279],
    [271, 272],
    [272, 304],
    [304, 271],
    [432, 434],
    [434, 427],
    [427, 432],
    [272, 407],
    [407, 408],
    [408, 272],
    [394, 430],
    [430, 431],
    [431, 394],
    [395, 369],
    [369, 400],
    [400, 395],
    [334, 333],
    [333, 299],
    [299, 334],
    [351, 417],
    [417, 168],
    [168, 351],
    [352, 280],
    [280, 411],
    [411, 352],
    [325, 319],
    [319, 320],
    [320, 325],
    [295, 296],
    [296, 336],
    [336, 295],
    [319, 403],
    [403, 404],
    [404, 319],
    [330, 348],
    [348, 349],
    [349, 330],
    [293, 298],
    [298, 333],
    [333, 293],
    [323, 454],
    [454, 447],
    [447, 323],
    [15, 16],
    [16, 315],
    [315, 15],
    [358, 429],
    [429, 279],
    [279, 358],
    [14, 15],
    [15, 316],
    [316, 14],
    [285, 336],
    [336, 9],
    [9, 285],
    [329, 349],
    [349, 350],
    [350, 329],
    [374, 380],
    [380, 252],
    [252, 374],
    [318, 402],
    [402, 403],
    [403, 318],
    [6, 197],
    [197, 419],
    [419, 6],
    [318, 319],
    [319, 325],
    [325, 318],
    [367, 364],
    [364, 365],
    [365, 367],
    [435, 367],
    [367, 397],
    [397, 435],
    [344, 438],
    [438, 439],
    [439, 344],
    [272, 271],
    [271, 311],
    [311, 272],
    [195, 5],
    [5, 281],
    [281, 195],
    [273, 287],
    [287, 291],
    [291, 273],
    [396, 428],
    [428, 199],
    [199, 396],
    [311, 271],
    [271, 268],
    [268, 311],
    [283, 444],
    [444, 445],
    [445, 283],
    [373, 254],
    [254, 339],
    [339, 373],
    [282, 334],
    [334, 296],
    [296, 282],
    [449, 347],
    [347, 346],
    [346, 449],
    [264, 447],
    [447, 454],
    [454, 264],
    [336, 296],
    [296, 299],
    [299, 336],
    [338, 10],
    [10, 151],
    [151, 338],
    [278, 439],
    [439, 455],
    [455, 278],
    [292, 407],
    [407, 415],
    [415, 292],
    [358, 371],
    [371, 355],
    [355, 358],
    [340, 345],
    [345, 372],
    [372, 340],
    [346, 347],
    [347, 280],
    [280, 346],
    [442, 443],
    [443, 282],
    [282, 442],
    [19, 94],
    [94, 370],
    [370, 19],
    [441, 442],
    [442, 295],
    [295, 441],
    [248, 419],
    [419, 197],
    [197, 248],
    [263, 255],
    [255, 359],
    [359, 263],
    [440, 275],
    [275, 274],
    [274, 440],
    [300, 383],
    [383, 368],
    [368, 300],
    [351, 412],
    [412, 465],
    [465, 351],
    [263, 467],
    [467, 466],
    [466, 263],
    [301, 368],
    [368, 389],
    [389, 301],
    [395, 378],
    [378, 379],
    [379, 395],
    [412, 351],
    [351, 419],
    [419, 412],
    [436, 426],
    [426, 322],
    [322, 436],
    [2, 164],
    [164, 393],
    [393, 2],
    [370, 462],
    [462, 461],
    [461, 370],
    [164, 0],
    [0, 267],
    [267, 164],
    [302, 11],
    [11, 12],
    [12, 302],
    [268, 12],
    [12, 13],
    [13, 268],
    [293, 300],
    [300, 301],
    [301, 293],
    [446, 261],
    [261, 340],
    [340, 446],
    [330, 266],
    [266, 425],
    [425, 330],
    [426, 423],
    [423, 391],
    [391, 426],
    [429, 355],
    [355, 437],
    [437, 429],
    [391, 327],
    [327, 326],
    [326, 391],
    [440, 457],
    [457, 438],
    [438, 440],
    [341, 382],
    [382, 362],
    [362, 341],
    [459, 457],
    [457, 461],
    [461, 459],
    [434, 430],
    [430, 394],
    [394, 434],
    [414, 463],
    [463, 362],
    [362, 414],
    [396, 369],
    [369, 262],
    [262, 396],
    [354, 461],
    [461, 457],
    [457, 354],
    [316, 403],
    [403, 402],
    [402, 316],
    [315, 404],
    [404, 403],
    [403, 315],
    [314, 405],
    [405, 404],
    [404, 314],
    [313, 406],
    [406, 405],
    [405, 313],
    [421, 418],
    [418, 406],
    [406, 421],
    [366, 401],
    [401, 361],
    [361, 366],
    [306, 408],
    [408, 407],
    [407, 306],
    [291, 409],
    [409, 408],
    [408, 291],
    [287, 410],
    [410, 409],
    [409, 287],
    [432, 436],
    [436, 410],
    [410, 432],
    [434, 416],
    [416, 411],
    [411, 434],
    [264, 368],
    [368, 383],
    [383, 264],
    [309, 438],
    [438, 457],
    [457, 309],
    [352, 376],
    [376, 401],
    [401, 352],
    [274, 275],
    [275, 4],
    [4, 274],
    [421, 428],
    [428, 262],
    [262, 421],
    [294, 327],
    [327, 358],
    [358, 294],
    [433, 416],
    [416, 367],
    [367, 433],
    [289, 455],
    [455, 439],
    [439, 289],
    [462, 370],
    [370, 326],
    [326, 462],
    [2, 326],
    [326, 370],
    [370, 2],
    [305, 460],
    [460, 455],
    [455, 305],
    [254, 449],
    [449, 448],
    [448, 254],
    [255, 261],
    [261, 446],
    [446, 255],
    [253, 450],
    [450, 449],
    [449, 253],
    [252, 451],
    [451, 450],
    [450, 252],
    [256, 452],
    [452, 451],
    [451, 256],
    [341, 453],
    [453, 452],
    [452, 341],
    [413, 464],
    [464, 463],
    [463, 413],
    [441, 413],
    [413, 414],
    [414, 441],
    [258, 442],
    [442, 441],
    [441, 258],
    [257, 443],
    [443, 442],
    [442, 257],
    [259, 444],
    [444, 443],
    [443, 259],
    [260, 445],
    [445, 444],
    [444, 260],
    [467, 342],
    [342, 445],
    [445, 467],
    [459, 458],
    [458, 250],
    [250, 459],
    [289, 392],
    [392, 290],
    [290, 289],
    [290, 328],
    [328, 460],
    [460, 290],
    [376, 433],
    [433, 435],
    [435, 376],
    [250, 290],
    [290, 392],
    [392, 250],
    [411, 416],
    [416, 433],
    [433, 411],
    [341, 463],
    [463, 464],
    [464, 341],
    [453, 464],
    [464, 465],
    [465, 453],
    [357, 465],
    [465, 412],
    [412, 357],
    [343, 412],
    [412, 399],
    [399, 343],
    [360, 363],
    [363, 440],
    [440, 360],
    [437, 399],
    [399, 456],
    [456, 437],
    [420, 456],
    [456, 363],
    [363, 420],
    [401, 435],
    [435, 288],
    [288, 401],
    [372, 383],
    [383, 353],
    [353, 372],
    [339, 255],
    [255, 249],
    [249, 339],
    [448, 261],
    [261, 255],
    [255, 448],
    [133, 243],
    [243, 190],
    [190, 133],
    [133, 155],
    [155, 112],
    [112, 133],
    [33, 246],
    [246, 247],
    [247, 33],
    [33, 130],
    [130, 25],
    [25, 33],
    [398, 384],
    [384, 286],
    [286, 398],
    [362, 398],
    [398, 414],
    [414, 362],
    [362, 463],
    [463, 341],
    [341, 362],
    [263, 359],
    [359, 467],
    [467, 263],
    [263, 249],
    [249, 255],
    [255, 263],
    [466, 467],
    [467, 260],
    [260, 466],
    [75, 60],
    [60, 166],
    [166, 75],
    [238, 239],
    [239, 79],
    [79, 238],
    [162, 127],
    [127, 139],
    [139, 162],
    [72, 11],
    [11, 37],
    [37, 72],
    [121, 232],
    [232, 120],
    [120, 121],
    [73, 72],
    [72, 39],
    [39, 73],
    [114, 128],
    [128, 47],
    [47, 114],
    [233, 232],
    [232, 128],
    [128, 233],
    [103, 104],
    [104, 67],
    [67, 103],
    [152, 175],
    [175, 148],
    [148, 152],
    [119, 118],
    [118, 101],
    [101, 119],
    [74, 73],
    [73, 40],
    [40, 74],
    [107, 9],
    [9, 108],
    [108, 107],
    [49, 48],
    [48, 131],
    [131, 49],
    [32, 194],
    [194, 211],
    [211, 32],
    [184, 74],
    [74, 185],
    [185, 184],
    [191, 80],
    [80, 183],
    [183, 191],
    [185, 40],
    [40, 186],
    [186, 185],
    [119, 230],
    [230, 118],
    [118, 119],
    [210, 202],
    [202, 214],
    [214, 210],
    [84, 83],
    [83, 17],
    [17, 84],
    [77, 76],
    [76, 146],
    [146, 77],
    [161, 160],
    [160, 30],
    [30, 161],
    [190, 56],
    [56, 173],
    [173, 190],
    [182, 106],
    [106, 194],
    [194, 182],
    [138, 135],
    [135, 192],
    [192, 138],
    [129, 203],
    [203, 98],
    [98, 129],
    [54, 21],
    [21, 68],
    [68, 54],
    [5, 51],
    [51, 4],
    [4, 5],
    [145, 144],
    [144, 23],
    [23, 145],
    [90, 77],
    [77, 91],
    [91, 90],
    [207, 205],
    [205, 187],
    [187, 207],
    [83, 201],
    [201, 18],
    [18, 83],
    [181, 91],
    [91, 182],
    [182, 181],
    [180, 90],
    [90, 181],
    [181, 180],
    [16, 85],
    [85, 17],
    [17, 16],
    [205, 206],
    [206, 36],
    [36, 205],
    [176, 148],
    [148, 140],
    [140, 176],
    [165, 92],
    [92, 39],
    [39, 165],
    [245, 193],
    [193, 244],
    [244, 245],
    [27, 159],
    [159, 28],
    [28, 27],
    [30, 247],
    [247, 161],
    [161, 30],
    [174, 236],
    [236, 196],
    [196, 174],
    [103, 54],
    [54, 104],
    [104, 103],
    [55, 193],
    [193, 8],
    [8, 55],
    [111, 117],
    [117, 31],
    [31, 111],
    [221, 189],
    [189, 55],
    [55, 221],
    [240, 98],
    [98, 99],
    [99, 240],
    [142, 126],
    [126, 100],
    [100, 142],
    [219, 166],
    [166, 218],
    [218, 219],
    [112, 155],
    [155, 26],
    [26, 112],
    [198, 209],
    [209, 131],
    [131, 198],
    [169, 135],
    [135, 150],
    [150, 169],
    [114, 47],
    [47, 217],
    [217, 114],
    [224, 223],
    [223, 53],
    [53, 224],
    [220, 45],
    [45, 134],
    [134, 220],
    [32, 211],
    [211, 140],
    [140, 32],
    [109, 67],
    [67, 108],
    [108, 109],
    [146, 43],
    [43, 91],
    [91, 146],
    [231, 230],
    [230, 120],
    [120, 231],
    [113, 226],
    [226, 247],
    [247, 113],
    [105, 63],
    [63, 52],
    [52, 105],
    [241, 238],
    [238, 242],
    [242, 241],
    [124, 46],
    [46, 156],
    [156, 124],
    [95, 78],
    [78, 96],
    [96, 95],
    [70, 46],
    [46, 63],
    [63, 70],
    [116, 143],
    [143, 227],
    [227, 116],
    [116, 123],
    [123, 111],
    [111, 116],
    [1, 44],
    [44, 19],
    [19, 1],
    [3, 236],
    [236, 51],
    [51, 3],
    [207, 216],
    [216, 205],
    [205, 207],
    [26, 154],
    [154, 22],
    [22, 26],
    [165, 39],
    [39, 167],
    [167, 165],
    [199, 200],
    [200, 208],
    [208, 199],
    [101, 36],
    [36, 100],
    [100, 101],
    [43, 57],
    [57, 202],
    [202, 43],
    [242, 20],
    [20, 99],
    [99, 242],
    [56, 28],
    [28, 157],
    [157, 56],
    [124, 35],
    [35, 113],
    [113, 124],
    [29, 160],
    [160, 27],
    [27, 29],
    [211, 204],
    [204, 210],
    [210, 211],
    [124, 113],
    [113, 46],
    [46, 124],
    [106, 43],
    [43, 204],
    [204, 106],
    [96, 62],
    [62, 77],
    [77, 96],
    [227, 137],
    [137, 116],
    [116, 227],
    [73, 41],
    [41, 72],
    [72, 73],
    [36, 203],
    [203, 142],
    [142, 36],
    [235, 64],
    [64, 240],
    [240, 235],
    [48, 49],
    [49, 64],
    [64, 48],
    [42, 41],
    [41, 74],
    [74, 42],
    [214, 212],
    [212, 207],
    [207, 214],
    [183, 42],
    [42, 184],
    [184, 183],
    [210, 169],
    [169, 211],
    [211, 210],
    [140, 170],
    [170, 176],
    [176, 140],
    [104, 105],
    [105, 69],
    [69, 104],
    [193, 122],
    [122, 168],
    [168, 193],
    [50, 123],
    [123, 187],
    [187, 50],
    [89, 96],
    [96, 90],
    [90, 89],
    [66, 65],
    [65, 107],
    [107, 66],
    [179, 89],
    [89, 180],
    [180, 179],
    [119, 101],
    [101, 120],
    [120, 119],
    [68, 63],
    [63, 104],
    [104, 68],
    [234, 93],
    [93, 227],
    [227, 234],
    [16, 15],
    [15, 85],
    [85, 16],
    [209, 129],
    [129, 49],
    [49, 209],
    [15, 14],
    [14, 86],
    [86, 15],
    [107, 55],
    [55, 9],
    [9, 107],
    [120, 100],
    [100, 121],
    [121, 120],
    [153, 145],
    [145, 22],
    [22, 153],
    [178, 88],
    [88, 179],
    [179, 178],
    [197, 6],
    [6, 196],
    [196, 197],
    [89, 88],
    [88, 96],
    [96, 89],
    [135, 138],
    [138, 136],
    [136, 135],
    [138, 215],
    [215, 172],
    [172, 138],
    [218, 115],
    [115, 219],
    [219, 218],
    [41, 42],
    [42, 81],
    [81, 41],
    [5, 195],
    [195, 51],
    [51, 5],
    [57, 43],
    [43, 61],
    [61, 57],
    [208, 171],
    [171, 199],
    [199, 208],
    [41, 81],
    [81, 38],
    [38, 41],
    [224, 53],
    [53, 225],
    [225, 224],
    [24, 144],
    [144, 110],
    [110, 24],
    [105, 52],
    [52, 66],
    [66, 105],
    [118, 229],
    [229, 117],
    [117, 118],
    [227, 34],
    [34, 234],
    [234, 227],
    [66, 107],
    [107, 69],
    [69, 66],
    [10, 109],
    [109, 151],
    [151, 10],
    [219, 48],
    [48, 235],
    [235, 219],
    [183, 62],
    [62, 191],
    [191, 183],
    [142, 129],
    [129, 126],
    [126, 142],
    [116, 111],
    [111, 143],
    [143, 116],
    [118, 117],
    [117, 50],
    [50, 118],
    [223, 222],
    [222, 52],
    [52, 223],
    [94, 19],
    [19, 141],
    [141, 94],
    [222, 221],
    [221, 65],
    [65, 222],
    [196, 3],
    [3, 197],
    [197, 196],
    [45, 220],
    [220, 44],
    [44, 45],
    [156, 70],
    [70, 139],
    [139, 156],
    [188, 122],
    [122, 245],
    [245, 188],
    [139, 71],
    [71, 162],
    [162, 139],
    [149, 170],
    [170, 150],
    [150, 149],
    [122, 188],
    [188, 196],
    [196, 122],
    [206, 216],
    [216, 92],
    [92, 206],
    [164, 2],
    [2, 167],
    [167, 164],
    [242, 141],
    [141, 241],
    [241, 242],
    [0, 164],
    [164, 37],
    [37, 0],
    [11, 72],
    [72, 12],
    [12, 11],
    [12, 38],
    [38, 13],
    [13, 12],
    [70, 63],
    [63, 71],
    [71, 70],
    [31, 226],
    [226, 111],
    [111, 31],
    [36, 101],
    [101, 205],
    [205, 36],
    [203, 206],
    [206, 165],
    [165, 203],
    [126, 209],
    [209, 217],
    [217, 126],
    [98, 165],
    [165, 97],
    [97, 98],
    [237, 220],
    [220, 218],
    [218, 237],
    [237, 239],
    [239, 241],
    [241, 237],
    [210, 214],
    [214, 169],
    [169, 210],
    [140, 171],
    [171, 32],
    [32, 140],
    [241, 125],
    [125, 237],
    [237, 241],
    [179, 86],
    [86, 178],
    [178, 179],
    [180, 85],
    [85, 179],
    [179, 180],
    [181, 84],
    [84, 180],
    [180, 181],
    [182, 83],
    [83, 181],
    [181, 182],
    [194, 201],
    [201, 182],
    [182, 194],
    [177, 137],
    [137, 132],
    [132, 177],
    [184, 76],
    [76, 183],
    [183, 184],
    [185, 61],
    [61, 184],
    [184, 185],
    [186, 57],
    [57, 185],
    [185, 186],
    [216, 212],
    [212, 186],
    [186, 216],
    [192, 214],
    [214, 187],
    [187, 192],
    [139, 34],
    [34, 156],
    [156, 139],
    [218, 79],
    [79, 237],
    [237, 218],
    [147, 123],
    [123, 177],
    [177, 147],
    [45, 44],
    [44, 4],
    [4, 45],
    [208, 201],
    [201, 32],
    [32, 208],
    [98, 64],
    [64, 129],
    [129, 98],
    [192, 213],
    [213, 138],
    [138, 192],
    [235, 59],
    [59, 219],
    [219, 235],
    [141, 242],
    [242, 97],
    [97, 141],
    [97, 2],
    [2, 141],
    [141, 97],
    [240, 75],
    [75, 235],
    [235, 240],
    [229, 24],
    [24, 228],
    [228, 229],
    [31, 25],
    [25, 226],
    [226, 31],
    [230, 23],
    [23, 229],
    [229, 230],
    [231, 22],
    [22, 230],
    [230, 231],
    [232, 26],
    [26, 231],
    [231, 232],
    [233, 112],
    [112, 232],
    [232, 233],
    [244, 189],
    [189, 243],
    [243, 244],
    [189, 221],
    [221, 190],
    [190, 189],
    [222, 28],
    [28, 221],
    [221, 222],
    [223, 27],
    [27, 222],
    [222, 223],
    [224, 29],
    [29, 223],
    [223, 224],
    [225, 30],
    [30, 224],
    [224, 225],
    [113, 247],
    [247, 225],
    [225, 113],
    [99, 60],
    [60, 240],
    [240, 99],
    [213, 147],
    [147, 215],
    [215, 213],
    [60, 20],
    [20, 166],
    [166, 60],
    [192, 187],
    [187, 213],
    [213, 192],
    [243, 112],
    [112, 244],
    [244, 243],
    [244, 233],
    [233, 245],
    [245, 244],
    [245, 128],
    [128, 188],
    [188, 245],
    [188, 114],
    [114, 174],
    [174, 188],
    [134, 131],
    [131, 220],
    [220, 134],
    [174, 217],
    [217, 236],
    [236, 174],
    [236, 198],
    [198, 134],
    [134, 236],
    [215, 177],
    [177, 58],
    [58, 215],
    [156, 143],
    [143, 124],
    [124, 156],
    [25, 110],
    [110, 7],
    [7, 25],
    [31, 228],
    [228, 25],
    [25, 31],
    [264, 356],
    [356, 368],
    [368, 264],
    [0, 11],
    [11, 267],
    [267, 0],
    [451, 452],
    [452, 349],
    [349, 451],
    [267, 302],
    [302, 269],
    [269, 267],
    [350, 357],
    [357, 277],
    [277, 350],
    [350, 452],
    [452, 357],
    [357, 350],
    [299, 333],
    [333, 297],
    [297, 299],
    [396, 175],
    [175, 377],
    [377, 396],
    [280, 347],
    [347, 330],
    [330, 280],
    [269, 303],
    [303, 270],
    [270, 269],
    [151, 9],
    [9, 337],
    [337, 151],
    [344, 278],
    [278, 360],
    [360, 344],
    [424, 418],
    [418, 431],
    [431, 424],
    [270, 304],
    [304, 409],
    [409, 270],
    [272, 310],
    [310, 407],
    [407, 272],
    [322, 270],
    [270, 410],
    [410, 322],
    [449, 450],
    [450, 347],
    [347, 449],
    [432, 422],
    [422, 434],
    [434, 432],
    [18, 313],
    [313, 17],
    [17, 18],
    [291, 306],
    [306, 375],
    [375, 291],
    [259, 387],
    [387, 260],
    [260, 259],
    [424, 335],
    [335, 418],
    [418, 424],
    [434, 364],
    [364, 416],
    [416, 434],
    [391, 423],
    [423, 327],
    [327, 391],
    [301, 251],
    [251, 298],
    [298, 301],
    [275, 281],
    [281, 4],
    [4, 275],
    [254, 373],
    [373, 253],
    [253, 254],
    [375, 307],
    [307, 321],
    [321, 375],
    [280, 425],
    [425, 411],
    [411, 280],
    [200, 421],
    [421, 18],
    [18, 200],
    [335, 321],
    [321, 406],
    [406, 335],
    [321, 320],
    [320, 405],
    [405, 321],
    [314, 315],
    [315, 17],
    [17, 314],
    [423, 426],
    [426, 266],
    [266, 423],
    [396, 377],
    [377, 369],
    [369, 396],
    [270, 322],
    [322, 269],
    [269, 270],
    [413, 417],
    [417, 464],
    [464, 413],
    [385, 386],
    [386, 258],
    [258, 385],
    [248, 456],
    [456, 419],
    [419, 248],
    [298, 284],
    [284, 333],
    [333, 298],
    [168, 417],
    [417, 8],
    [8, 168],
    [448, 346],
    [346, 261],
    [261, 448],
    [417, 413],
    [413, 285],
    [285, 417],
    [326, 327],
    [327, 328],
    [328, 326],
    [277, 355],
    [355, 329],
    [329, 277],
    [309, 392],
    [392, 438],
    [438, 309],
    [381, 382],
    [382, 256],
    [256, 381],
    [279, 429],
    [429, 360],
    [360, 279],
    [365, 364],
    [364, 379],
    [379, 365],
    [355, 277],
    [277, 437],
    [437, 355],
    [282, 443],
    [443, 283],
    [283, 282],
    [281, 275],
    [275, 363],
    [363, 281],
    [395, 431],
    [431, 369],
    [369, 395],
    [299, 297],
    [297, 337],
    [337, 299],
    [335, 273],
    [273, 321],
    [321, 335],
    [348, 450],
    [450, 349],
    [349, 348],
    [359, 446],
    [446, 467],
    [467, 359],
    [283, 293],
    [293, 282],
    [282, 283],
    [250, 458],
    [458, 462],
    [462, 250],
    [300, 276],
    [276, 383],
    [383, 300],
    [292, 308],
    [308, 325],
    [325, 292],
    [283, 276],
    [276, 293],
    [293, 283],
    [264, 372],
    [372, 447],
    [447, 264],
    [346, 352],
    [352, 340],
    [340, 346],
    [354, 274],
    [274, 19],
    [19, 354],
    [363, 456],
    [456, 281],
    [281, 363],
    [426, 436],
    [436, 425],
    [425, 426],
    [380, 381],
    [381, 252],
    [252, 380],
    [267, 269],
    [269, 393],
    [393, 267],
    [421, 200],
    [200, 428],
    [428, 421],
    [371, 266],
    [266, 329],
    [329, 371],
    [432, 287],
    [287, 422],
    [422, 432],
    [290, 250],
    [250, 328],
    [328, 290],
    [385, 258],
    [258, 384],
    [384, 385],
    [446, 265],
    [265, 342],
    [342, 446],
    [386, 387],
    [387, 257],
    [257, 386],
    [422, 424],
    [424, 430],
    [430, 422],
    [445, 342],
    [342, 276],
    [276, 445],
    [422, 273],
    [273, 424],
    [424, 422],
    [306, 292],
    [292, 307],
    [307, 306],
    [352, 366],
    [366, 345],
    [345, 352],
    [268, 271],
    [271, 302],
    [302, 268],
    [358, 423],
    [423, 371],
    [371, 358],
    [327, 294],
    [294, 460],
    [460, 327],
    [331, 279],
    [279, 294],
    [294, 331],
    [303, 271],
    [271, 304],
    [304, 303],
    [436, 432],
    [432, 427],
    [427, 436],
    [304, 272],
    [272, 408],
    [408, 304],
    [395, 394],
    [394, 431],
    [431, 395],
    [378, 395],
    [395, 400],
    [400, 378],
    [296, 334],
    [334, 299],
    [299, 296],
    [6, 351],
    [351, 168],
    [168, 6],
    [376, 352],
    [352, 411],
    [411, 376],
    [307, 325],
    [325, 320],
    [320, 307],
    [285, 295],
    [295, 336],
    [336, 285],
    [320, 319],
    [319, 404],
    [404, 320],
    [329, 330],
    [330, 349],
    [349, 329],
    [334, 293],
    [293, 333],
    [333, 334],
    [366, 323],
    [323, 447],
    [447, 366],
    [316, 15],
    [15, 315],
    [315, 316],
    [331, 358],
    [358, 279],
    [279, 331],
    [317, 14],
    [14, 316],
    [316, 317],
    [8, 285],
    [285, 9],
    [9, 8],
    [277, 329],
    [329, 350],
    [350, 277],
    [253, 374],
    [374, 252],
    [252, 253],
    [319, 318],
    [318, 403],
    [403, 319],
    [351, 6],
    [6, 419],
    [419, 351],
    [324, 318],
    [318, 325],
    [325, 324],
    [397, 367],
    [367, 365],
    [365, 397],
    [288, 435],
    [435, 397],
    [397, 288],
    [278, 344],
    [344, 439],
    [439, 278],
    [310, 272],
    [272, 311],
    [311, 310],
    [248, 195],
    [195, 281],
    [281, 248],
    [375, 273],
    [273, 291],
    [291, 375],
    [175, 396],
    [396, 199],
    [199, 175],
    [312, 311],
    [311, 268],
    [268, 312],
    [276, 283],
    [283, 445],
    [445, 276],
    [390, 373],
    [373, 339],
    [339, 390],
    [295, 282],
    [282, 296],
    [296, 295],
    [448, 449],
    [449, 346],
    [346, 448],
    [356, 264],
    [264, 454],
    [454, 356],
    [337, 336],
    [336, 299],
    [299, 337],
    [337, 338],
    [338, 151],
    [151, 337],
    [294, 278],
    [278, 455],
    [455, 294],
    [308, 292],
    [292, 415],
    [415, 308],
    [429, 358],
    [358, 355],
    [355, 429],
    [265, 340],
    [340, 372],
    [372, 265],
    [352, 346],
    [346, 280],
    [280, 352],
    [295, 442],
    [442, 282],
    [282, 295],
    [354, 19],
    [19, 370],
    [370, 354],
    [285, 441],
    [441, 295],
    [295, 285],
    [195, 248],
    [248, 197],
    [197, 195],
    [457, 440],
    [440, 274],
    [274, 457],
    [301, 300],
    [300, 368],
    [368, 301],
    [417, 351],
    [351, 465],
    [465, 417],
    [251, 301],
    [301, 389],
    [389, 251],
    [394, 395],
    [395, 379],
    [379, 394],
    [399, 412],
    [412, 419],
    [419, 399],
    [410, 436],
    [436, 322],
    [322, 410],
    [326, 2],
    [2, 393],
    [393, 326],
    [354, 370],
    [370, 461],
    [461, 354],
    [393, 164],
    [164, 267],
    [267, 393],
    [268, 302],
    [302, 12],
    [12, 268],
    [312, 268],
    [268, 13],
    [13, 312],
    [298, 293],
    [293, 301],
    [301, 298],
    [265, 446],
    [446, 340],
    [340, 265],
    [280, 330],
    [330, 425],
    [425, 280],
    [322, 426],
    [426, 391],
    [391, 322],
    [420, 429],
    [429, 437],
    [437, 420],
    [393, 391],
    [391, 326],
    [326, 393],
    [344, 440],
    [440, 438],
    [438, 344],
    [458, 459],
    [459, 461],
    [461, 458],
    [364, 434],
    [434, 394],
    [394, 364],
    [428, 396],
    [396, 262],
    [262, 428],
    [274, 354],
    [354, 457],
    [457, 274],
    [317, 316],
    [316, 402],
    [402, 317],
    [316, 315],
    [315, 403],
    [403, 316],
    [315, 314],
    [314, 404],
    [404, 315],
    [314, 313],
    [313, 405],
    [405, 314],
    [313, 421],
    [421, 406],
    [406, 313],
    [323, 366],
    [366, 361],
    [361, 323],
    [292, 306],
    [306, 407],
    [407, 292],
    [306, 291],
    [291, 408],
    [408, 306],
    [291, 287],
    [287, 409],
    [409, 291],
    [287, 432],
    [432, 410],
    [410, 287],
    [427, 434],
    [434, 411],
    [411, 427],
    [372, 264],
    [264, 383],
    [383, 372],
    [459, 309],
    [309, 457],
    [457, 459],
    [366, 352],
    [352, 401],
    [401, 366],
    [1, 274],
    [274, 4],
    [4, 1],
    [418, 421],
    [421, 262],
    [262, 418],
    [331, 294],
    [294, 358],
    [358, 331],
    [435, 433],
    [433, 367],
    [367, 435],
    [392, 289],
    [289, 439],
    [439, 392],
    [328, 462],
    [462, 326],
    [326, 328],
    [94, 2],
    [2, 370],
    [370, 94],
    [289, 305],
    [305, 455],
    [455, 289],
    [339, 254],
    [254, 448],
    [448, 339],
    [359, 255],
    [255, 446],
    [446, 359],
    [254, 253],
    [253, 449],
    [449, 254],
    [253, 252],
    [252, 450],
    [450, 253],
    [252, 256],
    [256, 451],
    [451, 252],
    [256, 341],
    [341, 452],
    [452, 256],
    [414, 413],
    [413, 463],
    [463, 414],
    [286, 441],
    [441, 414],
    [414, 286],
    [286, 258],
    [258, 441],
    [441, 286],
    [258, 257],
    [257, 442],
    [442, 258],
    [257, 259],
    [259, 443],
    [443, 257],
    [259, 260],
    [260, 444],
    [444, 259],
    [260, 467],
    [467, 445],
    [445, 260],
    [309, 459],
    [459, 250],
    [250, 309],
    [305, 289],
    [289, 290],
    [290, 305],
    [305, 290],
    [290, 460],
    [460, 305],
    [401, 376],
    [376, 435],
    [435, 401],
    [309, 250],
    [250, 392],
    [392, 309],
    [376, 411],
    [411, 433],
    [433, 376],
    [453, 341],
    [341, 464],
    [464, 453],
    [357, 453],
    [453, 465],
    [465, 357],
    [343, 357],
    [357, 412],
    [412, 343],
    [437, 343],
    [343, 399],
    [399, 437],
    [344, 360],
    [360, 440],
    [440, 344],
    [420, 437],
    [437, 456],
    [456, 420],
    [360, 420],
    [420, 363],
    [363, 360],
    [361, 401],
    [401, 288],
    [288, 361],
    [265, 372],
    [372, 353],
    [353, 265],
    [390, 339],
    [339, 249],
    [249, 390],
    [339, 448],
    [448, 255],
    [255, 339],
  ]);
  Y('HAND_CONNECTIONS', [
    [0, 1],
    [1, 2],
    [2, 3],
    [3, 4],
    [0, 5],
    [5, 6],
    [6, 7],
    [7, 8],
    [5, 9],
    [9, 10],
    [10, 11],
    [11, 12],
    [9, 13],
    [13, 14],
    [14, 15],
    [15, 16],
    [13, 17],
    [0, 17],
    [17, 18],
    [18, 19],
    [19, 20],
  ]);
  Y('POSE_CONNECTIONS', [
    [0, 1],
    [1, 2],
    [2, 3],
    [3, 7],
    [0, 4],
    [4, 5],
    [5, 6],
    [6, 8],
    [9, 10],
    [11, 12],
    [11, 13],
    [13, 15],
    [15, 17],
    [15, 19],
    [15, 21],
    [17, 19],
    [12, 14],
    [14, 16],
    [16, 18],
    [16, 20],
    [16, 22],
    [18, 20],
    [11, 23],
    [12, 24],
    [23, 24],
    [23, 25],
    [24, 26],
    [25, 27],
    [26, 28],
    [27, 29],
    [28, 30],
    [29, 31],
    [30, 32],
    [27, 31],
    [28, 32],
  ]);
  Y('POSE_LANDMARKS', {
    NOSE: 0,
    LEFT_EYE_INNER: 1,
    LEFT_EYE: 2,
    LEFT_EYE_OUTER: 3,
    RIGHT_EYE_INNER: 4,
    RIGHT_EYE: 5,
    RIGHT_EYE_OUTER: 6,
    LEFT_EAR: 7,
    RIGHT_EAR: 8,
    LEFT_RIGHT: 9,
    RIGHT_LEFT: 10,
    LEFT_SHOULDER: 11,
    RIGHT_SHOULDER: 12,
    LEFT_ELBOW: 13,
    RIGHT_ELBOW: 14,
    LEFT_WRIST: 15,
    RIGHT_WRIST: 16,
    LEFT_PINKY: 17,
    RIGHT_PINKY: 18,
    LEFT_INDEX: 19,
    RIGHT_INDEX: 20,
    LEFT_THUMB: 21,
    RIGHT_THUMB: 22,
    LEFT_HIP: 23,
    RIGHT_HIP: 24,
    LEFT_KNEE: 25,
    RIGHT_KNEE: 26,
    LEFT_ANKLE: 27,
    RIGHT_ANKLE: 28,
    LEFT_HEEL: 29,
    RIGHT_HEEL: 30,
    LEFT_FOOT_INDEX: 31,
    RIGHT_FOOT_INDEX: 32,
  });
  Y('POSE_LANDMARKS_LEFT', {
    LEFT_EYE_INNER: 1,
    LEFT_EYE: 2,
    LEFT_EYE_OUTER: 3,
    LEFT_EAR: 7,
    LEFT_RIGHT: 9,
    LEFT_SHOULDER: 11,
    LEFT_ELBOW: 13,
    LEFT_WRIST: 15,
    LEFT_PINKY: 17,
    LEFT_INDEX: 19,
    LEFT_THUMB: 21,
    LEFT_HIP: 23,
    LEFT_KNEE: 25,
    LEFT_ANKLE: 27,
    LEFT_HEEL: 29,
    LEFT_FOOT_INDEX: 31,
  });
  Y('POSE_LANDMARKS_RIGHT', {
    RIGHT_EYE_INNER: 4,
    RIGHT_EYE: 5,
    RIGHT_EYE_OUTER: 6,
    RIGHT_EAR: 8,
    RIGHT_LEFT: 10,
    RIGHT_SHOULDER: 12,
    RIGHT_ELBOW: 14,
    RIGHT_WRIST: 16,
    RIGHT_PINKY: 18,
    RIGHT_INDEX: 20,
    RIGHT_THUMB: 22,
    RIGHT_HIP: 24,
    RIGHT_KNEE: 26,
    RIGHT_ANKLE: 28,
    RIGHT_HEEL: 30,
    RIGHT_FOOT_INDEX: 32,
  });
  Y('POSE_LANDMARKS_NEUTRAL', { NOSE: 0 });
}.call(this));
