import React from 'react';
import styled from 'styled-components';

import SRC_NIPPON from '../../assets/logo/nippon.svg';
import SRC_CUHK from '../../assets/logo/cuhk.svg';
import SRC_CSLDS from '../../assets/logo/CSLDS.png';
import SRC_KWANSEI from '../../assets/logo/kwansei.svg';
import SRC_GOOGLE from '../../assets/logo/google.png';
import SRC_SIGN from '../../assets/logo/sign_language_research_center.svg';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  position: fixed;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 50%);

  width: 100%;
  max-width: 1600px;
  min-width: 1000px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
`;
const Section = styled.section`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  /* width: 40vw; */
  & h2 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
`;

const Title = styled.h2`
  margin: auto 40px auto 0;
  white-space: pre;
`;

const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  & img {
    height: 50px;
  }

  img + img {
    margin-left: 40px;
  }
`;

export default function PartnerLogo(): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Container>
      <Section>
        <Title>{t('global.broughttoyou')}</Title>
        <Logos>
          <img src={SRC_NIPPON} />
          <img src={SRC_CUHK} style={{ height: 50 }} />
          <img src={SRC_GOOGLE} style={{ height: 35 }} />
          <img src={SRC_CSLDS} style={{ height: 35 }} />
        </Logos>
      </Section>
      <Section>
        <Title>{t('global.collaborator')}</Title>
        <Logos>
          <img
            src={SRC_KWANSEI}
            style={{ height: 70, transform: 'translate(0, -.5rem)' }}
          />
          <img src={SRC_SIGN} style={{ height: 22 }} />
        </Logos>
      </Section>
    </Container>
  );
}
