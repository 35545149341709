import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useRecoilState, useRecoilValue } from 'recoil';

import styled from 'styled-components';

import config from '../../../config';
import CharacterDialog from '../../../containers/MainCharacter/CharacterDialog';
import PlayButton from '../../../containers/PlayButton';
import SignPlay from '../../../containers/SignPlay';
import { CloseButton } from '../../../layout/Modal';
import {
  CharacterPose,
  characterStateAtom,
} from '../../../recoils/characterStateAtom';
import {
  getGameStateAtomByMap,
  IGameState,
  MapNames,
} from '../../../recoils/gameStateAtom';
import {
  soundStateAtom,
  SOUNDSTATE,
  soundStatusAtom,
  SOUNDSTATUS,
} from '../../../recoils/soundStateAtom';

const Container = styled.div`
  pointer-events: none;

  & * {
    pointer-events: all;
  }
`;

export default function Interaction({
  className,
  mapName,
}: {
  className?: string;
  mapName: MapNames;
}): React.ReactElement {
  const [open, setOpen] = React.useState(false);
  const [gameState, setGameState] = useRecoilState(
    getGameStateAtomByMap(mapName),
  );
  const [, setCharacterState] = useRecoilState(characterStateAtom);
  const history = useHistory();
  const location = useLocation();

  function walkFor(duration: number): void {
    setCharacterState((oldState) => ({
      ...oldState,
      pose: CharacterPose.WALKING,
    }));
    setTimeout(() => {
      setCharacterState((oldState) => ({
        ...oldState,
        pose: CharacterPose.IDLE,
      }));
    }, duration);
  }

  const nextScene = () => {
    setGameState((oldGameState) => ({
      ...oldGameState,
      scene: oldGameState.scene + 1,
    }));
    // closeModal();
    history.push(
      window.location.pathname +
        '?openmodal=false' +
        '&scene=' +
        (gameState.scene + 1),
    );
    walkFor(config.transition * 1000);
  };

  function compareGameState(gameStateA: IGameState, gameStateB: IGameState) {
    return (
      gameStateA.scene === gameStateB.scene &&
      gameStateA.frame === gameStateB.frame
    );
  }

  // sound
  const [, updateBirdSoundState] = useRecoilState(soundStateAtom);
  const soundStatus = useRecoilValue(soundStatusAtom);

  React.useEffect(() => {
    updateBirdSoundState(SOUNDSTATE.PLAY);
  }, []);

  React.useEffect(() => {
    const searchUrl = window.location.search;
    const searchParams = new URLSearchParams(searchUrl);
    const openmodal = searchParams.get('openmodal');
    const scene = searchParams.get('scene');
    if (openmodal === 'true') {
      setOpen(true);
    } else {
      if (scene) console.log('scene: ', parseInt(scene));
      if (scene && parseInt(scene) !== gameState.scene) {
        console.log('scene: ', parseInt(scene));
        setGameState((oldGameState) => ({
          ...oldGameState,
          scene: parseInt(scene),
        }));
        walkFor(config.transition * 1000);
      }
      // history.push(
      //   window.location.pathname +
      //     '?openmodal=false' +
      //     '&scene=' +
      //     gameState.scene,
      // );
      setOpen(false);
    }
  }, [location]);

  const openModal = (scene: number) => {
    history.push(
      window.location.pathname +
        '?openmodal=true&scene=' +
        scene +
        '&signstate=1',
    );

    // setOpen(true);
    if (soundStatus !== SOUNDSTATUS.DISABLED)
      updateBirdSoundState(SOUNDSTATE.PAUSE);
  };
  const closeModal = () => {
    history.push(
      window.location.pathname +
        '?openmodal=false' +
        '&scene=' +
        gameState.scene,
    );

    // setOpen(false);
    if (soundStatus !== SOUNDSTATUS.DISABLED)
      updateBirdSoundState(SOUNDSTATE.PLAY);
  };

  const { t } = useTranslation();

  function renderByGameState() {
    switch (true) {
      case compareGameState({ scene: 0, frame: 0, zoom: false }, gameState):
        return (
          <SignPlay
            open={open}
            Button={
              <PlayButton onClick={() => openModal(0)}>
                {t('play.town.0.button')}
              </PlayButton>
            }
            closeModal={closeModal}
            mapName={mapName}
            nextScene={nextScene}
            thisScene={0}
          >
            <CloseButton onClick={() => closeModal()}>X</CloseButton>
            {/* <button onClick={nextScene}>Next</button> */}
          </SignPlay>
        );

      case compareGameState({ scene: 1, frame: 0, zoom: false }, gameState):
        return (
          <SignPlay
            open={open}
            Button={
              <PlayButton onClick={() => openModal(1)}>
                {t('play.town.1.button')}
              </PlayButton>
            }
            closeModal={closeModal}
            mapName={mapName}
            nextScene={nextScene}
            thisScene={1}
          >
            <CloseButton onClick={() => closeModal()}>X</CloseButton>
            {/* <button onClick={nextFrame}>Next</button> */}
          </SignPlay>
        );
      case compareGameState({ scene: 2, frame: 0, zoom: false }, gameState):
        return (
          <SignPlay
            open={open}
            Button={
              <PlayButton onClick={() => openModal(2)}>
                {t('play.town.2.button')}
              </PlayButton>
            }
            closeModal={closeModal}
            mapName={mapName}
            nextScene={nextScene}
            thisScene={2}
          >
            <CloseButton onClick={() => closeModal()}>X</CloseButton>
          </SignPlay>
        );
      // case compareGameState({ scene: 3, frame: 0, zoom: false }, gameState):
      //   return (
      //     <SignPlay
      //       open={open}
      //       Button={<PlayButton>{t('play.town.3.button')}</PlayButton>}
      //       closeModal={closeModal}
      //       mapName={mapName}
      //       nextScene={nextScene}
      //       thisScene={2}
      //     >
      //       <CloseButton onClick={() => closeModal()}>X</CloseButton>
      //     </SignPlay>
      //   );
      default:
        // <PlayButton deactivated>{t('play.town.disabled.button')}</PlayButton>
        // return <PlayButton disabled>{t('play.town.2.button')}</PlayButton>;
        return <></>;
    }
  }

  return (
    <Container className={className}>
      <CharacterDialog mapName={mapName} />
      {renderByGameState()}
    </Container>
  );
}
