import * as React from 'react';

import styled from 'styled-components';
import { ParentSizeProvidedProps } from '@vx/responsive/lib/components/ParentSize';

import { IAssetProps } from './Asset';
import { MapNames } from '../../recoils/gameStateAtom';

const LayerGroup = styled.g``;

export interface ILayerProps {
  sceneIndex?: number;
  mapName?: MapNames;
  sceneName?: string;
  layerName?: string;
  dimension?: ParentSizeProvidedProps;
  children?: React.ReactElement | React.ReactElement[];
}

function Layer(props: ILayerProps): React.ReactElement {
  function renderAsset(Asset: React.ReactElement) {
    if (typeof Asset.type === 'string') return Asset;
    return React.cloneElement<IAssetProps>(Asset, {
      key: Asset.props.name,
      sceneIndex: props.sceneIndex,
      mapName: props.mapName,
      sceneName: props.sceneName,
      layerName: props.layerName,
      dimension: props.dimension,
    });
  }

  function renderAssets() {
    if (Array.isArray(props.children)) {
      return props.children.map(renderAsset);
    } else if (props.children) {
      return renderAsset(props.children);
    }
  }
  return <LayerGroup>{renderAssets()}</LayerGroup>;
}

export default Layer;
