import * as React from 'react';

import { MapNames } from '../../../recoils/gameStateAtom';
import InteractionBase from './Interaction';
import styled from 'styled-components';
import SceneController from '../../../components/SceneController';

const Interaction = styled(InteractionBase)`
  position: absolute;
  top: 50%;
  z-index: 20;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default function PlayHotel({
  mapName,
}: {
  mapName: MapNames;
}): React.ReactElement {
  return (
    <main>
      <Interaction mapName={mapName} />
      {/* {isDev && <PlayController mapName={mapName} />} */}
      <SceneController mapName={mapName} numScene={4} />
      {/* <Reset mapName={mapName} /> */}
    </main>
  );
}
