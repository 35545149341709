import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { classifyModel as classifyModelAtom } from '../../recoils/mlStateAtom';

import { ErrorStatus, errorAtom } from '../../recoils/errorAtom';
import {
  e_playerPositionState,
  playerPositionState as playerPositionStateAtom,
} from '../../recoils/playStateAtom';
import { useTranslation } from 'react-i18next';
/**
 * TODO: handle clear useEffect hook
 * connect send 16 frame to cloud
 */
const VideoStyle = styled.video`
  position: absolute;
  height: 100%;
  left: 50%;
  object-fit: cover;
  width: 80%;
  transform: translate(-50%, 0) rotateY(180deg);
  border-radius: 30px;
`;
const VideoBorder = styled.div`
  position: absolute;
  height: 100%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, 0);
  border: 0.5rem solid black;
  border-radius: 30px;
  box-sizing: border-box;
  z-index: 11;
`;

const CanvasCaptureStyle = styled.canvas`
  position: absolute;
  width: 100%;
  opacity: 0;
  object-fit: contain;
  transform: rotateY(180deg);
  z-index: -1;
`;
const VideoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 80%;
`;
const HumanGuide = styled.img`
  position: absolute;
  height: 98%;
  width: 78%;
  top: 50%;
  left: 50%;
  object-fit: cover;
  z-index: 10;
  transform: translate(-50%, -50%);
`;
const AlearText = styled.p`
  font-size: calc(24px * var(--scale, 1));
  font-weight: 600;
  width: max-content;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
`;

type CameraComponentsProps = {
  action?: () => void;
  signingState: string;
  handleStart?: () => void;
};

type CameraComponentsHandle = {
  captureImage: () => void;
  start: () => void;
  handleClassify: () => void;
};

let farBuffer = 120;
let closeBuffer = 155;
let readyTimeout: NodeJS.Timeout | undefined;
const Camera: ForwardRefRenderFunction<
  CameraComponentsHandle,
  CameraComponentsProps
> = (props: CameraComponentsProps, forwardedRef) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasCaptureRef = useRef<HTMLCanvasElement>(null);

  const classifyModel = useRecoilValue(classifyModelAtom);

  const [isVideoPlayed, updateIsVideoPlayed] = useState<boolean>(false);
  const [isCameraSetup, updateIsCameraSetup] = useState<boolean>(false);
  const [isMobileCheck] = useState<boolean>(isMobile);

  const [startPoseCheck, updateStartPoseCheck] = useState(true);
  const [isClassify, updateIsClassify] = useState(false);
  const [playerPositionState, updatePlayerPositionState] = useRecoilState(
    playerPositionStateAtom,
  );
  // const [cameraPermissionState, updateCameraPermissionState] = useState<string>(
  //   'idle',
  // );
  const [, updateCameraError] = useRecoilState(errorAtom);
  const { t } = useTranslation();

  const setupCamera = async () => {
    const constraints = {
      audio: false,
      video: {
        facingMode: 'user', // 'user' or 'environment'
      },
    };
    if (videoRef.current !== null) {
      const mediaStream = await navigator.mediaDevices
        .getUserMedia(constraints)
        .catch((err) => {
          console.log(err.name);
          updateIsCameraSetup(false);
          if (props.action) props.action();
          if (err.name === 'NotAllowedError') {
            updateCameraError(ErrorStatus.CAMERA_PERMISSION);
            return;
          } else {
            updateCameraError(ErrorStatus.CAMERA_UNDETECTED);
            return;
          }
        });
      if (mediaStream) {
        videoRef.current.srcObject = mediaStream;
        console.log(`--- set up camera ---`);
        updateIsCameraSetup(true);
        // updateCameraPermissionState('allow');
      }
    } else return;
  };

  const onLoadedVideo = () => {
    console.log('loadedVideoData');
    if (
      videoRef.current !== null &&
      canvasCaptureRef.current !== null &&
      !isVideoPlayed &&
      isCameraSetup
    ) {
      updateIsVideoPlayed(true);
      videoRef.current.play();
    }
  };

  useEffect(() => {
    if (videoRef.current !== null) setupCamera();
  }, [videoRef]);

  useEffect(() => {
    if (canvasCaptureRef.current !== null) onLoadedVideo();
  }, [canvasCaptureRef]);

  const captureImage = () => {
    const canvasCtx = canvasCaptureRef.current?.getContext('2d');
    if (
      canvasCaptureRef.current?.width !== undefined &&
      canvasCaptureRef.current?.height !== undefined &&
      videoRef.current !== null
    ) {
      // console.log('capture Image !');
      canvasCtx?.clearRect(
        0,
        0,
        canvasCaptureRef.current?.width,
        canvasCaptureRef.current?.height,
      );
      isMobileCheck
        ? canvasCtx?.drawImage(
            videoRef.current,
            0,
            (videoRef.current.videoHeight - videoRef.current.videoWidth) / 2,
            videoRef.current?.videoWidth,
            videoRef.current?.videoWidth,
            0,
            0,
            canvasCaptureRef.current?.width,
            canvasCaptureRef.current?.height,
          )
        : canvasCtx?.drawImage(
            videoRef.current,
            (videoRef.current.videoWidth - videoRef.current.videoHeight) / 2,
            0,
            videoRef.current?.videoHeight,
            videoRef.current?.videoHeight,
            0,
            0,
            canvasCaptureRef.current?.width,
            canvasCaptureRef.current?.height,
          );
      return {
        imageData: canvasCtx?.getImageData(
          0,
          0,
          canvasCaptureRef.current.width,
          canvasCaptureRef.current.height,
        ),
        imageUrl: canvasCaptureRef.current?.toDataURL('image/png'),
      };
    } else return false;
  };

  React.useImperativeHandle(forwardedRef, () => ({
    captureImage: captureImage,
    start: function start() {
      console.log('start');
    },
    handleClassify: handleClassify,
  }));

  useEffect(() => {
    if (
      isVideoPlayed &&
      isCameraSetup &&
      startPoseCheck &&
      !isClassify &&
      props.signingState === 'positioning'
    ) {
      const poseCheck = async (image: {
        imageData: ImageData;
        imageURL: string;
      }) => {
        const diffEar = await classifyModel.predictFace(image.imageData);
        if (typeof diffEar === 'number') {
          // console.log(diffEar);
          if (diffEar > closeBuffer) {
            updatePlayerPositionState(e_playerPositionState.T_CLOSE);
            if (readyTimeout) clearTimeout(readyTimeout);
            readyTimeout = undefined;
            closeBuffer = 280;
          } else if (diffEar < farBuffer) {
            updatePlayerPositionState(e_playerPositionState.T_FAR);
            if (readyTimeout) clearTimeout(readyTimeout);
            readyTimeout = undefined;
            farBuffer = 230;
          } else {
            updatePlayerPositionState(e_playerPositionState.OK);
            console.log(readyTimeout);
            if (!readyTimeout) {
              readyTimeout = setTimeout(() => {
                if (props.handleStart) props.handleStart();
                if (readyTimeout) clearTimeout(readyTimeout);
                readyTimeout = undefined;
              }, 1000);
            }
            if (farBuffer === 230 || closeBuffer === 280) {
              closeBuffer = 290;
              farBuffer = 210;
            }
          }
        } else {
          updatePlayerPositionState(e_playerPositionState.NONE);
          console.log(diffEar);
        }
        setTimeout(() => {
          updateStartPoseCheck(true);
        }, 20);
      };

      const image = captureImage();
      if (image && image.imageData) {
        poseCheck({ imageData: image.imageData, imageURL: image.imageUrl });
      }
      updateStartPoseCheck(false);
    }
  }, [startPoseCheck, isVideoPlayed, isCameraSetup, props.signingState]);

  const handleClassify = () => {
    updateIsClassify(true);
  };
  // const { i18n } = useTranslation();

  return (
    <>
      <VideoWrapper>
        {props.signingState === 'positioning' && (
          <>
            <HumanGuide src={'./assets/sign_play/guide_human.png'} />
            {playerPositionState !== e_playerPositionState.OK ? (
              <AlearText>
                {playerPositionState === e_playerPositionState.T_CLOSE &&
                  t('signplay.signing.tooclose')}
                {playerPositionState === e_playerPositionState.T_FAR &&
                  t('signplay.signing.toofar')}
              </AlearText>
            ) : (
              <AlearText>{t('signplay.signing.positionready')}</AlearText>
            )}
          </>
        )}
        {props.signingState !== 'processing' &&
          props.signingState !== 'senddata' && <VideoBorder />}
        <VideoStyle
          ref={videoRef}
          autoPlay={true}
          onLoadedData={onLoadedVideo}
          playsInline
          id="videoEl"
        />
      </VideoWrapper>
      <CanvasCaptureStyle
        id="canvasEl"
        ref={canvasCaptureRef}
        width={257}
        height={257}
      />
    </>
  );
};

export default forwardRef(Camera);
