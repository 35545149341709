import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import config from '../../config';
import {
  CharacterPose,
  characterStateAtom,
} from '../../recoils/characterStateAtom';
import { getGameStateAtomByMap, MapNames } from '../../recoils/gameStateAtom';

const transitionStyles: { [key in TransitionStatus]: { opacity: number } } = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

const DialogBox = styled.div<{ state: TransitionStatus }>`
  position: fixed;
  top: 30vh;
  @media (min-height: 1000px) {
    top: calc(70vh - 400px);
  }
  left: ${config.characterOffset * 100}vw;
  transform: translate(-50%, -50%);

  font-size: 22px;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;

  opacity: ${(props) => transitionStyles[props.state]['opacity']};
  transition: opacity ${config.fadeDuration}ms ease-in-out;

  width: 25vw;

  z-index: 13;
`;

const Announcer = styled.div<{ state: TransitionStatus }>`
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 25px;
  font-weight: bold;
  text-align: center;

  opacity: ${(props) => transitionStyles[props.state]['opacity']};
  transition: opacity ${config.fadeDuration}ms ease-in-out;

  z-index: 13;
`;

interface ICharacterDialogProps {
  mapName?: string;
}

export default function CharacterDialog(
  props: ICharacterDialogProps,
): React.ReactElement {
  const { t } = useTranslation();

  const gameState = useRecoilValue(
    getGameStateAtomByMap(props.mapName as MapNames),
  );
  const { scene } = gameState;
  const { pose } = useRecoilValue(characterStateAtom);

  const [dialog, setDialog] = React.useState('');
  const [annouce, setAnnouce] = React.useState('');
  const nodeRef = React.useRef(null);

  React.useEffect(() => {
    const characterIdx = ['play', props.mapName, scene, 'dialog'].join('.');
    const annoucerIdx = ['play', props.mapName, scene, 'annouce'].join('.');
    const dialog = t(characterIdx, { defaultValue: '' });
    const annouce = t(annoucerIdx, { defaultValue: '' });

    setTimeout(() => {
      setDialog(dialog);
      setAnnouce(annouce);
    }, config.fadeDuration);
  }, [gameState]);
  return (
    <Transition
      nodeRef={nodeRef}
      in={pose === CharacterPose.IDLE}
      timeout={config.fadeDuration}
    >
      {(state) => (
        <div ref={nodeRef}>
          <DialogBox state={state}>{dialog}</DialogBox>
          <Announcer state={state}>{annouce}</Announcer>
        </div>
      )}
    </Transition>
  );
}
