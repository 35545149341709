import styled from 'styled-components';

export default styled.div<{ progress: number }>`
  width: 100%;
  height: 6px;
  background: #bfbfbf;
  position: relative;
  border-radius: 5px;

  &::after {
    position: absolute;
    top: 0;
    left: 0;

    content: '';
    border-radius: 5px;
    width: ${(props) => props.progress * 100}%;
    height: 100%;
    background: #52c6b1;

    transition: 0.5s all;
  }
`;
// function LineIndicator ({progress}: {progress: number}): React.ReactElement {
//   return
// }
