import { atom } from 'recoil';

export enum SOUNDSTATUS {
  DISABLED,
  ENABLE,
}

export enum SOUNDSTATE {
  PLAY,
  PAUSE,
}

export const soundStatusAtom = atom({
  key: 'soundstatus',
  default: SOUNDSTATUS.DISABLED,
});

export const soundStateAtom = atom({
  key: 'soundplay',
  default: SOUNDSTATE.PLAY,
});

export const soundLevelAtom = atom({
  key: 'soundvolume',
  default: 1,
});
