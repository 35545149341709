import { IAssetProps } from '../containers/ParallaxMap/Asset';
import { IGameState } from '../recoils/gameStateAtom';

export interface IPosition {
  x: number;
  y: number;
}

export type IAssetStyle = IPosition & { opacity: number };

export default function getAssetPosition(
  gameState: IGameState,
  asset: IAssetProps,
): IAssetStyle {
  if (!asset.dimension) {
    return { x: 0, y: 0, opacity: 0 };
  }

  const offsetX =
    (gameState.scene - (asset.sceneIndex as number)) * asset.speed;
  const normalizedPositionX =
    asset.start.x + offsetX + (asset.sceneIndex as number) - gameState.scene;
  const x = normalizedPositionX * asset.dimension.width;

  const y = asset.start.y * asset.dimension.height;

  const opacity = 1;

  return {
    x,
    y,
    opacity,
  };
}
