import React, { FC } from 'react';

import { ParentSize } from '@vx/responsive';
import { useTranslation } from 'react-i18next/';
import styled from 'styled-components';

import { PrimaryButton } from '../../components/Button';
import SVGResponsivePlayer from '../../components/SVGResponsivePlayer';
import { useRecoilState } from 'recoil';
import { lastPageAtom } from '../../recoils/playStateAtom';
import { ModalContext } from '../../layout/Modal';

const SignInstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const Header = styled.h3`
  margin-top: calc(50px * var(--scale, 1));
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: calc(18px * var(--scale, 1));
  font-weight: 700;
`;
const VideoWrapper = styled.div<{ height: number }>`
  width: 100%;
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;
const VideoTextContainer = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const LottieContainer = styled.svg``;
const VideoStyle = styled.div`
  width: 100%;
  height: calc(100% - calc(100px * var(--scale, 1)));
`;
const TextStyle = styled.div`
  margin: 0;
  height: 40px;
  text-align: center;
  font-size: calc(18px * var(--scale, 1));
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ControllerWrapper = styled.div`
  margin-bottom: calc(2.6rem * var(--scale, 1));
`;

interface SignInstructionProps {
  nextState: () => void;
}

const SignInstruction: FC<SignInstructionProps> = (
  props: SignInstructionProps,
) => {
  const { width, height } = React.useContext(ModalContext);
  const { t } = useTranslation();
  const handleClickStart = () => {
    console.log('click start');
    props.nextState();
  };

  const [, updateLastPage] = useRecoilState(lastPageAtom);
  React.useEffect(() => {
    updateLastPage('play_tips');
  }, []);

  return (
    <SignInstructionWrapper>
      <Header>{t('signplay.signinstruction.header')}</Header>
      <VideoWrapper height={height * 0.75}>
        <VideoTextContainer>
          <VideoStyle>
            <ParentSize>
              {(dimension) => (
                <LottieContainer
                  width={dimension.width}
                  height={dimension.height}
                >
                  <g
                    transform={`translate(${dimension.width * 0.5}, ${
                      dimension.height * 0.5
                    }) scale(${width / 900})`}
                  >
                    <SVGResponsivePlayer
                      src={'assets/export_tutorial_A.json'}
                      fitBy={'width'}
                      side={dimension.width}
                    />
                  </g>
                </LottieContainer>
              )}
            </ParentSize>
          </VideoStyle>
          <TextStyle>{t('signplay.signinstruction.left')}</TextStyle>
        </VideoTextContainer>
        <VideoTextContainer>
          <VideoStyle>
            <ParentSize>
              {(dimension) => (
                <LottieContainer
                  width={dimension.width}
                  height={dimension.height}
                >
                  <g
                    transform={`translate(${dimension.width * 0.5}, ${
                      dimension.height * 0.5
                    }) scale(${width / 900})`}
                  >
                    <SVGResponsivePlayer
                      src={'assets/export_tutorial_B.json'}
                      fitBy={'width'}
                      side={dimension.width}
                    />
                  </g>
                </LottieContainer>
              )}
            </ParentSize>
          </VideoStyle>
          <TextStyle>{t('signplay.signinstruction.right')}</TextStyle>
        </VideoTextContainer>
      </VideoWrapper>
      <ControllerWrapper>
        <PrimaryButton onClick={handleClickStart}>
          {t('signplay.signinstruction.start')}
        </PrimaryButton>
      </ControllerWrapper>
    </SignInstructionWrapper>
  );
};

export default SignInstruction;
