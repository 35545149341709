import { deafCultureName } from '../../recoils/deafCultureStateAtom';

export default function Viewbox(
  cultureName: deafCultureName,
): string | undefined {
  return {
    [deafCultureName.LIGHT_ON]: undefined,

    [deafCultureName.HAND_UP_DOWN]: undefined, // 0 0 240 220

    [deafCultureName.HAND_WAVE_TAP_SHOULDER]: undefined,

    [deafCultureName.CHARACTER_SWITCH_POSITION]: undefined,

    [deafCultureName.BAG_CHARACTER]: undefined,

    [deafCultureName.LIGHT_SWITCH]: undefined,

    [deafCultureName.CLOCK_VIBRATE]: '0 40 800 500',
  }[cultureName];
}
