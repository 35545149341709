import * as React from 'react';
import { useRecoilValue } from 'recoil';
import {
  soundLevelAtom,
  SOUNDSTATUS,
  soundStatusAtom,
} from '../recoils/soundStateAtom';

interface IMutableAudio {
  play: () => void;
  setCurrentTime: (t: number) => void;
  pause: () => void;
  setVolume: (v: number) => void;
  setAttribute: (qualifiedName: string, value: string) => void;
}

let shouldPlay = false;
export default function useSound(audiofile: string): IMutableAudio {
  const audio = React.useRef(new Audio(audiofile)).current;
  const soundStatus = useRecoilValue(soundStatusAtom);
  const soundLevel = useRecoilValue(soundLevelAtom);
  React.useEffect(() => {
    if (soundStatus === SOUNDSTATUS.DISABLED) {
      shouldPlay = false;
      audio.pause();
    } else {
      shouldPlay = true;
    }
  }, [soundStatus]);
  React.useEffect(() => {
    audio.volume = soundLevel;
  }, [soundLevel]);
  const MutableAudio = {
    play: () => {
      if (shouldPlay) {
        audio.play();
      }
    },
    setCurrentTime: (t: number) => {
      audio.currentTime = t;
    },
    pause: () => {
      audio.pause();
    },
    setVolume: (v: number) => {
      audio.volume = v;
    },
    setAttribute: (qualifiedName: string, value: string) => {
      audio.setAttribute(qualifiedName, value);
    },
  };
  const audioRef = React.useRef(MutableAudio);

  return audioRef.current;
}
