import * as React from 'react';
import { TransitionStatus } from 'react-transition-group/Transition';

import styled from 'styled-components';

export const modalTransition: {
  [key in TransitionStatus]: string;
} = {
  entering: `
    transform: translate(0, 200%);
  `,
  entered: `
    transform: translate(0, 0);
  `,
  exiting: `
    transform: translate(0, 200%);
  `,
  exited: `
    transform: translate(0, 200%);
  `,
  unmounted: `
    transform: translate(0, 200%);
  `,
};

export const modalContainerTransition: {
  [key in TransitionStatus]: string;
} = {
  entering: `
    background-color: rgba(104, 104, 104, 0);
  `,
  entered: `
    background-color: rgba(104, 104, 104, 0.75);
  `,
  exiting: `
    background-color: rgba(104, 104, 104, 0);
  `,
  exited: `
    background-color: rgba(104, 104, 104, 0);
  `,
  unmounted: `
    background-color: rgba(104, 104, 104, 0);
  `,
};
export interface ITransition {
  transition?: {
    css: { [key in TransitionStatus]: string };
    status: TransitionStatus;
  };
}

export const ModalContainer = styled.div<ITransition>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 25;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.transition
      ? `
    ${props.transition.css[props.transition.status]};
    transition: all 500ms;
  `
      : ''}
`;

export const ModalBase = styled.div<IModalProps & ITransition>`
  --scale: ${(props) => props.scale || 1};
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: ${(props) => props.borderWidth}px solid black;
  border-radius: 20px;
  background: ${(props) => props.background};

  overflow: hidden;

  position: relative;

  ${(props) =>
    props.transition
      ? `
    ${props.transition.css[props.transition.status]};
    transition: all 500ms;
  `
      : ''}

  ${(props) =>
    props.folded
      ? `clip-path: path('M0 0
      H${props.width}
      V${props.height}
      h${
        -(props.width as number) +
        (props.foldedWidth as number) -
        (props.borderWidth as number) / 2
      }
      L0 ${
        (props.height as number) -
        (props.foldedHeight as number) +
        (props.borderWidth as number) / 2
      }
        L0 0');`
      : ''}
`;

interface IModalProps {
  width?: number;
  height?: number;
  folded?: boolean;
  foldedWidth?: number;
  foldedHeight?: number;
  borderWidth?: number;
  background?: string;
  className?: string;
  children?: React.ReactNode;
  left?: boolean;
  foldedColor?: string;
  scale?: number;
}

const ratio = 9000 / 5652;
const getWidth = () => Math.min(0.8 * innerWidth, 0.8 * innerHeight * ratio);
let width = getWidth();

export const ModalContext = React.createContext<{
  width: number;
  height: number;
}>({ width: width, height: width / ratio });

export function Modal<T>({
  folded = true,
  foldedWidth = 60,
  foldedHeight = 68,
  borderWidth = 8,
  background = 'white',
  left = true,
  foldedColor = 'transparent',
  ...props
}: T & IModalProps & ITransition): React.ReactElement {
  const [dimension, setDimension] = React.useState({
    width: width,
    height: width / ratio,
  });

  React.useEffect(() => {
    const resize = () => {
      const newWidth = getWidth();
      width = newWidth;

      setDimension({
        width: newWidth,
        height: newWidth / ratio,
      });
    };
    window.addEventListener('resize', resize);
    resize();

    return () => window.removeEventListener('resize', resize);
  }, []);

  return (
    <ModalContext.Provider value={dimension}>
      <ModalBase
        {...props}
        width={dimension.width}
        height={dimension.height}
        folded={folded}
        foldedWidth={foldedWidth}
        foldedHeight={foldedHeight}
        borderWidth={borderWidth}
        background={background}
        left={left}
        scale={width / 900}
      >
        {props.children}
        {folded && (
          <svg
            width={dimension.width - borderWidth * 2}
            height={dimension.height - borderWidth * 2}
            style={{
              position: 'absolute',
              top: 0,
              left: -16,
              pointerEvents: 'none',
            }}
          >
            <path
              d={`
            M${(dimension.width - borderWidth) * -1 + dimension.width} ${
                dimension.height - borderWidth - foldedHeight
              }
            L${
              (dimension.width - borderWidth - foldedWidth) * -1 +
              dimension.width
            } ${dimension.height - borderWidth}
            v-${0.7 * foldedHeight}
            C ${
              (dimension.width - borderWidth - foldedWidth) * -1 +
              dimension.width
            } ${dimension.height - borderWidth - foldedHeight * 0.7}, ${
                (dimension.width - borderWidth - foldedWidth) * -1 +
                dimension.width
              } ${dimension.height - borderWidth - foldedHeight}, ${
                (dimension.width - borderWidth - 0.7 * foldedWidth) * -1 +
                dimension.width
              } ${dimension.height - borderWidth - foldedHeight}
            h${-0.7 * foldedWidth}
            `}
              fill={foldedColor}
              stroke={'black'}
              strokeWidth={borderWidth}
              strokeLinejoin={'round'}
            />
          </svg>
        )}
      </ModalBase>
    </ModalContext.Provider>
  );
}

export const CloseButtonBase = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;

  // placeholder
  outline: none;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
`;

export function CloseButton(
  props: React.ButtonHTMLAttributes<HTMLButtonElement>,
): React.ReactElement {
  return (
    <CloseButtonBase {...props}>
      <svg
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.5416 8.2795L22.7203 6.45825L15.4999 13.6787L8.2795 6.45825L6.45825 8.2795L13.6787 15.4999L6.45825 22.7203L8.2795 24.5416L15.4999 17.3212L22.7203 24.5416L24.5416 22.7203L17.3212 15.4999L24.5416 8.2795Z"
          fill="black"
          fillOpacity="0.49"
        />
      </svg>
    </CloseButtonBase>
  );
}
