import * as React from 'react';
import { useRecoilState } from 'recoil';

import { useTranslation } from 'react-i18next';

import ErrorPage, {
  ErrorDevice,
  ErrorUndetected,
  IErrorPageProps,
} from '../../components/ErrorPage';
import { errorAtom } from '../../recoils/errorAtom';
import { ErrorStatus } from '../../recoils/errorAtom';

import CAMERA_UNDETECTED_ICON from '../../assets/icon/error/CAMERA_PERMISSION.svg';
import BROWSER_INCOMPATIBLE_ICON from '../../assets/icon/error/BROWSER_INCOMPATIBLE.svg';
import ML_LOADING_FAILURE_ICON from '../../assets/icon/error/ML_LOADING_FAILURE.svg';
import DEVICE_INCOMPATIBLE_ICON from '../../assets/icon/error/DEVICE_INCOMPATIBLE.svg';

const ErrorIcon: { [key in keyof typeof ErrorStatus]: string | undefined } = {
  CAMERA_UNDETECTED: CAMERA_UNDETECTED_ICON,
  BROWSER_INCOMPATIBLE: BROWSER_INCOMPATIBLE_ICON,
  CAMERA_PERMISSION: undefined,
  DEVICE_INCOMPATIBLE: DEVICE_INCOMPATIBLE_ICON,
  ML_LOADING_FAILURE: ML_LOADING_FAILURE_ICON,
  WEBGL_ERROR: ML_LOADING_FAILURE_ICON,
};

export default function Error(): React.ReactElement {
  const [errorStatus, setErrrorStatus] = useRecoilState(errorAtom);

  const { t } = useTranslation();

  function errorTranslationKey(field: string) {
    return ['error', errorStatus, field].join('.');
  }

  const errorProps: IErrorPageProps = {
    icon: errorStatus && ErrorIcon[errorStatus],
    title: t(errorTranslationKey('title')),
    message: t(errorTranslationKey('message'), {
      interpolation: { escapeValue: false },
    }),
  };

  return errorStatus ? (
    errorStatus === ErrorStatus.CAMERA_PERMISSION ? (
      <ErrorUndetected
        {...errorProps}
        onDismiss={() => setErrrorStatus(undefined)}
      />
    ) : errorStatus === ErrorStatus.DEVICE_INCOMPATIBLE ? (
      <ErrorDevice {...errorProps} />
    ) : (
      <ErrorPage {...errorProps} />
    )
  ) : (
    <></>
  );
}
