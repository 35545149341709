import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import dropdownIcon from '../../assets/icon/dropdown_arrow.svg';

const StyleSelectBox = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 0 1rem;
  margin: 0 -1rem;
  position: relative;
  min-width: 200px;
`;
const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.6rem 1rem;

  justify-content: space-evenly;

  margin: 0.2rem;
  border: 5px solid #f1f1f1;
  border-radius: 30px;

  cursor: pointer;
`;
const SelectionText = styled.p`
  margin: 0;
  padding: 0 1rem 0 0;
  font-size: 17px;
  font-weight: 500;
  margin-right: auto;
`;

interface ArrowIconProps {
  isOpen: boolean;
}
const ArrowIcon = styled.img<ArrowIconProps>`
  object-fit: contain;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
  transition: all 0.2s;
`;

interface OptionsWrapperProps {
  isOpen: boolean;
}
const OptionsWrapper = styled.div<OptionsWrapperProps>`
  position: absolute;
  top: 0;
  width: 80%;
  transform: translate(0, -100%);

  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;

  border-radius: 30px;
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 1rem;
  border: none;

  transition: all 0.4s;
`;
const OptionStyle = styled.button`
  width: auto;
  height: 2rem;

  text-align: left;

  padding: 0 1rem;

  background-color: #f1f1f1;
  border: none;
  color: #000;

  &:hover {
    background-color: #000;
    color: #fff;
  }
  &:active {
    background-color: #000;
    color: #fff;
  }
  &:focus {
    outline: 0;
  }

  cursor: pointer;
  transition: all 0.2s;
`;

const LANGUAGE_TEXT: { [key: string]: string } = {
  en: 'English',
  jp: '日本語',
  hk: '繁體中文',
};
const DropdownSelection: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectionValue, updateSelectionValue] = useState<string>(
    i18n.language ? i18n.language : 'en',
  );
  const [isOptionOpen, updateIsOptionOpen] = useState<boolean>(false);

  const changeLanguage = (lng: string) => {
    updateSelectionValue(lng);
    updateIsOptionOpen(false);
    i18n.changeLanguage(lng);
  };

  return (
    <StyleSelectBox>
      {/* 
      wrapper 
      select value | arrow
      options 
      */}
      <SelectionWrapper onClick={() => updateIsOptionOpen(!isOptionOpen)}>
        <SelectionText>{LANGUAGE_TEXT[selectionValue]}</SelectionText>
        <ArrowIcon isOpen={isOptionOpen} src={dropdownIcon} />
      </SelectionWrapper>
      <OptionsWrapper isOpen={isOptionOpen}>
        {Object.entries(LANGUAGE_TEXT).map(
          ([key, Language]) =>
            key !== selectionValue && (
              <OptionStyle key={key} onClick={() => changeLanguage(key)}>
                {Language}
              </OptionStyle>
            ),
        )}
      </OptionsWrapper>
    </StyleSelectBox>
  );
};

export default DropdownSelection;
