import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import { ModalContext } from '../../layout/Modal';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;

  background: black;
  z-index: 5;
`;

const VideoContainer = styled.div`
  height: 100%;
  width: fit-content;
  margin: 0 auto;

  position: relative;
`;

const Video = styled.video`
  height: 100%;
  object-fit: contain;
`;

const Controller = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: center;
`;

const MinifyIcon = styled.img<{ size: number }>`
  margin-left: auto;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  cursor: pointer;
`;

const PlaybackController = styled.div`
  display: flex;
  padding: 20px;
  font-weight: bold;

  p + p {
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.2s;
    :hover {
      background-color: #fff;
      border-radius: 10px;
      padding: 0 10px;
      margin-left: 5px;
    }
  }
`;
const ReadyButton = styled.div`
  padding: 20px 40px;
  background-color: #7ecbbd;
  border-radius: 25px;
  font-weight: bold;
  font-size: calc(12px * var(--scale, 1));
  cursor: pointer;
  text-transform: capitalize;

  margin: auto;

  transition: all 0.2s;
  :hover {
    background-color: #48857a;
    color: #fff;
  }
`;
export default function MagnifyVideo({
  src,
  onMinify,
  ready,
}: {
  src: string;
  onMinify: () => void;
  ready: () => void;
}): React.ReactElement {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const { width } = React.useContext(ModalContext);
  const iconSize = width * 0.05;

  const setPlayback = (playbackRate: number) => {
    if (videoRef && videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  };

  const { t } = useTranslation();

  return (
    <Container>
      <VideoContainer>
        <Video
          ref={videoRef}
          src={src}
          autoPlay={true}
          loop
          playsInline
          muted
        />

        <Controller>
          <PlaybackController>
            <p>{t('signplay.signselection.playbackspeed')}</p>
            <p onClick={() => setPlayback(1)}>1x</p>
            <p onClick={() => setPlayback(0.25)}>0.25x</p>
          </PlaybackController>
          <ReadyButton onClick={ready}>
            {t('signplay.signselection.ready')}
          </ReadyButton>
          <MinifyIcon
            src={'/assets/minifier.svg'}
            size={iconSize}
            onClick={onMinify}
          />
        </Controller>
      </VideoContainer>
    </Container>
  );
}
