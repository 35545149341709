import * as React from 'react';
import { useRecoilValue } from 'recoil';

import styled from 'styled-components';
import { ParentSizeProvidedProps } from '@vx/responsive/lib/components/ParentSize';

import { getGameStateAtomByMap, MapNames } from '../../recoils/gameStateAtom';
import { ISceneProps } from './Scene';
import { ILayerProps } from './Layer';

const Graphics = styled.svg``;
const World = styled.g``;

export interface IParallaxMapProps {
  offset?: number;
  name: MapNames;
  dimension: ParentSizeProvidedProps;
  Background: React.ReactElement;
  Character: React.ReactElement;
  children: React.ReactElement[];
}
const ParallaxMap = ({
  offset = 0,
  ...props
}: IParallaxMapProps): React.ReactElement => {
  const { scene: currentScene } = useRecoilValue(
    getGameStateAtomByMap(props.name),
  );
  const worldRef = React.useRef(null);

  function renderBackground(): React.ReactNode {
    return React.cloneElement<ILayerProps>(props.Background, {
      sceneIndex: currentScene,
      mapName: props.name,
      sceneName: 'background',
      layerName: 'background',
      dimension: props.dimension,
    });
  }

  function renderCharacter(): React.ReactNode {
    return React.cloneElement<ILayerProps>(props.Character, {
      mapName: props.name,
      dimension: props.dimension,
    });
  }

  function renderSceneFactory(layerName: string, childIndex: 0 | 1) {
    return function renderScene(Scene: React.ReactElement, sceneIndex: number) {
      const closeToMainScene = true; // Math.abs(sceneIndex - currentScene) <= 1
      if (closeToMainScene) {
        return React.cloneElement<ISceneProps>(Scene, {
          key: Scene.props.name,
          mapName: props.name,
          layerName: layerName,
          dimension: props.dimension,
          sceneIndex: sceneIndex - offset,
          children: Scene.props.children[childIndex],
        });
      } else {
        return <g key={Scene.props.name}></g>;
      }
    };
  }

  function renderFront(): React.ReactNode {
    return props.children.map(renderSceneFactory('front', 1));
  }

  function renderBack(): React.ReactNode {
    return props.children.map(renderSceneFactory('back', 0));
  }

  return (
    <Graphics width={props.dimension.width} height={props.dimension.height}>
      <World ref={worldRef}>
        {renderBackground()}
        {renderBack()}
        {renderCharacter()}
        {renderFront()}
      </World>
    </Graphics>
  );
};

export default ParallaxMap;
