import React from 'react';

// import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';

import {
  Modal,
  ModalContainer,
  CloseButton,
  modalContainerTransition,
  modalTransition,
  ModalContext,
} from '../../layout/Modal';
import TryPage from './TryPage';
import PositionPage from './PositionPage';
import ReadyPage from './ReadyPage';
import { useTranslation } from 'react-i18next';
// import SVGResponsivePlayer from '../SVGResponsivePlayer';

// props
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 60px 80px 0 80px;

  justify-content: center;
  align-items: center;
  flex-flow: column;

  transition: 500ms all;
`;

// const BackButton = styled.div`
//   position: absolute;
//   top: 40px;
//   left: 40px;
//   width: 20px;
//   cursor: pointer;
//   z-index: 10;
// `;

const SkipButton = styled.div`
  position: absolute;
  bottom: 45px;
  right: calc(40px * var(--scale, 1));
  padding: calc(8px * var(--scale, 1));
  cursor: pointer;
  z-index: 10;
  font-size: calc(16px * var(--scale, 1));
  font-weight: bold;
  white-space: pre;

  color: black;
  background-color: white;
  border-radius: 0px;

  transition: all 0.3s;

  :hover {
    background-color: #7ecbbd;
    border-radius: 15px;
  }
`;

export interface InstructionModalProps {
  open: boolean;
  onCloseButtonClick: () => void;
  onFinish: () => void;
}

export interface InstructionPageProps {
  onNextPage: () => void;
  onFinish: () => void;
  goback: () => void;
}

const InstructionContent = ({
  onCloseButtonClick,
  onFinish,
}: {
  onCloseButtonClick: () => void;
  onFinish: () => void;
}): React.ReactElement => {
  const { width } = React.useContext(ModalContext);

  const InstructionPages: Array<
    (props: InstructionPageProps) => React.ReactElement
  > = [PositionPage, TryPage, ReadyPage];

  const [page, setPage] = React.useState(0);
  React.useEffect(() => {
    setPage(0);
  }, []);

  const goback = () => {
    setPage(Math.max(page - 1, 0));
  };

  const { t } = useTranslation();

  return (
    <>
      {/* {page !== 0 && (
        <BackButton onClick={() => setPage(Math.max(page - 1, 0))}>
          <ArrowIcon left />
        </BackButton>
      )} */}

      {InstructionPages.map((Page, idx) => (
        <Content
          key={idx}
          style={{
            transform: `translate(${(idx - page) * width}px, 0)`,
            background:
              idx === 2
                ? `linear-gradient( to bottom, #f0f0f0, #f0f0f0 69.5%, black 69.5%, black 70.5%, #dddddd 70.5%, #dddddd )`
                : '',
          }}
        >
          <Page
            onNextPage={() => setPage(idx + 1)}
            goback={goback}
            onFinish={onFinish}
          />
          {idx !== 2 && (
            <SkipButton onClick={onFinish}>
              {t('onboarding.instruction.skip')}
            </SkipButton>
          )}
        </Content>
      ))}

      <CloseButton onClick={onCloseButtonClick} />
    </>
  );
};

const InstructionModal = (props: InstructionModalProps): React.ReactElement => {
  // const { t } = useTranslation();
  const nodeRef = React.useRef(null);

  React.useEffect(() => {
    function onCloseEsc(e: KeyboardEvent) {
      if (e.keyCode === 27) {
        props.onCloseButtonClick();
      }
    }
    document.addEventListener('keydown', onCloseEsc);
    return () => document.removeEventListener('keydown', onCloseEsc);
  }, [props.onCloseButtonClick]);

  return (
    <Transition
      nodeRef={nodeRef}
      in={props.open}
      timeout={{ appear: 0, exit: 500 }}
      unmountOnExit
    >
      {(status) => (
        <ModalContainer
          ref={nodeRef}
          transition={{ css: modalContainerTransition, status: status }}
        >
          <Modal
            transition={{ css: modalTransition, status }}
            foldedColor={`rgba(0, 0, 0, 0.4)`}
          >
            <InstructionContent
              onCloseButtonClick={props.onCloseButtonClick}
              onFinish={props.onFinish}
            />
          </Modal>
        </ModalContainer>
      )}
    </Transition>
  );
};

export default InstructionModal;
