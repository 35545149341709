/**
 * KNN classify
 * - import all knn data
 * - read all knn data to mem
 * - array 832
 * - do KDsearch match all KNN data
 *
 */
// import knn from './knn.json';
import KNN from './signKNN';

let knnJson = {};
let KnnModel;

export const initKnn = () => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch('model/knn/knn_3.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    knnJson = await res.json();
    KnnModel = KNN.load(knnJson);
    resolve(true);
  });
};

export const knnClassify = (input, class_a, class_b) => {
  console.log('knn classify');
  const input_array = Array.from(input);
  const ans = KnnModel.predict(input_array, class_a, class_b);
  console.log(ans);
  return {
    predictedClassNumber: ans.predictedClass,
    predictedClass: KNN_LABEL[ans.predictedClass],
    class_a: {
      distance: ans.nearest_a.distance,
      pose: ans.nearest_a.poseDistance,
      face: ans.nearest_a.faceDistance,
      hand: ans.nearest_a.handsDistance,
    },
    class_b: {
      distance: ans.nearest_b.distance,
      pose: ans.nearest_b.poseDistance,
      face: ans.nearest_b.faceDistance,
      hand: ans.nearest_b.handsDistance,
    },
  };
};

export const KNN_LABEL = [
  '0_Idle',
  'Hksl_bathroom',
  'Hksl_bicycle-Jsl_bicycle',
  'Hksl_body_soap',
  'Hksl_carrot',
  'Hksl_cash',
  'Hksl_coffee',
  'Hksl_credit_cards',
  'Hksl_double_bed',
  'Hksl_flip_flops',
  'Hksl_grey',
  'Hksl_hat', // Hksl_hat-jsl_cap
  'Hksl_high_heels',
  'Hksl_jacket',
  'Hksl_lemon',
  'Hksl_mango',
  'Hksl_milk_tea',
  'Hksl_motorcycle-Jsl_motorcycle',
  'Hksl_non_smoking_room',
  'Hksl_onsite_payment',
  'Hksl_panda',
  'Hksl_pasta',
  'Hksl_post_office',
  'Hksl_purple',
  'Hksl_reservation', // Hksl_reservation_booking
  'Hksl_shampoo',
  'Hksl_shirt-Jsl_shirt',
  'Hksl_single_bed',
  'Hksl_smoking_room',
  'Hksl_steak',
  'Hksl_summer',
  'Hksl_sunglasses',
  'Hksl_swimming_pool',
  'Hksl_tiger',
  'Hksl_tomato',
  'Hksl_waiter',
  'Hksl_winter-Jsl_winter',
  'Jsl_bathroom',
  'Jsl_body_soap',
  'Jsl_cap', // jsl_cap_boshi
  'Jsl_carrot', // jsl_carrot_2
  'Jsl_cash',
  'Jsl_credit_cards',
  'Jsl_department_store',
  'Jsl_dog',
  'Jsl_double_bed',
  'Jsl_draught_beer', // jsl_draught_beer_2
  'Jsl_geta',
  'Jsl_goldfish',
  'Jsl_grey',
  'Jsl_high_heels',
  'Jsl_jacket',
  'Jsl_lemon',
  'Jsl_non_smoking_room',
  'Jsl_onsite_payment',
  'Jsl_pasta',
  'Jsl_reservation', // Jsl_reservation_booking
  'Jsl_shampoo',
  'Jsl_single_bed',
  'Jsl_smoking_room',
  'Jsl_steak',
  'Jsl_strawberry',
  'Jsl_summer',
  'Jsl_swimming_pool',
  'Jsl_tomato',
  'Jsl_waiter',
  'Jsl_watch',
  'Jsl_wine',
  'Jsl_yellow',
];
