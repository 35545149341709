import React from 'react';

import styled from 'styled-components';

import { ActionButton, PrimaryButton } from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { MapNames } from '../../recoils/gameStateAtom';
// import { ParentSize } from '@vx/responsive';
// import SVGResponsivePlayer from '../../components/SVGResponsivePlayer';
import formatSignText from '../../utils/formatSignText';
// import useSound from '../../utils/useSound';

// import Tagmanager from 'react-gtm-module';
// import { useRecoilState, useRecoilValue } from 'recoil';
// import {
//   IplayerEngagement,
//   lastPageAtom,
//   osNameAtom,
//   playerEngagementAtom,
// } from '../../recoils/playStateAtom';
// import CanvasResult from '../../components/Camera/CanvasResult';
import { ModalContext } from '../../layout/Modal';

/**
 * FLOW:
 * fetch scope of possible result
 * evalute possible result
 * set threshole of the possible result
 * check if more than threshole trigger check left or right
 * else return result error
 *
 * scope of possible result -> receive from sign selection
 */

// const Container = styled.div`
//   display: flex;
//   flex-flow: row nowrap;
//   width: 100%;
//   height: 100%;
//   padding: 0 100px;
// `;

// const Column = styled.div`
//   height: 100%;
//   width: 50%;
//   position: relative;
//   display: flex;
//   flex-flow: column nowrap;
//   justify-content: center;
//   align-items: center;
// `;

const RowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  /* position: absolute; */
  z-index: 10;
  bottom: 15%;
  width: 100%;

  & button {
    margin: 0 0.5rem;
  }
`;

const SignErrorColumn = styled.div`
  height: 100%;
  width: 70%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
`;

const RowSignIconDisplay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  /* position: absolute; */
  z-index: 10;
  /* bottom: 15%; */
  width: 100%;
  height: 45%;
  padding-bottom: 10%;

  & img {
    margin: 0 1rem;
  }
`;
const AnnotationText = styled.p`
  font-size: calc(24px * var(--scale, 1));
  font-weight: 700;
  text-align: center;
  width: 100%;
  white-space: nowrap;
`;

const SignResult = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45%;

  & img {
    height: 75%;
    width: auto;
  }
`;

const SignIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  cursor: pointer;
`;

const SignIconText = styled.p`
  font-size: calc(19px * var(--scale, 1));
  font-weight: 700;
  text-align: center;
  width: 100%;
  padding: 1rem;
`;
const SignIcon = styled.img<{ size?: number }>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
const SignResultText = styled.p`
  font-size: calc(24px * var(--scale, 1));
  font-weight: bold;
  margin-top: calc(12px * var(--scale, 1));

  text-align: center;
  text-transform: capitalize;
`;
// const Didyousign = styled.div`
//   font-size: 23px;
//   font-weight: 700;
//   text-align: center;
//   width: 100%;
// `;

interface SingingResultProps {
  handleTryAgain: () => void;
  signingResultSelect: string;
  signingResultSelectPair: string;
  isError: boolean;
  isGuessingError: boolean;
  handleGuessingError: (input: string) => void;
  nextState: () => void;
  signSelectionState: () => void;
  mapName: MapNames;
  thisScene: number;
  skipSign: () => void;
  // signScore_left: number[];
  // signScore_right: number[];
  signGuessScore: number[];
  signGuess: string;
  sendDataToCloud: () => void;
  signingPosition: number;
  signSelect_analytics: string;
}

const SigningResult: React.FC<SingingResultProps> = (
  props: SingingResultProps,
) => {
  const { t, i18n } = useTranslation();

  // const failedsound = useSound('./assets/sound/fail.wav');
  // const yessound = useSound('./assets/sound/yes.wav');

  // const osName = useRecoilValue<string>(osNameAtom);
  // const [
  //   playerEngagement,
  //   updatePlayerEngagement,
  // ] = useRecoilState<IplayerEngagement>(playerEngagementAtom);
  // const [, updateLastPage] = useRecoilState(lastPageAtom);

  // const failState = () => {
  //   let minIndex = 1;
  //   let minValue = 99;
  //   for (let i = 1; i < props.signGuessScore.length; i++) {
  //     if (minValue > props.signGuessScore[i]) {
  //       minValue = props.signGuessScore[i];
  //       minIndex = i;
  //     }
  //   }
  //   return minIndex === 2 ? 1 : 2;
  // };
  /**
   * sign video naming convension
   * ./assets/videos/${signLanguage}/${signLanguage.charAt(0).toUpperCase() +
   *  signLanguage.slice(1).toLowerCase()}_${signingSelectPair}.m4v
   */
  /**
   * component flow
   *
   * not error:
   *  hurray
   * is error
   *  get signing selectpair
   *  show video
   */
  // useEffect(() => {
  //   console.log('result props: ', props);
  //   if (props.isError) {
  //     updateLastPage('signing_result_fail');
  //     failedsound.play();
  //     const args = {
  //       dataLayer: {
  //         event: 'sign_user_fail',
  //         sign_select: props.signSelect_analytics,
  //         os_name: osName,
  //       },
  //     };
  //     Tagmanager.dataLayer(args);
  //     updatePlayerEngagement({
  //       ...playerEngagement,
  //       sign_count: playerEngagement.sign_count + 1,
  //       fail_count: playerEngagement.fail_count + 1,
  //     });
  //   } else {
  //     updateLastPage('signing_result_correct');
  //     yessound.play();
  //     const args = {
  //       dataLayer: {
  //         event: 'sign_user_success',
  //         sign_select: props.signSelect_analytics,
  //         os_name: osName,
  //       },
  //     };
  //     Tagmanager.dataLayer(args);
  //     updatePlayerEngagement({
  //       ...playerEngagement,
  //       sign_count: playerEngagement.sign_count + 1,
  //       success_count: playerEngagement.success_count + 1,
  //     });
  //   }
  // }, []);

  const { width } = React.useContext(ModalContext);
  const iconSize = width * 0.175;
  return (
    <>
      {props.isError ? (
        <>
          <SignErrorColumn>
            <AnnotationText>{t('signplay.signresult.sorry')}</AnnotationText>
            <RowSignIconDisplay>
              <SignIconWrapper
                onClick={() =>
                  props.handleGuessingError(props.signingResultSelect)
                }
              >
                <SignIcon
                  src={`/assets/sign_icon/${props.signingResultSelect}.svg`}
                  size={iconSize}
                />
                <SignIconText>
                  {formatSignText(props.signingResultSelect)}
                </SignIconText>
              </SignIconWrapper>
              <SignIconWrapper
                onClick={() =>
                  props.handleGuessingError(props.signingResultSelectPair)
                }
              >
                <SignIcon
                  src={`/assets/sign_icon/${props.signingResultSelectPair}.svg`}
                  size={iconSize}
                />
                <SignIconText>
                  {formatSignText(props.signingResultSelectPair)}
                </SignIconText>
              </SignIconWrapper>
            </RowSignIconDisplay>
          </SignErrorColumn>
        </>
      ) : (
        <>
          <SignErrorColumn>
            <AnnotationText>
              {i18n.language === 'hk'
                ? t('signplay.signresult.didyousign') +
                  ' ' +
                  formatSignText(props.signingResultSelect) +
                  ' ' +
                  t('signplay.signresult.didyousign2')
                : t('signplay.signresult.didyousign') +
                  ' ' +
                  formatSignText(props.signingResultSelect) +
                  '?'}
            </AnnotationText>

            <SignResult>
              <SignIcon
                src={`/assets/sign_icon/${props.signingResultSelect}.svg`}
              />
              <SignResultText>
                {formatSignText(props.signingResultSelect)}
              </SignResultText>
            </SignResult>

            {/* <SignResult>
              <SignIcon
                src={`/assets/sign_icon/${props.signingResultSelect}.svg`}
              />
              <SignResultText>
                {formatSignText(props.signingResultSelect)}
              </SignResultText>
            </SignResult> */}
            <RowButtonWrapper>
              <ActionButton
                onClick={() =>
                  props.handleGuessingError(props.signingResultSelectPair)
                }
              >
                {t('signplay.signresult.no')}
              </ActionButton>
              <PrimaryButton onClick={props.nextState}>
                {t('signplay.signresult.yes')}
              </PrimaryButton>
            </RowButtonWrapper>
          </SignErrorColumn>
        </>
      )}
    </>
  );
};

export default SigningResult;
