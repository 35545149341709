export default function getAssetLottie(
  mapName: string,
  scene: string,
  layer: string,
  asset: string,
): string {
  const basePath = '/assets/map';

  return [basePath, mapName, scene, layer, asset].join('/') + '.json';
}
