import { AnimationItem, AnimationSegment } from 'lottie-web';
import * as React from 'react';
import styled from 'styled-components';
import { CustomLottiePlayer, IPlayerProps } from './CustomLottiePlayer';

interface ISVGResponsivePlayerProps {
  src: string;
  fitBy: 'width' | 'height';
  side: number;
  segments?: AnimationSegment[];
  lottieProps?: Partial<IPlayerProps>;
}

const LottieContainer = styled.g``;

export default function SVGResponsivePlayer(
  props: ISVGResponsivePlayerProps,
): React.ReactElement {
  const { src, fitBy, side, lottieProps } = props;

  const [characterLottie, setCharacterLottie] = React.useState(false);
  const [lottieRef, setLottieRef] = React.useState<AnimationItem | null>(null);
  const [characterWidth, characterHeight] = React.useMemo((): number[] => {
    if (!lottieRef) return [0, 0];
    const {
      renderer: { svgElement },
    } = lottieRef;

    const newWidth = fitBy === 'width' ? side : svgElement.width.baseVal.value,
      newHeight = fitBy === 'height' ? side : svgElement.height.baseVal.value;

    if (fitBy === 'width') {
      svgElement.width.baseVal.value = newWidth;
    } else {
      svgElement.height.baseVal.value = newHeight;
    }

    return [newWidth, newHeight];
  }, [lottieRef, side]);

  React.useEffect(() => {
    fetch(src)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        setCharacterLottie(json);
      })
      .catch((err) => {
        console.error(`Failed to fetch lottie on ${src} with ${err}`);
      });
  }, [src]);

  return (
    <LottieContainer
      transform={`translate(${-characterWidth / 2}, ${-characterHeight / 2})`}
    >
      <CustomLottiePlayer
        autoplay
        loop
        src={characterLottie}
        playSegment={props.segments}
        lottieRef={(ref) => setLottieRef(ref)}
        {...lottieProps}
      />
    </LottieContainer>
  );
}
