import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { ActionButton, PrimaryButton } from '../../components/Button';
import { signLanguageAtom, SignLanguageList } from '../../recoils/signLanguage';

/**
 * new ux result
 */
const AnnotationText = styled.p`
  margin: 0;
  position: absolute;
  top: 60px;
  font-size: calc(18px * var(--scale, 1));
  font-weight: bold;
`;
const VideoWrapper = styled.div`
  position: absolute;
  width: 35%;
  height: 55%;
  top: 20%;
  right: 54%;
`;
const VideoTutorial = styled.video`
  border-radius: 15px;
  width: 100%;
  height: 100%;
`;

const RowButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: absolute;
  z-index: 10;
  bottom: 10%;
  width: 100%;

  & button {
    margin: 0 0.5rem;
  }
`;
interface SigningErrorProps {
  signDisplay: string;
  handleTryAgain: () => void;
  skipSign: () => void;
}
const SigningError: React.FC<SigningErrorProps> = (
  props: SigningErrorProps,
) => {
  const { t } = useTranslation();
  const signLanguage = useRecoilValue<SignLanguageList>(signLanguageAtom);

  console.log(props.signDisplay);
  return (
    <>
      <AnnotationText>{t('signplay.signresult.comparison')}</AnnotationText>
      <VideoWrapper className="video-tutorial-wrapper">
        <VideoTutorial
          src={`./assets/videos/${signLanguage}/${
            signLanguage.charAt(0).toUpperCase() +
            signLanguage.slice(1).toLowerCase()
          }_${props.signDisplay}.m4v`}
          muted
          playsInline
          loop
          id="video-tutorial-loop"
        />
      </VideoWrapper>

      <RowButtonWrapper>
        <ActionButton onClick={props.handleTryAgain}>
          {t('signplay.signresult.tryagain')}
        </ActionButton>
        <PrimaryButton onClick={props.skipSign}>
          {t('signplay.signresult.skip')}
        </PrimaryButton>
      </RowButtonWrapper>
    </>
  );
};

export default SigningError;
